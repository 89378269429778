import {Button, Form} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {useAppDispatch, useAppSelector} from "@store/hooks";
import {ChangeEvent} from "react";
import _ from "lodash";
import {appSettings} from "@api/defaults";
import {veranstaltungenActions, VeranstaltungenListFilterState} from "@features/veranstaltungen/store/store";

export const VeranstaltungenListFilterPanel = () => {
    const filters:VeranstaltungenListFilterState = {
        search: useAppSelector(state => state.veranstaltungen.search),
        alter: useAppSelector(state => state.veranstaltungen.alter),
    }
    const dispatch = useAppDispatch();

    const handleResetForm = () => {
        dispatch(veranstaltungenActions.resetFilters());
        dispatch(veranstaltungenActions.setFilterPanelOpen(false));
        dispatch(veranstaltungenActions.resetPagination());
    }

    const handleSetSearch = (e: ChangeEvent<HTMLInputElement>) => {
        dispatch(veranstaltungenActions.setSearch(e.target.value));
        dispatch(veranstaltungenActions.resetPagination());
    }

    const handleSetAlter = (e: ChangeEvent<HTMLInputElement>) => {
        dispatch(veranstaltungenActions.setAlter(Number(e.target.value)));
        dispatch(veranstaltungenActions.resetPagination());
    }

    return <Form onReset={handleResetForm} className="rowLayout rowLayoutGap my-4">
            <div className={"rowLayoutFlex"}>
                <Form.Control placeholder="Suche" size={"sm"} onChange={_.debounce(handleSetSearch, appSettings.debounce)}
                              defaultValue={filters.search || ""}/>
            </div>
            <div className={"text-end"}>
                <Button type={"reset"} variant={"danger"} size={"sm"}>
                    <FontAwesomeIcon icon={faTimes}/>
                </Button>
            </div>
        </Form>;
}