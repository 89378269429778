import {useRouteError} from "react-router";
import {Link} from "react-router-dom";
import React from "react";

export const HttpError404Page = () => {
    const error:any = useRouteError();
    console.error(error);

    return (
        <div id="error-page">
            <h1>Oops!</h1>
            <p>Sorry, an unexpected error has occurred.</p>
            <p>
                <i>{error.statusText || error.message}</i>
            </p>
            <Link to={`..`}>zurück</Link>
        </div>
    );
}
