import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {OrderByDirection, OrderState} from "@api/defaults";

export interface GemeindenListState {
    filterPanelOpen: boolean;
}

export interface GemeindenListFilterState {
    search?: string;
    institutionsart?: number;
}

export interface GemeindenState extends GemeindenListState, GemeindenListFilterState, OrderState {}

const initialGemeindenState: GemeindenState = {
    filterPanelOpen: false,
    orderBy: "name",
    orderByDirection: OrderByDirection.ASC,
};

export interface VeranstaltungsOrteListState {
    filterPanelOpen: boolean;
}

export interface VeranstaltungsOrteListFilterState {
    search?: string;
    institutionsart?: number;
}

export interface VeranstaltungsOrteState extends VeranstaltungsOrteListState, VeranstaltungsOrteListFilterState, OrderState {}

const initialVeranstaltungsOrteState: VeranstaltungsOrteState = {
    filterPanelOpen: false,
    orderBy: "name",
    orderByDirection: OrderByDirection.ASC,
};

export const gemeindenSlice = createSlice({
    name: "gemeinden",
    initialState: initialGemeindenState,
    reducers: {
        setFilterPanelOpen: (state, action: PayloadAction<boolean>) => {
            state.filterPanelOpen = action.payload;
        },

        setSearch: (state, action: PayloadAction<string|undefined>) => {
            state.search = action.payload;
        },
        setInstitutionsArt: (state, action: PayloadAction<number|undefined>) => {
            state.institutionsart = action.payload;
        },
        resetFilters: (state) => {
            state.search = undefined;
            state.institutionsart = undefined;
        },

        setOrderBy: (state, action: PayloadAction<OrderState>) => {
            state.orderBy = action.payload.orderBy;
            state.orderByDirection = action.payload.orderByDirection;
        },
        resetOrderBy: (state) => {
            state.orderBy = initialGemeindenState.orderBy;
            state.orderByDirection = initialGemeindenState.orderByDirection;
        }
    }
});

export const gemeindenActions = gemeindenSlice.actions;

export const gemeindenReducer = gemeindenSlice.reducer;


export const veranstaltungsOrteSlice = createSlice({
    name: "veranstaltungsOrte",
    initialState: initialVeranstaltungsOrteState,
    reducers: {
        setFilterPanelOpen: (state, action: PayloadAction<boolean>) => {
            state.filterPanelOpen = action.payload;
        },

        setSearch: (state, action: PayloadAction<string|undefined>) => {
            state.search = action.payload;
        },
        resetFilters: (state) => {
            state.search = undefined;
        },

        setOrderBy: (state, action: PayloadAction<OrderState>) => {
            state.orderBy = action.payload.orderBy;
            state.orderByDirection = action.payload.orderByDirection;
        },
        resetOrderBy: (state) => {
            state.orderBy = initialVeranstaltungsOrteState.orderBy;
            state.orderByDirection = initialVeranstaltungsOrteState.orderByDirection;
        }
    }
});

export const veranstaltungsOrteActions = veranstaltungsOrteSlice.actions;

export const veranstaltungsOrteReducer = veranstaltungsOrteSlice.reducer;

