import React from "react";
import {TeilnahmeForPerson, TeilnahmeForVeranstaltung} from "@api/teilnahmen.types";
import {formatTurnusTitel} from "@features/veranstaltungen/utils/veranstaltungenUtils";
import {formatDate} from "@features/ui/utils/dateTimeUtils";
import {Button, Col, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleRight} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import {formatName} from "@features/personen/utils/personenUtils";

type TeilnahmenInlineDetailsPanelProps = {
    teilnahme: TeilnahmeForPerson|TeilnahmeForVeranstaltung;
}

export const TeilnahmenInlineDetailsPanel: React.FC<TeilnahmenInlineDetailsPanelProps> = (props) => {
    return <Row className={"p-3 mb-1 editorSecondaryPanelItem"}>
        <Col sm={10}>
            {props.teilnahme.hasOwnProperty("turnus") && <>
                <div>
                    {formatTurnusTitel((props.teilnahme as TeilnahmeForPerson).turnus)}
                </div>
                <div>
                    {formatDate((props.teilnahme as TeilnahmeForPerson).turnus.von)}
                    &nbsp;-&nbsp;
                    {formatDate((props.teilnahme as TeilnahmeForPerson).turnus.bis)}
                </div>
            </>}
            {props.teilnahme.hasOwnProperty("person") && <>
                <div>
                    {formatName((props.teilnahme as TeilnahmeForVeranstaltung).person.personendaten)}
                </div>
                <div>
                    {formatDate((props.teilnahme as TeilnahmeForVeranstaltung).person.geburtsdatum)}
                </div>
            </>}
            <div>
                {props.teilnahme.teilnehmerRolle.bezeichnung}
            </div>
        </Col>
        <Col sm={2} className={"text-end"}>
            <Link to={`/teilnahmen/${props.teilnahme.id}`}>
                <Button size={"sm"}>
                    <FontAwesomeIcon icon={faAngleRight}/>
                </Button>
            </Link>
        </Col>
    </Row>
}