import {Button, Form} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {useAppDispatch, useAppSelector} from "@store/hooks";
import React, {ChangeEvent} from "react";
import _ from "lodash";
import {appSettings} from "@api/defaults";
import {veranstaltungsOrteActions, VeranstaltungsOrteListFilterState} from "@features/institutionen/store/store";

export const VeranstaltungsOrteListFilterPanel = () => {
    const filters:VeranstaltungsOrteListFilterState = {
        search: useAppSelector(state => state.veranstaltungsOrte.search),
    }
    const dispatch = useAppDispatch();

    const handleResetForm = () => {
        dispatch(veranstaltungsOrteActions.resetFilters());
        dispatch(veranstaltungsOrteActions.setFilterPanelOpen(false));
    }

    const handleSetSearch = (e: ChangeEvent<HTMLInputElement>) => {
        dispatch(veranstaltungsOrteActions.setSearch(e.target.value));
    }

    return <Form onReset={handleResetForm} className="rowLayout rowLayoutGap my-4">
            <div className={"rowLayoutFlex"}>
                <Form.Control placeholder="Suche" size={"sm"} onChange={_.debounce(handleSetSearch, appSettings.debounce)}
                              defaultValue={filters.search || ""}/>
            </div>
            <div className={"text-end"}>
                <Button type={"reset"} variant={"danger"} size={"sm"}>
                    <FontAwesomeIcon icon={faTimes}/>
                </Button>
            </div>
        </Form>;
}