import {Table} from "react-bootstrap";
import React, {MouseEvent} from "react";
import {useAppDispatch, useAppSelector} from "@store/hooks";
import {OrderState} from "@api/defaults";
import {OrderableTableHeader} from "@features/ui/components/OrderableTableHeader";
import {useNavigate} from "react-router-dom";
import {Stammdaten} from "@api/stammdaten.types";
import {RootState} from "@store/store";
import {StammdatenActions, StammdatenState} from "@features/stammdaten/store/store";
import {CaseReducerActions} from "@reduxjs/toolkit";

export type StammdatenListProps = {
    items?: Stammdaten[];
    getState: (state: RootState) => StammdatenState;
    actions: CaseReducerActions<StammdatenActions, string>;
}

export const StammdatenList: React.FC<StammdatenListProps> = (props) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const orderBy:OrderState = {
        orderBy: useAppSelector(state => props.getState(state).orderBy),
        orderByDirection: useAppSelector(state => props.getState(state).orderByDirection),
    }

    const handleRowClick = (e:MouseEvent<HTMLTableRowElement>, item: Stammdaten) => {
        if (!e.defaultPrevented) {
            navigate(`${item.id}`);
        }
    }

    const handleOrderByChanged = (orderBy:OrderState) => {
        dispatch(props.actions.setOrderBy(orderBy));
    }

    return <Table responsive hover>
        <thead>
        <tr>
            <OrderableTableHeader label={"Bezeichnung"} property={"bezeichnung"} orderBy={orderBy} onChangeOrder={handleOrderByChanged}/>
        </tr>
        </thead>
        <tbody>
        {props.items?.map((item) => <tr key={item.id} onClick={(e) => handleRowClick(e, item)} className={"cursorClickable"}>
            <td>{item.bezeichnung}</td>
        </tr>)}
        </tbody>
    </Table>;
}