import React, {useEffect, useState} from "react";
import "react-datepicker/dist/react-datepicker.css";
import {Institution, ListInstitution} from "@api/institutionen.types";
import {InstitutiuonEditorPanel} from "@features/institutionen/components/InstitutionEditorPanel";
import {EditorRow} from "@features/ui/components/EditorRow";
import {GemeindeAutocomplete} from "@features/institutionen/components/GemeindeAutocomplete";
import {GemeindenArtSelect} from "@features/institutionsarten/components/GemeindenArtSelect";
import {InstitutionsArt} from "@api/stammdaten.types";

type GemeindeEditorPanelProps = {
    title: string;
    item: Partial<Institution>,
    onSubmit: (item: Institution) => void;
    onDelete?: (id: number) => void;
}

export const GemeindeEditorPanel: React.FC<GemeindeEditorPanelProps> = (props) => {
    const [gemeinde, setGemeinde] = useState<Partial<Institution>>(props.item);
    useEffect(() => {
        setGemeinde(props.item);
    }, [props.item]);

    const handleSubmit = (item: Institution) => {
        props.onSubmit(item);
    }

    const handleInstitutionChange = (partialGemeinde: Partial<Institution>) => {
        setGemeinde({
            ...gemeinde,
            ...partialGemeinde
        });
    }

    const handleOnDelete = (id: number) => {
        if (props.onDelete) {
            props.onDelete(id);
        }
    }

    const handleUpdateOberInstitution = (gemeinde?: ListInstitution) => {
        setGemeinde({
            ...gemeinde,
            oberInstitution: gemeinde as Institution,
        });
    }

    const handleUpdateInstitutionsArt = (institutionsArt?: InstitutionsArt) => {
        setGemeinde({
            ...gemeinde,
            institutionsArt: institutionsArt?.id,
        });
    }

    return <InstitutiuonEditorPanel title={props.title} institution={gemeinde} onSubmit={item => handleSubmit(item as Institution)} onChange={handleInstitutionChange} onDelete={handleOnDelete}>
            <EditorRow label={"Gemeinde-Art"} className={"mb-2"}>
                <GemeindenArtSelect value={gemeinde.institutionsArt} onChange={handleUpdateInstitutionsArt}/>
            </EditorRow>

            <EditorRow label={"Landesverband"} className={"mb-2"}>
                <GemeindeAutocomplete placeholder={"Bitte wählen für Gemeinden und Liberale Gemeinden"} value={gemeinde.oberInstitution?.id} onChange={handleUpdateOberInstitution}/>
            </EditorRow>
    </InstitutiuonEditorPanel>;
}