import {Button, Offcanvas} from "react-bootstrap";
import {useAppDispatch, useAppSelector} from "@store/hooks";
import {uiActions} from "@features/ui/store/store";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import React, {MouseEvent, useState} from "react";
import styles from "@features/ui/style/SidebarMenu.module.scss";
import {authActions} from "@features/auth/store/store";
import {PageLoader} from "@features/ui/components/PageLoader";

export const SidebarAuthMenu = () => {
    const sidebarmenuOpen = useAppSelector((state) => state.ui.rightSidebarOpen);
    const username = useAppSelector(state => state.auth.username);
    const authorities = useAppSelector(state => state.auth.authorities);
    const dispatch = useAppDispatch();
    const [logout, setLogout] = useState(false);

    const handleCloseRightSidebar = () => {
        dispatch(uiActions.closeRightSidebar());
    }

    const handleLogout = async (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setLogout(true);
        setTimeout(() => {
            handleCloseRightSidebar();
            dispatch(authActions.clearAuth());
        }, 2000);
    }

    // const handleLogoutIframe = async () => {
    //     // do the axios-logout first and then
    //     // console.log("FORCE LOGUT....");
    //     // await logout();
    //     // console.log("FORCE LOGUT....DONE");
    //     setLogout(true);
    //     // dispatch(authActions.clearAuth());
    // }

    return <Offcanvas placement={"end"} show={sidebarmenuOpen} onHide={handleCloseRightSidebar}>
        <Offcanvas.Header className={styles.sidemenuHeader}>
            <Offcanvas.Title>Benutzerprofil</Offcanvas.Title>
            {!logout && <Button type="button" variant="link" onClick={handleCloseRightSidebar}>
                <FontAwesomeIcon icon={faTimes} size={"lg"} color={"#fff"}/>
            </Button>}
        </Offcanvas.Header>
        <Offcanvas.Body onClick={handleCloseRightSidebar}>
            {!logout && <>
                <div className={"mb-5"}>
                    <div className={"mb-1"}>
                        Sie sind angemeldet als:
                    </div>
                    <h5>{username}</h5>
                </div>
                <div className={"mb-5"}>
                    <h5>Berechtigungen</h5>
                    <ul>
                        {authorities.map(a => <li key={a}>{a}</li>)}
                    </ul>
                </div>
                <div className={"mb-5"}>
                    {/*<Button variant={"primary"} className={"me-2"}>Benutzerprofil</Button>*/}
                    <Button variant={"danger"} onClick={handleLogout}>Abmelden</Button>
                </div>
            </>}
            {logout && <PageLoader message={"Abmelden..."}/>}
            {logout && <iframe src={"http://localhost:8000/logout"} className={"invisible"}/>}
        </Offcanvas.Body>
    </Offcanvas>;
};