import React, {useEffect, useState} from "react";
import "react-datepicker/dist/react-datepicker.css";
import {Institution} from "@api/institutionen.types";
import {InstitutiuonEditorPanel} from "@features/institutionen/components/InstitutionEditorPanel";

type VeranstaltungsOrteEditorPanelProps = {
    title: string;
    item: Partial<Institution>,
    onSubmit: (item: Institution) => void;
    onDelete?: (id: number) => void;
}

export const VeranstaltungsOrteEditorPanel: React.FC<VeranstaltungsOrteEditorPanelProps> = (props) => {
    const [veranstaltungsOrt, setVeranstaltungsOrt] = useState<Partial<Institution>>(props.item);
    useEffect(() => {
        setVeranstaltungsOrt(props.item);
    }, [props.item]);

    const handleSubmit = (item: Institution) => {
        props.onSubmit(item);
    }

    const handleInstitutionChange = (partialVeranstaltungsOrt: Partial<Institution>) => {
        setVeranstaltungsOrt({
            ...veranstaltungsOrt,
            ...partialVeranstaltungsOrt
        });
    }

    const handleOnDelete = (id: number) => {
        if (props.onDelete) {
            props.onDelete(id);
        }
    }

    return <InstitutiuonEditorPanel title={props.title} institution={veranstaltungsOrt} onSubmit={item => handleSubmit(item as Institution)} onChange={handleInstitutionChange} onDelete={handleOnDelete}/>;
}