import {useQuery} from "@tanstack/react-query";
import {PaginationResponse} from "@api/query.types";
import {
    loadVeranstaltungenItem,
    loadVeranstaltungenPaginationList,
    VeranstaltungenListRequestParams,
    VeranstaltungItemRequestParams
} from "@api/veranstaltungen";
import {ListVeranstaltung, Veranstaltung} from "@api/veranstaltungen.types";

export const useLoadVeranstaltungenPaginationList = (params: VeranstaltungenListRequestParams) => {
    return useQuery<PaginationResponse<ListVeranstaltung>, Error>(
        ["veranstaltungen-list-paged", params],
        async () => {
            return await loadVeranstaltungenPaginationList(params);
        }
    )
};

export const useLoadVeranstaltungenItem = (params: VeranstaltungItemRequestParams) => {
    return useQuery<Veranstaltung, Error>(
        ["veranstaltungen-item", params],
        async () => {
            return await loadVeranstaltungenItem(params);
        }
    )
};
