import React from "react";
import {useParams} from "react-router-dom";
import {convertRouterParams, IdRouterParams, idRouterParamsTypes} from "@pages/router";
import {useLoadPersonenItem} from "@features/personen/api/personen-api";
import {PageLoader} from "@features/ui/components/PageLoader";
import {Col, Container, Row} from "react-bootstrap";
import {Person} from "@api/personen.types";
import "react-datepicker/dist/react-datepicker.css";
import {PersonenEditorPanel} from "@features/personen/components/PersonenEditorPanel";
import styles from "@scss/EditorPageLayout.module.scss";
import {TeilnahmenInlineListPanel} from "@features/teilnahmen/components/TeilnahmenInlineListPanel";

export const PersonenEditorPage = () => {
    const {id} = convertRouterParams<IdRouterParams>(useParams(), [idRouterParamsTypes]);

    const {data: person, isLoading, isError} = useLoadPersonenItem({
        id
    });

    if (isError) {
        return <div>Error</div>
    }

    if (isLoading) {
        return <PageLoader/>
    }

    const handleSubmit = (person: Person) => {
        console.log("SAVE PERSON");
        console.log(person);
    }

    return <Container fluid className={styles.layout}>
        <Row>
            <Col xl={6}>
                <PersonenEditorPanel person={person} onSubmit={handleSubmit}/>
            </Col>
            <Col xl={6} className={"editorSecondaryPanel"}>
                <TeilnahmenInlineListPanel teilnahmen={person.teilnahmen}></TeilnahmenInlineListPanel>
            </Col>
        </Row>
    </Container>;
}