import {ListPerson, Person} from "@api/personen.types";
import {axios} from "@api/axios";
import {OrderRequestParam, PaginationRequestParam, PaginationResponse} from "@api/query.types";
import {PersonenListFilterState} from "@features/personen/store/store";

const ENDPOINT = "/admin/personen";

export interface PersonenListRequestParams extends PaginationRequestParam,OrderRequestParam,PersonenListFilterState {}
export interface PersonItemRequestParams {
    id: number;
}

export const loadPersonenPaginationList = async (params: PersonenListRequestParams) => {
    if (!params.orderBy) {
        params.orderBy = "personendaten.nachname:ASC,personendaten.vorname:ASC";
    }
    const { data } = await axios.get<PaginationResponse<ListPerson>>(`${ENDPOINT}/pages`, {
        params
    });
    return data;
}

export const loadPersonenItem = async (params: PersonItemRequestParams) => {
    const { data } = await axios.get<Person>(`${ENDPOINT}/${params.id}`);
    return data;
}
