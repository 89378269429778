import {ListVeranstaltung, TurnusForTeilnahme, Veranstaltung} from "@api/veranstaltungen.types";

export const formatTurnusTitel = (turnus?: TurnusForTeilnahme): string => {
    return `${`${turnus?.veranstaltung?.thema || ""} ${turnus?.veranstaltung?.thema2 || ""}`.trim()}${turnus?.titel ? ` - ${turnus?.titel}` : ""}`;
}

export const formatVeranstaltungTitel = (veranstaltung?: ListVeranstaltung|Veranstaltung): string => {
    if (!veranstaltung) {
        return "";
    }
    return `${`${veranstaltung?.thema || ""} ${veranstaltung?.thema2 || ""}`.trim()}`;
}
