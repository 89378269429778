import {useMutation, useQuery} from "@tanstack/react-query";
import {queryClient} from "@api/query-client";
import {
    deleteGemeindenItem,
    deleteVeranstaltungsOrteItem,
    GemeindeItemRequestParams,
    GemeindenListRequestParams,
    loadGemeindenItem,
    loadGemeindenList,
    loadVeranstaltungsOrteItem,
    loadVeranstaltungsOrteList,
    updateGemeindenItem,
    updateVeranstaltungsOrteItem,
    VeranstaltungsOrteItemRequestParams,
    VeranstaltungsOrteListRequestParams
} from "@api/institutionen";
import {Institution, ListInstitution} from "@api/institutionen.types";

export const useLoadGemeindenList = (params: GemeindenListRequestParams) => {
    return useQuery<ListInstitution[], Error>(
        ["gemeinden-list", params],
        async () => {
            return await loadGemeindenList(params);
        }
    )
};

export const useLoadGemeindenItem = (params: GemeindeItemRequestParams) => {
    return useQuery<Institution, Error>(
        ["gemeinden-item", params],
        async () => {
            return await loadGemeindenItem(params);
        }
    )
};

export const useUpdateGemeindenItem = (options?: {
    onSuccess?: (id: number) => void;
    onError?: (err: unknown) => void;
}) => {
    return useMutation({
        mutationFn: updateGemeindenItem,
        onSuccess: async (data) => {
            await queryClient.invalidateQueries({queryKey: ["gemeinden-list"]});
            await queryClient.invalidateQueries({queryKey: ["gemeinden-item"]});
            if (options?.onSuccess) {
                options.onSuccess(data.data);
            }
        },
        onError: (err) => {
            if (options?.onError) {
                options.onError(err);
            }
        },
    });
}

export const useDeleteGemeindenItem = (options?: {
    onSuccess?: () => void;
    onError?: (err: unknown) => void;
}) => {
    return useMutation({
        mutationFn: deleteGemeindenItem,
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: ["gemeinden-list"]});
            await queryClient.invalidateQueries({queryKey: ["gemeinden-item"]});
            if (options?.onSuccess) {
                options.onSuccess();
            }
        },
        onError: (err) => {
            if (options?.onError) {
                options.onError(err);
            }
        },
    });
}

export const useLoadVeranstaltungsOrteList = (params: VeranstaltungsOrteListRequestParams) => {
    return useQuery<ListInstitution[], Error>(
        ["veranstaltungs-orte-list", params],
        async () => {
            return await loadVeranstaltungsOrteList(params);
        }
    )
};

export const useLoadVeranstaltungsOrteItem = (params: VeranstaltungsOrteItemRequestParams) => {
    return useQuery<Institution, Error>(
        ["veranstaltungs-orte-item", params],
        async () => {
            return await loadVeranstaltungsOrteItem(params);
        }
    )
};

export const useUpdateVeranstaltungsOrteItem = (options?: {
    onSuccess?: (id: number) => void;
    onError?: (err: unknown) => void;
}) => {
    return useMutation({
        mutationFn: updateVeranstaltungsOrteItem,
        onSuccess: async (data) => {
            await queryClient.invalidateQueries({queryKey: ["veranstaltungs-orte-list"]});
            await queryClient.invalidateQueries({queryKey: ["veranstaltungs-orte-item"]});
            if (options?.onSuccess) {
                options.onSuccess(data.data);
            }
        },
        onError: (err) => {
            if (options?.onError) {
                options.onError(err);
            }
        },
    });
}

export const useDeleteVeranstaltungsOrteItem = (options?: {
    onSuccess?: () => void;
    onError?: (err: unknown) => void;
}) => {
    return useMutation({
        mutationFn: deleteVeranstaltungsOrteItem,
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: ["veranstaltungs-orte-list"]});
            await queryClient.invalidateQueries({queryKey: ["veranstaltungs-orte-item"]});
            if (options?.onSuccess) {
                options.onSuccess();
            }
        },
        onError: (err) => {
            if (options?.onError) {
                options.onError(err);
            }
        },
    });
}
