import React, {MouseEvent} from "react";
import {Button, Col, Container, Row} from "react-bootstrap";
import {BackButton} from "@features/ui/components/BackButton";
import {ReloadButton} from "@features/ui/components/ReloadButton";

type EditorButtonsProps = {
    hideDeleteButton?: boolean;
    onDelete?: () => void;
    deleteMessage?: string;
}

export const EditorButtons: React.FC<EditorButtonsProps> = (props) => {

    const onDeleteClick = (e:MouseEvent<HTMLButtonElement>) => {
        if (!props.onDelete) {
            return;
        }
        e.preventDefault();
        if (window.confirm(props.deleteMessage || "Sind sie sicher, dass Sie den Datensatz unwiderruflich entfernen möchten?")) {
            props.onDelete();
        }
    }

    return <Container fluid className={"mt-3 mb-5"}>
        <Row>
            <Col sm={3}>
                <BackButton/>
            </Col>
            <Col sm={9} className={"text-end"}>
                <Button type={"submit"}>Speichern</Button>
                &nbsp;
                <ReloadButton/>
                {!props.hideDeleteButton && props.onDelete && <>
                    &nbsp;
                    <Button variant={"danger"} onClick={onDeleteClick}>Entfernen</Button>
                </>}
            </Col>
        </Row>
    </Container>
}