import {Table} from "react-bootstrap";
import {ListPerson} from "@api/personen.types";
import React from "react";
import {useAppDispatch, useAppSelector} from "@store/hooks";
import {personenActions} from "@features/personen/store/store";
import {OrderState} from "@api/defaults";
import {OrderableTableHeader} from "@features/ui/components/OrderableTableHeader";
import {PersonenListRow} from "@features/personen/components/PersonenListRow";
import {useNavigate} from "react-router-dom";

export type PersonenListProps = {
    personen?: ListPerson[];
}

export const PersonenList: React.FC<PersonenListProps> = (props) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const orderBy:OrderState = {
        orderBy: useAppSelector(state => state.personen.orderBy),
        orderByDirection: useAppSelector(state => state.personen.orderByDirection),
    }

    const handleShowPersonEditor = (person: ListPerson) => {
        navigate(`${person.id}`);
    }

    const handleOrderByChanged = (orderBy:OrderState) => {
        dispatch(personenActions.setOrderBy(orderBy));
    }

    return <Table responsive hover>
        <thead>
        <tr>
            <th style={{width: "32px", paddingRight: "16px"}}></th>
            <OrderableTableHeader label={"Vorname"} property={"personendaten.vorname"} orderBy={orderBy} onChangeOrder={handleOrderByChanged}/>
            <OrderableTableHeader label={"Nachname"} property={"personendaten.nachname"} orderBy={orderBy} onChangeOrder={handleOrderByChanged}/>
            <OrderableTableHeader label={"Gemeinde"} property={"gemeinde.name"} orderBy={orderBy} onChangeOrder={handleOrderByChanged}/>
            <OrderableTableHeader label={"Geburtsdatum"} property={"alter"} orderBy={orderBy} onChangeOrder={handleOrderByChanged}/>
            <th></th>
        </tr>
        </thead>
        <tbody>
        {props.personen?.map((person) => <PersonenListRow key={person.id} person={person} onShowPersonEditor={handleShowPersonEditor}/>)}
        </tbody>
    </Table>;
}