import React, {ChangeEvent, FormEvent, useEffect, useState} from "react";
import {PersonendatenEditorPanel} from "@features/stammdaten/components/PersonendatenEditorPanel";
import {Button, Form} from "react-bootstrap";
import {Adresse, Bankverbindung, Kontaktdaten, Person, Personendaten, Reisepass} from "@api/personen.types";
import "react-datepicker/dist/react-datepicker.css";
import {DatePicker} from "@features/ui/components/DatePicker";
import {PersonAutocomplete} from "@features/personen/components/PersonAutocomplete";
import {AdresseEditorPanel} from "@features/stammdaten/components/AdresseEditorPanel";
import {EditorRow} from "@features/ui/components/EditorRow";
import {GemeindeAutocomplete} from "@features/institutionen/components/GemeindeAutocomplete";
import {EditorButtons} from "@features/ui/components/EditorButtons";
import {KontaktdatenEditorPanel} from "@features/stammdaten/components/KontaktdatenEditorPanel";
import {EditorSpacer} from "@features/ui/components/EditorSpacer";
import {BankverbindungEditorPanel} from "@features/stammdaten/components/BankverbindungEditorPanel";
import {ReisepassEditorPanel} from "@features/stammdaten/components/ReisepassEditorPanel";
import {formatName} from "@features/personen/utils/personenUtils";
import {formatDate} from "@features/ui/utils/dateTimeUtils";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleRight} from "@fortawesome/free-solid-svg-icons";
import {Veranstaltung} from "@api/veranstaltungen.types";
import {ReferatSelect} from "@features/stammdaten/components/ReferatSelect";

type VeranstaltungenEditorPanelProps = {
    veranstaltung: Partial<Veranstaltung>,
    onSubmit: (veranstaltung: Veranstaltung) => void;
}

export const VeranstaltungenEditorPanel: React.FC<VeranstaltungenEditorPanelProps> = (props) => {
    const [veranstaltung, setVeranstaltung] = useState<Partial<Veranstaltung>>(props.veranstaltung);
    useEffect(() => {
        setVeranstaltung(props.veranstaltung);
    }, [props.veranstaltung]);

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();
        console.log("NOW SUBMIT EDITOR PANEL");
        console.log(veranstaltung);
    }

    // const handleUpdatePersonendaten = (personendaten: Personendaten) => {
    //     setVeranstaltung({
    //         ...veranstaltung,
    //         personendaten,
    //     });
    // }
    //
    // const handleUpdateGeburtsdatum = (geburtsdatum?: Date) => {
    //     setVeranstaltung({
    //         ...veranstaltung,
    //         geburtsdatum
    //     });
    // }
    //
    // const handleUpdateVerstorbenAm = (verstorbenAm?: Date) => {
    //     setVeranstaltung({
    //         ...veranstaltung,
    //         verstorbenAm
    //     });
    // }
    //
    // const handleUpdateErziehungsberechtigter = (erziehungsberechtigter?: Person) => {
    //     setVeranstaltung({
    //         ...veranstaltung,
    //         erziehungsberechtigter
    //     });
    // }
    //
    // const handleUpdateAdresse = (adresse: Adresse) => {
    //     setVeranstaltung({
    //         ...veranstaltung,
    //         adresse,
    //     });
    // }
    //
    // const handleUpdateVerzogenAm = (verzogenAm?: Date) => {
    //     setVeranstaltung({
    //         ...veranstaltung,
    //         verzogenAm
    //     });
    // }
    //
    // const handleUpdateKontaktdatenPrivat = (kontaktdatenPrivat: Kontaktdaten) => {
    //     setVeranstaltung({
    //         ...veranstaltung,
    //         kontaktdatenPrivat,
    //     });
    // }
    //
    // const handleUpdateEmailUnerwuenscht = (e: ChangeEvent<HTMLInputElement>) => {
    //     setVeranstaltung({
    //         ...veranstaltung,
    //         emailUnerwuenscht: e.target.checked,
    //     });
    // }
    //
    // const handleUpdateKontaktUnerwuenscht = (e: ChangeEvent<HTMLInputElement>) => {
    //     setVeranstaltung({
    //         ...veranstaltung,
    //         kontaktUnerwuenscht: e.target.checked,
    //     });
    // }
    //
    // const handleUpdateKontaktdatenArbeit = (kontaktdatenArbeit: Kontaktdaten) => {
    //     setVeranstaltung({
    //         ...veranstaltung,
    //         kontaktdatenArbeit,
    //     });
    // }
    //
    // const handleUpdateBankverbindung = (bankverbindung: Bankverbindung) => {
    //     setVeranstaltung({
    //         ...veranstaltung,
    //         bankverbindung,
    //     });
    // }
    //
    // const handleUpdateReisepass = (reisepass: Reisepass) => {
    //     setVeranstaltung({
    //         ...veranstaltung,
    //         reisepass,
    //     });
    // }
    //
    // const handleUpdateReisepass2 = (reisepass2: Reisepass) => {
    //     setVeranstaltung({
    //         ...veranstaltung,
    //         reisepass2,
    //     });
    // }
    //
    // const handleUpdateBemerkungen = (e: ChangeEvent<HTMLInputElement>) => {
    //     setVeranstaltung({
    //         ...veranstaltung,
    //         bemerkungen: e.target.value
    //     });
    // }
    //
    // const handleUpdateSozialreferat = (e: ChangeEvent<HTMLInputElement>) => {
    //     setVeranstaltung({
    //         ...veranstaltung,
    //         sozialreferat: e.target.checked,
    //     });
    // }
    //
    // const handleUpdateJugendreferat = (e: ChangeEvent<HTMLInputElement>) => {
    //     setVeranstaltung({
    //         ...veranstaltung,
    //         jugendreferat: e.target.checked,
    //     });
    // }

    const handleReset = (ignored: FormEvent<HTMLFormElement>) => {
        // anthing to do here?
        // localFormRefs.geburtsdatum.current?.forceUpdate();
        // setPerson(props.veranstaltung); // doesn't seem to work
    };

    // return <Form onSubmit={handleSubmit} onReset={handleReset} ref={localFormRefs.form}>
    return <Form onSubmit={handleSubmit} onReset={handleReset}>
        <div className={"p-3"}>
            {/*<PersonendatenEditorPanel personendaten={veranstaltung.personendaten!} onUpdate={handleUpdatePersonendaten}>*/}
            {/*    <EditorRow label={"Geburtsdatum"} className={"mb-2"}>*/}
            {/*        <DatePicker value={veranstaltung.geburtsdatum} onChange={handleUpdateGeburtsdatum}/>*/}
            {/*    </EditorRow>*/}

            {/*    <EditorRow label={"Verstorben am"} className={"mb-2"}>*/}
            {/*        <DatePicker value={veranstaltung.verstorbenAm} onChange={handleUpdateVerstorbenAm}/>*/}
            {/*    </EditorRow>*/}

            {/*    <EditorRow label={"Erziehungsberechtiger"} className={"mb-2"}>*/}
            {/*        <PersonAutocomplete placeholder={"Erziehungsberechtiger"} value={veranstaltung.erziehungsberechtigter?.id}*/}
            {/*                            onChange={handleUpdateErziehungsberechtigter}/>*/}
            {/*        {veranstaltung.erziehungsberechtigter && <Link to={`/personen/${veranstaltung.erziehungsberechtigter.id}`}>*/}
            {/*            <Button>*/}
            {/*                <FontAwesomeIcon icon={faAngleRight}/>*/}
            {/*            </Button>*/}
            {/*        </Link>}*/}
            {/*    </EditorRow>*/}

            {/*    {(veranstaltung.kinder?.length && veranstaltung.kinder?.length > 0) ?*/}
            {/*        <EditorRow label={"Kinder"} className={"mb-2"}>*/}
            {/*            <div>*/}
            {/*                {veranstaltung.kinder.map(k => <div className={"mb-1"} key={`kinder_${veranstaltung.id}_${k.id}`}>*/}
            {/*                    <Link to={`/personen/${k.id}`}>*/}
            {/*                        {formatName(k.personendaten)}, {formatDate(k.geburtsdatum)}*/}
            {/*                    </Link>*/}
            {/*                </div>)}*/}
            {/*            </div>*/}
            {/*        </EditorRow> : ""}*/}
            {/*</PersonendatenEditorPanel>*/}

            {/*<EditorSpacer/>*/}
            {/*<AdresseEditorPanel adresse={veranstaltung.adresse!} onUpdate={handleUpdateAdresse}>*/}
            {/*    <EditorRow label={"Verzogen am"} className={"mb-2"}>*/}
            {/*        <DatePicker value={veranstaltung.verzogenAm} onChange={handleUpdateVerzogenAm}/>*/}
            {/*    </EditorRow>*/}

            {/*    <EditorRow label={"Gemeinde"} className={"mb-2"}>*/}
            {/*        <GemeindeAutocomplete/>*/}
            {/*    </EditorRow>*/}
            {/*</AdresseEditorPanel>*/}

            {/*<EditorSpacer/>*/}
            {/*<KontaktdatenEditorPanel kontaktdaten={veranstaltung.kontaktdatenPrivat!} title={"Kontaktdaten privat"}*/}
            {/*                         onUpdate={handleUpdateKontaktdatenPrivat}>*/}
            {/*    <EditorRow label={""} className={"mb-2"}>*/}
            {/*        <Form.Check type={"checkbox"} checked={veranstaltung.emailUnerwuenscht}*/}
            {/*                    label={"E-Mail Kontakt unerwünscht"}*/}
            {/*                    onChange={handleUpdateEmailUnerwuenscht}/>*/}
            {/*    </EditorRow>*/}
            {/*    <EditorRow label={""} className={"mb-2"}>*/}
            {/*        <Form.Check type={"checkbox"} checked={veranstaltung.kontaktUnerwuenscht}*/}
            {/*                    label={"Jeglicher Kontakt unerwünscht"}*/}
            {/*                    onChange={handleUpdateKontaktUnerwuenscht}/>*/}
            {/*    </EditorRow>*/}
            {/*</KontaktdatenEditorPanel>*/}

            {/*<EditorSpacer/>*/}
            {/*<KontaktdatenEditorPanel kontaktdaten={veranstaltung.kontaktdatenArbeit!} title={"Kontaktdaten Arbeit"}*/}
            {/*                         onUpdate={handleUpdateKontaktdatenArbeit}/>*/}

            {/*<EditorSpacer/>*/}
            {/*<BankverbindungEditorPanel bankverbindung={veranstaltung.bankverbindung!} onUpdate={handleUpdateBankverbindung}/>*/}

            {/*<EditorSpacer/>*/}
            {/*<ReisepassEditorPanel reisepass={veranstaltung.reisepass!} onUpdate={handleUpdateReisepass}/>*/}

            {/*<EditorSpacer/>*/}
            {/*<ReisepassEditorPanel title={"Reisepass 2"} reisepass={veranstaltung.reisepass2!}*/}
            {/*                      onUpdate={handleUpdateReisepass2}/>*/}

            {/*<EditorSpacer/>*/}
            {/*<h4>Sonstiges</h4>*/}
            {/*<EditorRow label={"Bemerkungen"} className={"mb-2"}>*/}
            {/*    <Form.Control placeholder={"Bemerkungen"} value={veranstaltung.bemerkungen || ""}*/}
            {/*                  onChange={handleUpdateBemerkungen} as="textarea" rows={3}/>*/}
            {/*</EditorRow>*/}

            {/*<EditorRow label={""} className={"mb-2"}>*/}
            {/*    <ReferatSelect value={veranstaltung.referat} onChange={handleUpdateSozialreferat}/>*/}
            {/*</EditorRow>*/}
        </div>

        <EditorButtons/>
    </Form>;
}