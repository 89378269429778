import {useMutation, useQuery} from "@tanstack/react-query";
import {TeilnehmerRolle} from "@api/stammdaten.types";
import {queryClient} from "@api/query-client";
import {StammdatenItemRequestParams, StammdatenListRequestParams} from "@api/stammdaten";
import {
    deleteTeilnehmerRollenItem,
    loadTeilnehmerRolleList,
    loadTeilnehmerRollenItem,
    updateTeilnehmerRollenItem
} from "@api/teilnehmer-rollen";

export const useLoadTeilnehmerRollenPaginationList = (params: StammdatenListRequestParams) => {
    return useQuery<TeilnehmerRolle[], Error>(
        ["teilnehmer-rollen-list-paged", params],
        async () => {
            return await loadTeilnehmerRolleList(params);
        }
    )
};

export const useLoadTeilnehmerRollenItem = (params: StammdatenItemRequestParams) => {
    return useQuery<TeilnehmerRolle, Error>(
        ["teilnehmer-rollen-item", params],
        async () => {
            return await loadTeilnehmerRollenItem(params);
        }
    )
};

export const useUpdateTeilnehmerRollenItem = (options?: {
       onSuccess?: (id: number) => void;
       onError?: (err: unknown) => void;
   }) => {
    return useMutation({
        mutationFn: updateTeilnehmerRollenItem,
        onSuccess: async (data) => {
            await queryClient.invalidateQueries({queryKey: ["teilnehmer-rollen-list-paged"]});
            await queryClient.invalidateQueries({queryKey: ["teilnehmer-rollen-item"]});
            if (options?.onSuccess) {
                options.onSuccess(data.data);
            }
        },
        onError: (err) => {
            if (options?.onError) {
                options.onError(err);
            }
        },
    });
}

export const useDeleteTeilnehmerRollenItem = (options?: {
       onSuccess?: () => void;
       onError?: (err: unknown) => void;
   }) => {
    return useMutation({
        mutationFn: deleteTeilnehmerRollenItem,
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: ["teilnehmer-rollen-list-paged"]});
            await queryClient.invalidateQueries({queryKey: ["teilnehmer-rollen-item"]});
            if (options?.onSuccess) {
                options.onSuccess();
            }
        },
        onError: (err) => {
            if (options?.onError) {
                options.onError(err);
            }
        },
    });
}
