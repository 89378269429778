import React from "react";
import {PageContentHeader} from "@features/ui/components/PageContentHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus, faSearch} from "@fortawesome/free-solid-svg-icons";
import {Button, Container, Row} from "react-bootstrap";
import {PersonenListFilterPanel} from "@features/personen/components/PersonenListFilterPanel";
import {Pagination} from "@features/ui/components/Pagination";
import {PersonenList} from "@features/personen/components/PersonenList";
import styles from "@scss/ListPageLayout.module.scss";
import {
    convertOrderState,
    convertPaginationState,
    useLoadPersonenPaginationList
} from "@features/personen/api/personen-api";
import {PageLoader} from "@features/ui/components/PageLoader";
import {useAppDispatch, useAppSelector} from "@store/hooks";
import {personenActions, PersonenListFilterState,} from "@features/personen/store/store";
import {OrderState, PaginationState} from "@api/defaults";

export const PersonenListPage = () => {
    const filterPanelOpen = useAppSelector(state => state.personen.filterPanelOpen);
    const dispatch = useAppDispatch();

    const handleToggleFilterPanel = () => {
        dispatch(personenActions.setFilterPanelOpen(true));
    }

    const handlePaginationPage = (newPage:number) => {
        dispatch(personenActions.setPage(newPage));
    }

    const filters:PersonenListFilterState = {
        search: useAppSelector(state => state.personen.search),
        alter: useAppSelector(state => state.personen.alter),
    }

    const pagination:PaginationState = {
        page: useAppSelector(state => state.personen.page),
        size: useAppSelector(state => state.personen.size),
    }

    const orderBy:OrderState = {
        orderBy: useAppSelector(state => state.personen.orderBy),
        orderByDirection: useAppSelector(state => state.personen.orderByDirection),
    }

    const { data, isLoading, isError } = useLoadPersonenPaginationList({
        ...convertPaginationState(pagination),
        ...convertOrderState(orderBy),
        ...filters,
    });

    if (isError) {
        return <div>Error</div>
    }

    return <>
        <Container fluid className={styles.layout}>
            {filterPanelOpen || <Row className={styles.header}>
                <PageContentHeader title={"Personen"}>
                    <Button variant={"link"} onClick={handleToggleFilterPanel}>
                        <FontAwesomeIcon icon={faPlus} size={"lg"}/>
                    </Button>
                    <Button variant={"link"} onClick={handleToggleFilterPanel}>
                        <FontAwesomeIcon icon={faSearch} size={"lg"}/>
                    </Button>
                </PageContentHeader>
            </Row>}
            {filterPanelOpen && <Row className={styles.filter}>
                <PersonenListFilterPanel/>
            </Row>}
            {isLoading && <Row className={styles.list}>
                <PageLoader message={"Personen werden geladen"}/>
            </Row>}
            {isLoading || <Row className={styles.list}>
                <PersonenList personen={data?.list} />
            </Row>}
            {data && <Row className={styles.pagination}>
                <Pagination pages={data.pages} currentPage={pagination.page} onPageChanged={handlePaginationPage} />
            </Row>}
        </Container>
    </>;
}