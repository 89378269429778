import {Button, Form} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {PersonenListFilterState} from "@features/personen/store/store";
import {useAppDispatch, useAppSelector} from "@store/hooks";
import React, {ChangeEvent} from "react";
import _ from "lodash";
import {appSettings} from "@api/defaults";
import {RootState} from "@store/store";
import {StammdatenActions, StammdatenState} from "@features/stammdaten/store/store";
import {CaseReducerActions} from "@reduxjs/toolkit";

type StammdatenListFilterPanelProps = {
    getState: (state: RootState) => StammdatenState;
    actions: CaseReducerActions<StammdatenActions, string>;
}

export const StammdatenListFilterPanel: React.FC<StammdatenListFilterPanelProps> = (props) => {
    const filters:PersonenListFilterState = {
        search: useAppSelector(state => props.getState(state).search),
    }
    const dispatch = useAppDispatch();

    const handleResetForm = () => {
        dispatch(props.actions.resetFilters());
        dispatch(props.actions.setFilterPanelOpen(false));
    }

    const handleSetSearch = (e: ChangeEvent<HTMLInputElement>) => {
        dispatch(props.actions.setSearch(e.target.value));
    }

    return <Form onReset={handleResetForm} className="rowLayout rowLayoutGap my-4">
            <div className={"rowLayoutFlex"}>
                <Form.Control placeholder="Suche" size={"sm"} onChange={_.debounce(handleSetSearch, appSettings.debounce)}
                              defaultValue={filters.search || ""}/>
            </div>
            <div className={"text-end"}>
                <Button type={"reset"} variant={"danger"} size={"sm"}>
                    <FontAwesomeIcon icon={faTimes}/>
                </Button>
            </div>
        </Form>;
}