import {axios} from "@api/axios";
import {OrderRequestParam, PaginationRequestParam, PaginationResponse} from "@api/query.types";
import {ListVeranstaltung, Veranstaltung} from "@api/veranstaltungen.types";
import {VeranstaltungenListFilterState} from "@features/veranstaltungen/store/store";

const ENDPOINT = "/admin/veranstaltungen";

export interface VeranstaltungenListRequestParams extends PaginationRequestParam,OrderRequestParam,VeranstaltungenListFilterState {}
export interface VeranstaltungItemRequestParams {
    id: number;
}

export const loadVeranstaltungenPaginationList = async (params: VeranstaltungenListRequestParams) => {
    if (!params.orderBy) {
        params.orderBy = "turnusse.von:ASC";
    }
    const { data } = await axios.get<PaginationResponse<ListVeranstaltung>>(`${ENDPOINT}/pages`, {
        params
    });
    return data;
}

export const loadVeranstaltungenItem = async (params: VeranstaltungItemRequestParams) => {
    const { data } = await axios.get<Veranstaltung>(`${ENDPOINT}/${params.id}`);
    return data;
}
