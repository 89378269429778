import React, {ChangeEvent, useEffect, useState} from "react";
import {Form} from "react-bootstrap";
import {Geschlecht} from "@api/personen.types";

type GenderSelectProps = {
    required?: boolean;
    value?: Geschlecht;
    size?: 'sm' | 'lg';
    className?: string | undefined;
    onChange?: (geschlecht?: Geschlecht) => void;
}

const genderSelectOptions = [
    {
        value: undefined,
        label: "Geschlecht: Bitte wählen"
    },
    {
        value: Geschlecht.WEIBLICH.valueOf(),
        label: "Weiblich"
    },
    {
        value: Geschlecht.MAENNLICH.valueOf(),
        label: "Männlich"
    },
    {
        value: Geschlecht.DIVERS.valueOf(),
        label: "Divers"
    },
];

export const GenderSelect = React.forwardRef<HTMLSelectElement, GenderSelectProps>((props, ref: React.ForwardedRef<HTMLSelectElement>) => {
    const [value, setValue] = useState(props.value);
    useEffect(() => {
        setValue(props.value)
    }, [props.value]);

    const handleChange = (e:ChangeEvent<HTMLSelectElement>) => {
        if (props.onChange) {
            props.onChange(Geschlecht[e.target.value as keyof typeof Geschlecht])
        }
    }

    return <Form.Select placeholder={"Geschlecht"} value={value?.valueOf()} ref={ref} size={props.size} className={props.className} onChange={handleChange} required={props.required}>
        {genderSelectOptions.map(o => <option value={o.value} key={`options_${o.value || "none"}`}>{o.label}</option>)}
    </Form.Select>;
});