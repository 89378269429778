import {Institution} from "@api/institutionen.types";
import {TeilnahmeForPerson} from "@api/teilnahmen.types";

export enum Geschlecht {
    WEIBLICH = "WEIBLICH",
    MAENNLICH = "MAENNLICH",
    DIVERS = "DIVERS",
}

export type Personendaten = {
    geschlecht?: Geschlecht;
    titel?: string;
    vorname?: string;
    nachname?: string;
}

export type Kontaktdaten = {
    email?: string;
    telefon?: string;
    mobil?: string;
    fax?: string;
}

export type Adresse = {
    adresse1?: string;
    adresse2?: string;
    plz?: string;
    ort?: string;
    land?: string;
}

export type Bankverbindung = {
    name?: string;
    kreditinstitut?: string;
    iban?: string;
    bic?: string;
    kontonummer?: string;
    blz?: string;
}

export type Reisepass = {
    nummer?: string;
    staatsangehoerigkeit?: string;
    ausgestelltAm?: Date;
    gueltigBis?: Date;
}

export type ListPerson = {
    id?: number;
    alter?: number;
    personendaten: Personendaten;
    adresse: Adresse;
    kontaktdatenPrivat: Kontaktdaten;
    kontaktdatenArbeit: Kontaktdaten;
    beruf?: string;
    geburtsdatum?: Date;
    verstorbenAm?: Date;
    verzogenAm?: Date;
    bankverbindung: Bankverbindung;
    emailUnerwuenscht?: boolean;
    kontaktUnerwuenscht?: boolean;
    bemerkungen?: string;
    reisepass: Reisepass;
    reisepass2: Reisepass;
    sozialreferat: boolean;
    jugendreferat: boolean;
    gemeinde?: Institution;
};

export type Person = {
    id?: number;
    alter?: number;
    personendaten: Personendaten;
    adresse: Adresse;
    kontaktdatenPrivat: Kontaktdaten;
    kontaktdatenArbeit: Kontaktdaten;
    beruf?: string;
    geburtsdatum?: Date;
    verstorbenAm?: Date;
    verzogenAm?: Date;
    bankverbindung: Bankverbindung;
    emailUnerwuenscht?: boolean;
    kontaktUnerwuenscht?: boolean;
    bemerkungen?: string;
    reisepass: Reisepass;
    reisepass2: Reisepass;
    sozialreferat: boolean;
    jugendreferat: boolean;
    gemeinde?: Institution;
    teilnahmen?: TeilnahmeForPerson[];
    primaereRolle?: any; // TODO: ändern!
    erziehungsberechtigter?: Person;
    kinder?: Person[];
};

export const createPerson = (): Person => {
    return {
        personendaten: {},
        adresse: {},
        kontaktdatenPrivat: {},
        kontaktdatenArbeit: {},
        bankverbindung: {},
        reisepass: {},
        reisepass2: {},
        sozialreferat: false,
        jugendreferat: false
    };
}
