import React, {ChangeEvent, ReactNode, useEffect, useState} from "react";
import {Bankverbindung} from "@api/personen.types";
import {Form} from "react-bootstrap";
import {EditorRow} from "@features/ui/components/EditorRow";

export type BankverbindungEditorPanelProps = {
    title?: string;
    bankverbindung: Bankverbindung;
    size?: 'sm' | 'lg';
    children?: ReactNode;
    onUpdate?: (bankverbindung: Bankverbindung) => void;
}

export const BankverbindungEditorPanel: React.FC<BankverbindungEditorPanelProps> = (props) => {
    const [bankverbindung, setBankverbindung] = useState<Bankverbindung>(props.bankverbindung);
    useEffect(() => {
        setBankverbindung(props.bankverbindung);
    }, [props.bankverbindung]);

    const updateBankverbindung = (bankverbindung: Bankverbindung) => {
        setBankverbindung(bankverbindung);
        if (props.onUpdate) {
            props.onUpdate(bankverbindung);
        }
    }

    const handleUpdateKontoinhaber = (e:ChangeEvent<HTMLInputElement>) => {
        updateBankverbindung({
            ...bankverbindung,
            name: e.target.value
        });
    }

    const handleUpdateKreditinstitut = (e:ChangeEvent<HTMLInputElement>) => {
        updateBankverbindung({
            ...bankverbindung,
            kreditinstitut: e.target.value
        });
    }

    const handleUpdateIban = (e:ChangeEvent<HTMLInputElement>) => {
        updateBankverbindung({
            ...bankverbindung,
            iban: e.target.value
        });
    }

    const handleUpdateBic = (e:ChangeEvent<HTMLInputElement>) => {
        updateBankverbindung({
            ...bankverbindung,
            bic: e.target.value
        });
    }

    return <>
        <h4>{props.title || "Bankverbindung"}</h4>

        <EditorRow label={"Kontoinhaber"} className={"mb-2"}>
            <Form.Control placeholder={"Kontoinhaber"} value={bankverbindung.name || ""} size={props.size} className={"mb-2"} onChange={handleUpdateKontoinhaber}/>
        </EditorRow>

        <EditorRow label={"Kreditinstitut"} className={"mb-2"}>
            <Form.Control placeholder={"Kreditinstitut"} value={bankverbindung.kreditinstitut || ""} size={props.size} className={"mb-2"} onChange={handleUpdateKreditinstitut}/>
        </EditorRow>

        <EditorRow label={"IBAN"} className={"mb-2"}>
            <Form.Control placeholder={"IBAN"} value={bankverbindung.iban || ""} size={props.size} className={"mb-2"} onChange={handleUpdateIban}/>
        </EditorRow>

        <EditorRow label={"BIC"} className={"mb-2"}>
            <Form.Control placeholder={"BIC"} value={bankverbindung.bic || ""} size={props.size} className={"mb-2"} onChange={handleUpdateBic}/>
        </EditorRow>

        {(bankverbindung.kontonummer || bankverbindung.blz) && <EditorRow label={"Kontonummer, BLZ"} className={"mb-2"}>
            {bankverbindung.kontonummer || "-"}, {bankverbindung.blz || "-"}
        </EditorRow>}

        {props.children}
    </>;
}