import Axios, {AxiosResponseTransformer} from "axios";
import {isArray} from "lodash";
import {appSettings} from "@api/defaults";
import {store} from "@store/store";

const dateTransformer = (data: string): AxiosResponseTransformer => {
    if (data === '') {
        return JSON.parse('{}');
    } else {
        return JSON.parse(data, dateTimeReviver);
    }
}

const dateTimeReviver = function (key:string, value:unknown): unknown | Date {
    if (typeof value === 'string') {
        const a = /^(\d{1,2}).(\d{1,2}).(\d{4})$/.exec(value);
        if (a && isArray(a) && a.length == 4) {
            return new Date(`${a[3]}-${a[2]}-${a[1]}T00:00:00Z`);
        }
    }
    return value;
}

export const axios = Axios.create({
    baseURL: appSettings.apiBaseURL,
});

axios.defaults.transformResponse = [dateTransformer];

axios.interceptors.request.use(config => {
    const accessToken = store.getState().auth.accessToken;

    if (accessToken && config.headers) {
        config.headers.Authorization = `Bearer ${accessToken}`
    }

    return config;
});

export const axiosAuth = Axios.create({
    baseURL: appSettings.authBaseURL,
});

axiosAuth.defaults.transformResponse = [dateTransformer];
