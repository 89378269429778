import React from "react";
import {OrderByDirection} from "@api/defaults";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowDownAZ, faArrowUpZA} from "@fortawesome/free-solid-svg-icons";

type OrderByIconProps = {
    orderByProperty: string;
    property: string;
    direction: OrderByDirection;
}

export const OrderByIcon: React.FC<OrderByIconProps> = (props) => {
    if (props.property != props.orderByProperty) {
        return <></>;
    }

    if (props.direction == OrderByDirection.DESC) {
        return <FontAwesomeIcon icon={faArrowUpZA} className={"inactiveLink ml-5"}/>
    }

    return <FontAwesomeIcon icon={faArrowDownAZ} className={"inactiveLink ml-5"}/>
}
