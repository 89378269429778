import {Button, Form} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {useAppDispatch, useAppSelector} from "@store/hooks";
import React, {ChangeEvent} from "react";
import _ from "lodash";
import {appSettings} from "@api/defaults";
import {gemeindenActions, GemeindenListFilterState} from "@features/institutionen/store/store";
import {GemeindenArtSelect} from "@features/institutionsarten/components/GemeindenArtSelect";
import {InstitutionsArt} from "@api/stammdaten.types";

export const GemeindenListFilterPanel = () => {
    const filters:GemeindenListFilterState = {
        search: useAppSelector(state => state.gemeinden.search),
        institutionsart: useAppSelector(state => state.gemeinden.institutionsart),
    }
    const dispatch = useAppDispatch();

    const handleResetForm = () => {
        dispatch(gemeindenActions.resetFilters());
        dispatch(gemeindenActions.setFilterPanelOpen(false));
    }

    const handleSetSearch = (e: ChangeEvent<HTMLInputElement>) => {
        dispatch(gemeindenActions.setSearch(e.target.value));
    }

    const handleSetInstitutionsArt = (institutionsArt?: InstitutionsArt) => {
        dispatch(gemeindenActions.setInstitutionsArt(institutionsArt?.id));
    }

    return <Form onReset={handleResetForm} className="rowLayout rowLayoutGap my-4">
            <div className={"rowLayoutFlex"}>
                <Form.Control placeholder="Suche" size={"sm"} onChange={_.debounce(handleSetSearch, appSettings.debounce)}
                              defaultValue={filters.search || ""}/>
            </div>
            <div className={"rowLayoutFlex"}>
                <GemeindenArtSelect size={"sm"} value={filters.institutionsart} onChange={handleSetInstitutionsArt}/>
            </div>
            <div className={"text-end"}>
                <Button type={"reset"} variant={"danger"} size={"sm"}>
                    <FontAwesomeIcon icon={faTimes}/>
                </Button>
            </div>
        </Form>;
}