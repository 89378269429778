import React, {ChangeEvent, ReactNode, useEffect, useState} from "react";
import {Reisepass} from "@api/personen.types";
import {Form} from "react-bootstrap";
import {CountryAutocomplete} from "@features/ui/components/CountryAutocomplete";
import {EditorRow} from "@features/ui/components/EditorRow";
import {DatePicker} from "@features/ui/components/DatePicker";

export type ReisepassEditorPanelProps = {
    title?: string;
    reisepass: Reisepass;
    size?: 'sm' | 'lg';
    children?: ReactNode;
    onUpdate?: (reisepass: Reisepass) => void;
}

export const ReisepassEditorPanel: React.FC<ReisepassEditorPanelProps> = (props) => {
    const [reisepass, setReisepass] = useState<Reisepass>(props.reisepass);
    useEffect(() => {
        setReisepass(props.reisepass);
    }, [props.reisepass]);

    const updateReisepass = (reisepass: Reisepass) => {
        setReisepass(reisepass);
        if (props.onUpdate) {
            props.onUpdate(reisepass);
        }
    }

    const handleUpdateStaatsangehoerigkeit = (staatsangehoerigkeit?: string) => {
        updateReisepass({
            ...reisepass,
            staatsangehoerigkeit
        });
    }

    const handleUpdateNummer = (e:ChangeEvent<HTMLInputElement>) => {
        updateReisepass({
            ...reisepass,
            nummer: e.target.value
        });
    }

    const handleUpdateAusgestelltAm = (ausgestelltAm?: Date) => {
        updateReisepass({
            ...reisepass,
            ausgestelltAm
        });
    }

    const handleUpdateGueltigBis = (gueltigBis?: Date) => {
        updateReisepass({
            ...reisepass,
            gueltigBis
        });
    }

    return <>
        <h4>{props.title || "Reisepass 1"}</h4>

        <EditorRow label={"Staatsangehörigkeit"} className={"mb-2"}>
            <CountryAutocomplete value={reisepass.staatsangehoerigkeit} onChange={handleUpdateStaatsangehoerigkeit}/>
        </EditorRow>

        <EditorRow label={"Nummer"} className={"mb-2"}>
            <Form.Control placeholder={"Nummer"} value={reisepass.nummer || ""} size={props.size} className={"mb-2"} onChange={handleUpdateNummer}/>
        </EditorRow>

        <EditorRow label={"Ausgestellt am"} className={"mb-2"}>
            <DatePicker value={reisepass.ausgestelltAm} onChange={handleUpdateAusgestelltAm}/>
        </EditorRow>

        <EditorRow label={"Gültig bis"} className={"mb-2"}>
            <DatePicker value={reisepass.gueltigBis} onChange={handleUpdateGueltigBis}/>
        </EditorRow>

        {props.children}
    </>;
}