import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import styles from "@scss/EditorPageLayout.module.scss";
import {VeranstaltungsKategorie} from "@api/stammdaten.types";
import {useNavigate} from "react-router-dom";
import {addMessage} from "@features/ui/store/actions";
import {appSettings} from "@api/defaults";
import {
    useUpdateVeranstaltungsKategorienItem
} from "@features/veranstaltungskategorien/api/veranstaltungs-kategorien-api";
import {
    VeranstaltungsKategorieEditorPanel
} from "@features/veranstaltungskategorien/components/VeranstaltungsKategorieEditorPanel";

export const VeranstaltungsKategorienCreatePage = () => {
    const navigate = useNavigate();
    const veranstaltungsKategorie: Partial<VeranstaltungsKategorie> = {};
    const saveMutation = useUpdateVeranstaltungsKategorienItem({
        onSuccess: id => {
            addMessage({
                title: "Die Veranstaltungs-Kategorie wurde angelegt",
                timeout: appSettings.messageTimeout,
            });
            navigate(`../${id}`);
        }
    });

    const handleSubmit = (item: VeranstaltungsKategorie) => {
        saveMutation.mutate({
            id: item.id,
            item
        });
    }

    return <Container fluid className={styles.layout}>
        <Row>
            <Col xl={6}>
                <VeranstaltungsKategorieEditorPanel title={"Veranstaltungs-Kategorie anlegen"}
                                                    item={veranstaltungsKategorie}
                                                    onSubmit={item => handleSubmit(item as VeranstaltungsKategorie)}/>
            </Col>
            <Col xl={6}>
            </Col>
        </Row>
    </Container>;
}