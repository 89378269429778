import React from "react";
import {PageContentHeader} from "@features/ui/components/PageContentHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus, faSearch} from "@fortawesome/free-solid-svg-icons";
import {Button, Container, Row} from "react-bootstrap";
import styles from "@scss/ListPageLayout.module.scss";
import {convertOrderState} from "@features/personen/api/personen-api";
import {PageLoader} from "@features/ui/components/PageLoader";
import {useAppDispatch, useAppSelector} from "@store/hooks";
import {OrderState} from "@api/defaults";
import {RootState} from "@store/store";
import {LinkContainer} from "react-router-bootstrap";
import {useLoadGemeindenList} from "@features/institutionen/api/institutionen-api";
import {GemeindenList} from "@features/institutionen/components/GemeindenList";
import {gemeindenActions, GemeindenListFilterState} from "@features/institutionen/store/store";
import {GemeindenListFilterPanel} from "@features/institutionen/components/GemeindenListFilterPanel";

export const GemeindenListPage = () => {
    const getState = (state: RootState) => {
        return state.gemeinden;
    }

    const filterPanelOpen = useAppSelector(state => getState(state).filterPanelOpen);
    const dispatch = useAppDispatch();

    const handleToggleFilterPanel = () => {
        dispatch(gemeindenActions.setFilterPanelOpen(true));
    }

    const filters:GemeindenListFilterState = {
        search: useAppSelector(state => getState(state).search),
        institutionsart: useAppSelector(state => state.gemeinden.institutionsart),
    }

    const orderBy:OrderState = {
        orderBy: useAppSelector(state => getState(state).orderBy),
        orderByDirection: useAppSelector(state => getState(state).orderByDirection),
    }

    const { data, isLoading, isError } = useLoadGemeindenList({
        ...convertOrderState(orderBy),
        ...filters,
    });

    if (isError) {
        return <div>Error</div>
    }

    return <>
        <Container fluid className={styles.layout}>
            {filterPanelOpen || <Row className={styles.header}>
                <PageContentHeader title={"Gemeinden"}>
                    <LinkContainer to={"new"}>
                        <Button variant={"link"}>
                            <FontAwesomeIcon icon={faPlus} size={"lg"}/>
                        </Button>
                    </LinkContainer>
                    <Button variant={"link"} onClick={handleToggleFilterPanel}>
                        <FontAwesomeIcon icon={faSearch} size={"lg"}/>
                    </Button>
                </PageContentHeader>
            </Row>}
            {filterPanelOpen && <Row className={styles.filter}>
                <GemeindenListFilterPanel/>
            </Row>}
            {isLoading && <Row className={styles.list}>
                <PageLoader message={"Gemeinden werden geladen"}/>
            </Row>}
            {isLoading || <Row className={styles.list}>
                <GemeindenList gemeinden={data}/>
            </Row>}
        </Container>
    </>;
}