import {axios} from "@api/axios";
import {VeranstaltungsArt} from "@api/stammdaten.types";
import {StammdatenItemRequestParams, StammdatenListRequestParams} from "@api/stammdaten";

const VERANSTALTUNGS_ARTEN_ENDPOINT = "/admin/veranstaltungsarten";

export const loadVeranstaltungsArtenList = async (params: StammdatenListRequestParams) => {
    if (!params.orderBy) {
        params.orderBy = "bezeichnung:ASC";
    }
    const {data} = await axios.get<VeranstaltungsArt[]>(`${VERANSTALTUNGS_ARTEN_ENDPOINT}`, {
        params
    });
    return data;
}

export const loadVeranstaltungsArtenItem = async (params: StammdatenItemRequestParams) => {
    const {data} = await axios.get<VeranstaltungsArt>(`${VERANSTALTUNGS_ARTEN_ENDPOINT}/${params.id}`);
    return data;
}

export const updateVeranstaltungsArtenItem = async (payload: {
    id?: number,
    item: VeranstaltungsArt
}) => {
    if (payload.id) {
        return axios.put<number>(`${VERANSTALTUNGS_ARTEN_ENDPOINT}/${payload.id}`, payload.item);
    }
    return axios.post<number>(VERANSTALTUNGS_ARTEN_ENDPOINT, payload.item);
}

export const deleteVeranstaltungsArtenItem = async (id: number) => {
    return axios.delete<void>(`${VERANSTALTUNGS_ARTEN_ENDPOINT}/${id}`);
}
