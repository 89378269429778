import React from "react";
import {useNavigate, useParams} from "react-router-dom";
import {convertRouterParams, IdRouterParams, idRouterParamsTypes} from "@pages/router";
import {PageLoader} from "@features/ui/components/PageLoader";
import {Col, Container, Row} from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import styles from "@scss/EditorPageLayout.module.scss";
import {AnsprechpartnerRolle} from "@api/stammdaten.types";
import {addMessage} from "@features/ui/store/actions";
import {appSettings} from "@api/defaults";
import {
    useDeleteAnsprechpartnerRollenItem,
    useLoadAnsprechpartnerRollenItem,
    useUpdateAnsprechpartnerRollenItem
} from "@features/ansprechpartnerrollen/api/ansprechpartner-rollen-api";
import {
    AnsprechpartnerRolleEditorPanel
} from "@features/ansprechpartnerrollen/components/AnsprechpartnerRolleEditorPanel";

export const AnsprechpartnerRolleEditorPage = () => {
    const navigate = useNavigate();
    const {id} = convertRouterParams<IdRouterParams>(useParams(), [idRouterParamsTypes]);
    const {data: ansprechpartnerRolle, isLoading, isError} = useLoadAnsprechpartnerRollenItem({
        id
    });

    const saveMutation = useUpdateAnsprechpartnerRollenItem({
        onSuccess: () => {
            addMessage({
                title: "Die Ansprechpartner-Rolle wurde gespeichert",
                timeout: appSettings.messageTimeout,
            });
        }
    });

    const deleteMutation = useDeleteAnsprechpartnerRollenItem({
        onSuccess: () => {
            addMessage({
                title: "Die Ansprechpartner-Rolle wurde entfernt",
                timeout: appSettings.messageTimeout,
            });
        },
        onError: () => {
            addMessage({
                title: "Beim Entfernen der Ansprechpartner-Rolle ist ein Fehler aufgetreten",
                timeout: appSettings.messageTimeout,
            });
        }
    });

    if (isError) {
        return <div>Error</div>
    }

    if (isLoading) {
        return <PageLoader/>
    }

    const handleSubmit = async (item: AnsprechpartnerRolle) => {
        saveMutation.mutate({
            id: item.id,
            item
        });
    }
    const handleOnDelete = (id: number) => {
        deleteMutation.mutate(id);
        navigate("..");
    }

    return <Container fluid className={styles.layout}>
        <Row>
            <Col xl={6}>
                <AnsprechpartnerRolleEditorPanel title={"Ansprechpartner-Rolle bearbeiten"}
                                            item={ansprechpartnerRolle}
                                            onSubmit={item => handleSubmit(item as AnsprechpartnerRolle)}
                                            onDelete={handleOnDelete}/>
            </Col>
            <Col xl={6}>
            </Col>
        </Row>
    </Container>;
}