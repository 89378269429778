import {countries} from "@api/countries.data";

export interface Country {
    code: string;
    name: string;
}

export const getCountry = (code?:string):Country|undefined => {
    if (code == undefined) {
        return undefined;
    }
    code = code.toLowerCase()
    const country = countries.find(c => c.code.toLowerCase() == code);
    if (!country) {
        return undefined;
    }
    return country;
};

export const getCountryName = (code?:string):string|undefined => {
    if (code == undefined) {
        return undefined;
    }
    code = code.toLowerCase()
    const country = countries.find(c => c.code.toLowerCase() == code);
    if (!country) {
        return undefined;
    }
    return country.name;
};

export const filterCountriesForAutocomplete = (search:string):Country[] => {
    search = search.toLowerCase();
    return countries.filter(c => c.name.toLowerCase().startsWith(search));
};

