import React, {useEffect, useState} from "react";
import {Typeahead} from "react-bootstrap-typeahead";
import {countries} from "@api/countries.data";
import {Country, getCountry} from "@api/countries";
import {uniqueId} from "lodash";
import {Option} from "react-bootstrap-typeahead/types/types";
import styles from "@scss/Autocomplete.module.scss";

type CountryAutocompleteProps = {
    id?: string;
    value?: string;
    placeholder?: string;
    onChange?: (countryCode?: string) => void;
    className?: string | undefined;
}

export const CountryAutocomplete: React.FC<CountryAutocompleteProps> = (props) => {
    const [singleSelections, setSingleSelections] = useState<Country[]>([]);
    useEffect(() => {
        const s: Country | undefined = getCountry(props.value);
        setSingleSelections(s ? [s] : []);
    }, [props.value]);

    const id = props.id || uniqueId("countryautocomplete_");

    const handleChange = (option: Option[]) => {
        setSingleSelections(option as Country[]);
        if (props.onChange) {
            props.onChange(option.length ? (option[0] as Country).code : undefined);
        }
    }

    return <Typeahead
        className={`${styles.autocomplete} ${styles.autocompleteStretch} ${props.className}`}
        id={id}
        clearButton
        labelKey="name"
        // labelKey={(option: Option) => `${option.firstName} ${option.lastName}`}
        options={countries}
        onChange={handleChange}
        placeholder={props.placeholder || "Land"}
        selected={singleSelections}
    />;
};