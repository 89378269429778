import React from "react";
import {Person} from "@api/personen.types";
import {GenderIcon} from "@features/ui/components/GenderIcon";
import {preventDefault} from "@features/ui/utils/eventHandlerUtils";
import {useLoadPersonenItem} from "@features/personen/api/personen-api";
import {PageLoader} from "@features/ui/components/PageLoader";
import {formatName} from "@features/personen/utils/personenUtils";
import {Button, Col, Row} from "react-bootstrap";
import {formatDate} from "@features/ui/utils/dateTimeUtils";
import {formatInstitution} from "@features/institutionen/utils/institutionenUtils";
import {Link} from "react-router-dom";
import {formatTurnusTitel} from "@features/veranstaltungen/utils/veranstaltungenUtils";

export type PersonPreviewPanelParams = {
    title?: string;
    personId: number;
    onShowPersonEditor?: (person: Person) => void;
};

export const PersonPreviewPanel: React.FC<PersonPreviewPanelParams> = (props) => {
    const {data: person, isLoading, isError} = useLoadPersonenItem({
        id: props.personId
    });

    if (isError) {
        return <div>Error</div>
    }

    if (isLoading) {
        return <PageLoader/>
    }

    const handleDetailsClick = () => {
        if (props.onShowPersonEditor) {
            props.onShowPersonEditor(person);
        }
    }

    return <div onClick={preventDefault}>
        <Row>
            <Col md={4}>
                <div>
                    <GenderIcon gender={person.personendaten?.geschlecht} size={"sm"}/>
                    &nbsp;
                    {formatName(person.personendaten)}
                </div>
                <div>
                    {formatDate(person.geburtsdatum)}
                </div>
                <div>
                    {formatInstitution(person.gemeinde)}
                </div>
            </Col>
            <Col md={4}>
                {person.erziehungsberechtigter && <>
                    Erziehungsberechtiger:
                    <div>
                        <GenderIcon gender={person.erziehungsberechtigter?.personendaten.geschlecht} size={"sm"}/>
                        &nbsp;
                        {formatName(person.erziehungsberechtigter?.personendaten)}
                    </div>
                </>}
                {(person.kinder?.length && person.kinder?.length > 0) ?
                    <div>
                        Kinder:
                        <div>
                            {person.kinder.map(k => <div className={"mb-1"} key={`kinder_${person.id}_${k.id}`}>
                                <Link to={`/personen/${k.id}`}>
                                    {formatName(k.personendaten)}, {formatDate(k.geburtsdatum)}
                                </Link>
                            </div>)}
                        </div>
                    </div> : ""}
            </Col>
            <Col md={4}>
                {(person.teilnahmen?.length && person.teilnahmen?.length > 0) ?
                    <div>
                        Veranstaltungen:
                        <div>
                            {person.teilnahmen.map(t => <div className={"mb-1"}
                                                             key={`terilnahmen_${person.id}_${t.id}`}>
                                <Link to={`/teilnahmen/${t.id}`}>
                                    <div>
                                        {formatTurnusTitel(t.turnus)}
                                    </div>
                                    <div>
                                        {formatDate(t.turnus.von)}
                                        &nbsp;-&nbsp;
                                        {formatDate(t.turnus.bis)}
                                    </div>
                                </Link>
                            </div>)}
                        </div>
                    </div> : ""}
            </Col>
        </Row>
        <Button onClick={handleDetailsClick}>Bearbeiten</Button>
    </div>;
}