import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export interface UiMessage {
    id?: string;
    title: string;
    message?: string;
    timeout?: number;
}

interface UiState {
    leftSidebarOpen: boolean;
    rightSidebarOpen: boolean;
    footerSlotRight?: string;
    uiMessages: UiMessage[];
}

const initialState: UiState = {
    leftSidebarOpen: false,
    rightSidebarOpen: false,
    uiMessages: [],
};

export const uiSlice = createSlice({
    name: "ui",
    initialState,
    reducers: {
        toggleLeftSidebar: state => {
            state.leftSidebarOpen = !state.leftSidebarOpen;
        },
        openLeftSidebar: state => {
            state.leftSidebarOpen = true;
        },
        closeLeftSidebar: state => {
            state.leftSidebarOpen = false;
        },
        toggleRightSidebar: state => {
            state.rightSidebarOpen = !state.rightSidebarOpen;
        },
        openRightSidebar: state => {
            state.rightSidebarOpen = true;
        },
        closeRightSidebar: state => {
            state.rightSidebarOpen = false;
        },
        setFooterSlotRight: (state, action: PayloadAction<string|undefined>) => {
            state.footerSlotRight = action.payload;
        },
        clearFooterSlotRight: state => {
            state.footerSlotRight = undefined;
        },

        addMessage: (state, action: PayloadAction<UiMessage>) => {
            state.uiMessages.push(action.payload)
        },
        removeMessage: (state, action: PayloadAction<string>) => {
            state.uiMessages = [
                ...state.uiMessages.filter(m => !m.id || m.id != action.payload)
            ];
        },
        clearMessages: state => {
            state.uiMessages = [];
        },
    }
});

export const uiActions = uiSlice.actions;
export const uiReducer = uiSlice.reducer;
