import React, {ChangeEvent, ReactNode, useEffect, useState} from "react";
import {Geschlecht, Personendaten} from "@api/personen.types";
import {Form} from "react-bootstrap";
import {GenderSelect} from "@features/ui/components/GenderSelect";
import {EditorRow} from "@features/ui/components/EditorRow";

export type PersonendatenEditorPanelProps = {
    title?: string;
    personendaten: Personendaten;
    size?: 'sm' | 'lg';
    children?: ReactNode;
    onUpdate?: (personendaten: Personendaten) => void;
}

export const PersonendatenEditorPanel: React.FC<PersonendatenEditorPanelProps> = (props) => {
    const [personendaten, setPersonendaten] = useState<Personendaten>(props.personendaten);
    useEffect(() => {
        setPersonendaten(props.personendaten);
    }, [props.personendaten]);

    const updatePersonendaten = (personendaten: Personendaten) => {
        setPersonendaten(personendaten);
        if (props.onUpdate) {
            props.onUpdate(personendaten);
        }
    }

    const handleUpdateGeschlecht = (geschlecht?: Geschlecht) => {
        updatePersonendaten({
            ...personendaten,
            geschlecht
        });
    }

    const handleUpdateTitel = (e:ChangeEvent<HTMLInputElement>) => {
        updatePersonendaten({
            ...personendaten,
            titel: e.target.value
        });
    }

    const handleUpdateVorname = (e:ChangeEvent<HTMLInputElement>) => {
        updatePersonendaten({
            ...personendaten,
            vorname: e.target.value
        });
    }

    const handleUpdateNachname = (e:ChangeEvent<HTMLInputElement>) => {
        updatePersonendaten({
            ...personendaten,
            nachname: e.target.value
        });
    }

    return <>
        <h4>{props.title || "Personendaten"}</h4>

        <EditorRow label={"Geschlecht, Titel"} className={"mb-2"}>
            <GenderSelect value={personendaten.geschlecht} size={props.size} onChange={handleUpdateGeschlecht}/>
            <Form.Control placeholder="Prof. Dr." value={personendaten.titel || ""} size={props.size} onChange={handleUpdateTitel}/>
        </EditorRow>

        <EditorRow label={"Vorname"} className={"mb-2"}>
            <Form.Control placeholder={"Vorname"} value={personendaten.vorname || ""} size={props.size} onChange={handleUpdateVorname}/>
        </EditorRow>

        <EditorRow label={"Nachname"} className={"mb-2"}>
            <Form.Control placeholder={"Nachname"} value={personendaten.nachname || ""} size={props.size} onChange={handleUpdateNachname}/>
        </EditorRow>

        {props.children}
    </>;
}