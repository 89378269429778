import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {OrderByDirection, OrderState} from "@api/defaults";

export interface StammdatenListState {
    filterPanelOpen: boolean;
}

export interface StammdatenListFilterState {
    search?: string;
}

export interface StammdatenState extends StammdatenListState, StammdatenListFilterState, OrderState {}

export type StammdatenActions = {
    setFilterPanelOpen: (state: any, action: PayloadAction<boolean>) => void;
    resetOrderBy: (state: any) => void;
    setOrderBy: (state: any, action: PayloadAction<OrderState>) => void;
    resetFilters: (state: any) => void;
    setSearch: (state: any, action: PayloadAction<string | undefined>) => void;
}

const createStammdatenSLice = (sliceName: string) => {

    const initialState: StammdatenState = {
        filterPanelOpen: false,
        orderBy: "bezeichnung",
        orderByDirection: OrderByDirection.ASC,
    };

    return createSlice({
        name: sliceName,
        initialState,
        reducers: {
            setFilterPanelOpen: (state, action: PayloadAction<boolean>) => {
                state.filterPanelOpen = action.payload;
            },

            setSearch: (state, action: PayloadAction<string|undefined>) => {
                state.search = action.payload;
            },
            resetFilters: (state) => {
                state.search = undefined;
            },

            setOrderBy: (state, action: PayloadAction<OrderState>) => {
                state.orderBy = action.payload.orderBy;
                state.orderByDirection = action.payload.orderByDirection;
            },
            resetOrderBy: (state) => {
                state.orderBy = initialState.orderBy;
                state.orderByDirection = initialState.orderByDirection;
            }
        }
    });
}

export const institutionsArtenSlice = createStammdatenSLice("institutionsArten");
export const institutionsArtenActions = institutionsArtenSlice.actions;
export const institutionsArtenReducer = institutionsArtenSlice.reducer;

export const teilnehmerRollenSlice = createStammdatenSLice("teilnehmerRollen");
export const teilnehmerRollenActions = teilnehmerRollenSlice.actions;
export const teilnehmerRollenReducer = teilnehmerRollenSlice.reducer;

export const ansprechpartnerRollenSlice = createStammdatenSLice("ansprechpartnerRollen");
export const ansprechpartnerRollenActions = ansprechpartnerRollenSlice.actions;
export const ansprechpartnerRollenReducer = ansprechpartnerRollenSlice.reducer;

export const veranstaltungsArtenSlice = createStammdatenSLice("veranstaltungsArten");
export const veranstaltungsArtenActions = veranstaltungsArtenSlice.actions;
export const veranstaltungsArtenReducer = veranstaltungsArtenSlice.reducer;

export const veranstaltungsKategorienSlice = createStammdatenSLice("veranstaltungsKategorien");
export const veranstaltungsKategorienActions = veranstaltungsKategorienSlice.actions;
export const veranstaltungsKategorienReducer = veranstaltungsKategorienSlice.reducer;

