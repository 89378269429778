import {axios} from "@api/axios";
import {VeranstaltungsKategorie} from "@api/stammdaten.types";
import {StammdatenItemRequestParams, StammdatenListRequestParams} from "@api/stammdaten";

const VERANSTALTUNGS_KATEGORIEN_ENDPOINT = "/admin/veranstaltungskategorien";

export const loadVeranstaltungsKategorienList = async (params: StammdatenListRequestParams) => {
    if (!params.orderBy) {
        params.orderBy = "bezeichnung:ASC";
    }
    const {data} = await axios.get<VeranstaltungsKategorie[]>(`${VERANSTALTUNGS_KATEGORIEN_ENDPOINT}`, {
        params
    });
    return data;
}

export const loadVeranstaltungsKategorienItem = async (params: StammdatenItemRequestParams) => {
    const {data} = await axios.get<VeranstaltungsKategorie>(`${VERANSTALTUNGS_KATEGORIEN_ENDPOINT}/${params.id}`);
    return data;
}

export const updateVeranstaltungsKategorienItem = async (payload: {
    id?: number,
    item: VeranstaltungsKategorie
}) => {
    if (payload.id) {
        return axios.put<number>(`${VERANSTALTUNGS_KATEGORIEN_ENDPOINT}/${payload.id}`, payload.item);
    }
    return axios.post<number>(VERANSTALTUNGS_KATEGORIEN_ENDPOINT, payload.item);
}

export const deleteVeranstaltungsKategorienItem = async (id: number) => {
    return axios.delete<void>(`${VERANSTALTUNGS_KATEGORIEN_ENDPOINT}/${id}`);
}
