import {useNavigate} from "react-router-dom";
import React, {MouseEvent, ReactNode} from "react";
import {Button} from "react-bootstrap";
import {ButtonVariant} from "react-bootstrap/types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleLeft} from "@fortawesome/free-solid-svg-icons";

type BackButtonProps = {
    label?: string;
    variant?: ButtonVariant;
    size?: 'sm' | 'lg';
    children?: ReactNode;
}

export const BackButton: React.FC<BackButtonProps> = (props) => {
    const navigate = useNavigate();

    const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
        if (!e.defaultPrevented) {
            e.preventDefault();
            navigate("..");
        }
    }

    return <Button variant={props.variant || "secondary"} size={props.size} onClick={handleClick}>
        {props.children ? props.children : props.label || <>
            <FontAwesomeIcon icon={faAngleLeft}/>
            &nbsp;
            Zurück
        </>}
    </Button>
}
