import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleNotch} from "@fortawesome/free-solid-svg-icons";
import styles from "@features/ui/style/PageLoader.module.scss";

export type PageLoaderProperties = {
    message?:string;
};

const defaultMessage = "Daten werden geladen";

export const PageLoader: React.FC<PageLoaderProperties> = (props) => {

    return <div className={styles.pageLoader}>
        <div className={styles.pageLoaderContent}>
            <div>
                <FontAwesomeIcon icon={faCircleNotch} size={"xl"} spin/>
            </div>
            <br/>
            {props.message || defaultMessage}
        </div>
    </div>;
};