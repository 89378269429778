import {useQuery} from "@tanstack/react-query";
import {
    loadPersonenItem,
    loadPersonenPaginationList,
    PersonenListRequestParams,
    PersonItemRequestParams
} from "@api/personen";
import {OrderRequestParam, PaginationRequestParam, PaginationResponse} from "@api/query.types";
import {ListPerson, Person} from "@api/personen.types";
import {OrderState, PaginationState} from "@api/defaults";

export const useLoadPersonenPaginationList = (params: PersonenListRequestParams) => {
    return useQuery<PaginationResponse<ListPerson>, Error>(
        ["personen-list-paged", params],
        async () => {
            return await loadPersonenPaginationList(params);
        }
    )
};

export const useLoadPersonenItem = (params: PersonItemRequestParams) => {
    return useQuery<Person, Error>(
        ["personen-item", params],
        async () => {
            return await loadPersonenItem(params);
        }
    )
};

export const convertPaginationState = (paginationState:PaginationState): PaginationRequestParam => {
    if (!paginationState) {
        throw new Error("PaginationState must not be undefined");
    }
    return paginationState;
}

export const convertOrderState = (orderState:OrderState|undefined): OrderRequestParam|undefined => {
    if (!orderState || !orderState.orderBy || !orderState.orderByDirection) {
        return undefined;
    }
    return {
        orderBy : `${orderState.orderBy}:${orderState.orderByDirection}`
    }
}

// const useCreateTaskListTemplates = () => {
//     return useMutation({
//         mutationFn: createTaskListTemplate,
//         onSuccess: () => {
//             queryClient.invalidateQueries({ queryKey: ['task-list-templates'] })
//         },
//     });
// }
//
// const useDeleteTaskListTemplates = () => {
//     return useMutation({
//         mutationFn: deleteTaskListTemplate,
//         onSuccess: () => {
//             queryClient.invalidateQueries({ queryKey: ['task-list-templates'] })
//         },
//     });
// }

// export {personenList,useCreateTaskListTemplates,useDeleteTaskListTemplates};