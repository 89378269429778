import {axios} from "@api/axios";
import {TeilnehmerRolle} from "@api/stammdaten.types";
import {StammdatenItemRequestParams, StammdatenListRequestParams} from "@api/stammdaten";

const TEILNEHMER_ROLLEN_ENDPOINT = "/admin/teilnehmerrollen";

export const loadTeilnehmerRolleList = async (params: StammdatenListRequestParams) => {
    if (!params.orderBy) {
        params.orderBy = "bezeichnung:ASC";
    }
    const {data} = await axios.get<TeilnehmerRolle[]>(`${TEILNEHMER_ROLLEN_ENDPOINT}`, {
        params
    });
    return data;
}

export const loadTeilnehmerRollenItem = async (params: StammdatenItemRequestParams) => {
    const {data} = await axios.get<TeilnehmerRolle>(`${TEILNEHMER_ROLLEN_ENDPOINT}/${params.id}`);
    return data;
}

export const updateTeilnehmerRollenItem = async (payload: {
    id?: number,
    item: TeilnehmerRolle
}) => {
    if (payload.id) {
        return axios.put<number>(`${TEILNEHMER_ROLLEN_ENDPOINT}/${payload.id}`, payload.item);
    }
    return axios.post<number>(TEILNEHMER_ROLLEN_ENDPOINT, payload.item);
}

export const deleteTeilnehmerRollenItem = async (id: number) => {
    return axios.delete<void>(`${TEILNEHMER_ROLLEN_ENDPOINT}/${id}`);
}
