import React from "react";
import {TeilnahmeForPerson, TeilnahmeForVeranstaltung} from "@api/teilnahmen.types";
import {TeilnahmenInlineDetailsPanel} from "@features/teilnahmen/components/TeilnahmenInlineDetailsPanel";

type TeilnahmenInlineListPanelProps = {
    teilnahmen?: TeilnahmeForPerson[]|TeilnahmeForVeranstaltung[];
}

export const TeilnahmenInlineListPanel: React.FC<TeilnahmenInlineListPanelProps> = (props) => {

    if (!props.teilnahmen) {
        return <></>;
    }

    return <div className={"p-3"}>
        <h4>Teilnahmen</h4>
        {props.teilnahmen.map(t => <TeilnahmenInlineDetailsPanel key={t.id} teilnahme={t}/>)}
    </div>
}