import {configureStore} from "@reduxjs/toolkit";
import {authReducer} from "@features/auth/store/store";
import {uiReducer} from "@features/ui/store/store";
import {personenReducer} from "@features/personen/store/store";
import {
    ansprechpartnerRollenReducer,
    institutionsArtenReducer,
    teilnehmerRollenReducer,
    veranstaltungsArtenReducer,
    veranstaltungsKategorienReducer
} from "@features/stammdaten/store/store";
import {gemeindenReducer, veranstaltungsOrteReducer} from "@features/institutionen/store/store";
import {veranstaltungenReducer} from "@features/veranstaltungen/store/store";

export const store = configureStore({
    reducer: {
        auth: authReducer,
        ui: uiReducer,
        personen: personenReducer,
        gemeinden: gemeindenReducer,
        veranstaltungsOrte: veranstaltungsOrteReducer,
        institutionsArten: institutionsArtenReducer,
        teilnehmerRollen: teilnehmerRollenReducer,
        ansprechpartnerRollen: ansprechpartnerRollenReducer,
        veranstaltungen: veranstaltungenReducer,
        veranstaltungsArten: veranstaltungsArtenReducer,
        veranstaltungsKategorien: veranstaltungsKategorienReducer,
    }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
