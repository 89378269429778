import {appSettings} from "@api/defaults";
import {useEffect, useRef} from "react";
import jwt_decode from "jwt-decode";
import {useDispatch} from "react-redux";
import {authActions} from "@features/auth/store/store";
import {useAppSelector} from "@store/hooks";
import {obtainAccessToken} from "@api/auth";
import {useNavigate} from "react-router-dom";

export interface AccessToken {
    aud: string;
    authorities: string[];
    exp: number;
    iat: number;
    iss: string;
    nbf: number;
    sub: string;
}

export const LoginForm = () => {
    const iframe = useRef<HTMLIFrameElement>(null);
    const iframeTimeoutRef = useRef<number|undefined>(undefined);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const authenticated = useAppSelector(state => state.auth.authenticated);
    const prevAuthenticatedRef = useRef<boolean>();
    useEffect(() => {
        if (prevAuthenticatedRef.current === true && !authenticated) {
            navigate(0);
        }
        prevAuthenticatedRef.current = authenticated;
    });

    const codeChallenge = "mhaVjUOGomhFdP0R_Prrq3XyHOkvgBp-KlhvGgezKsc";
    const codeVerifier = "46q2nHZ2bYwzXdN.vYxDbNCN0JCAkLq-_cbTgJOWuPiRTkad_cNek2oWC1c.tupCmCFrmkkB_Sn1tZjCCbgN9OMRb7kSkIhen3FTXv_h6TE_fiO0gQV6I9Fy67Qh1dW.";

    const handleUpdateAccessToken = (event:MessageEvent) => {
        if (event.data.eventId !== 'update_access_token') {
            return;
        }

        if (typeof event.data.authorizationCode != "string" || event.data.authorizationCode.length <= 0) {
            console.warn("update_access_token data invalid: authorizationCode", event.data.authorizationCode);
            return;
        }

        handleObtainAccessToken(event.data.authorizationCode);
    };

    useEffect(() => {
        window.addEventListener("message", handleUpdateAccessToken);

        return () => {
            window.removeEventListener("message", handleUpdateAccessToken);
            if (iframeTimeoutRef.current) {
                window.clearTimeout(iframeTimeoutRef.current);
            }
        }
    }, [])

    const params = new URLSearchParams({
        response_type: "code",
        client_id: appSettings.authClientId,
        code_challenge: codeChallenge,
        code_challenge_method: "S256",
    });

    const handleObtainAccessToken = async (authorizationCode: string) => {
        try {
            const response = await obtainAccessToken({
                clientId: appSettings.authClientId,
                authorizationCode,
                codeVerifier,
            });

            if (typeof response.access_token != "string" || response.access_token.length <= 0) {
                throw new Error("Token data invalid: access_token = '" + response.access_token + "'");
            }

            if (typeof response.expires_in != "number" || response.expires_in <= 0) {
                throw new Error("Token data invalid: expires_in = '" + response.expires_in + "'");
            }

            if (typeof response.token_type != "string" || response.token_type !== "Bearer") {
                throw new Error("Invalid token type '" + response.token_type + "', excpected 'Bearer'");
            }

            console.log("Access Token updated at:", (new Date()).toLocaleString('de-DE'));
            // console.log("Access-Token");
            // console.log(response.access_token);
            // console.log("Token expires in: ", response.expires_in);
            // console.log("Token refresh in: ", Math.floor(response.expires_in * 1000 * 0.9));

            const decodedToken = jwt_decode(response.access_token) as AccessToken;
            console.log(decodedToken);
            if (decodedToken.sub && decodedToken.authorities) {
                dispatch(authActions.setAccessToken(response.access_token));
                dispatch(authActions.setAuthenticated(true));
                dispatch(authActions.setAuthorities(decodedToken.authorities));
                dispatch(authActions.setUsername(decodedToken.sub));
                // dispatch(authActions.setValidUntil(decodedToken.exp));
            } else {

            }

            if (iframeTimeoutRef.current) {
                window.clearTimeout(iframeTimeoutRef.current);
            }

            iframeTimeoutRef.current = window.setTimeout(() => {
                handleReload();
            }, Math.floor(response.expires_in * 1000 * 0.9));

        } catch (e) {
            console.warn("Error obtaining Access-Token");
            console.error(e);
            dispatch(authActions.clearAuth());
        }
    }

    const handleReload = () => {
        console.log("RELOAD AT:", (new Date()).toLocaleString('de-DE'));
        if (iframe.current) {
            iframe.current.src += "";
        }
    }

    return <div className={authenticated ? "invisible" : ""}>
            <iframe src={`${appSettings.authUrl}?${params.toString()}`} ref={iframe}/>
    </div>
}