import {Table} from "react-bootstrap";
import React, {MouseEvent} from "react";
import {useAppDispatch, useAppSelector} from "@store/hooks";
import {OrderState} from "@api/defaults";
import {OrderableTableHeader} from "@features/ui/components/OrderableTableHeader";
import {useNavigate} from "react-router-dom";
import {ListInstitution} from "@api/institutionen.types";
import {gemeindenActions} from "@features/institutionen/store/store";

export type GemeindenListProps = {
    gemeinden?: ListInstitution[];
}

export const GemeindenList: React.FC<GemeindenListProps> = (props) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const orderBy:OrderState = {
        orderBy: useAppSelector(state => state.gemeinden.orderBy),
        orderByDirection: useAppSelector(state => state.gemeinden.orderByDirection),
    }

    const handleRowClick = (e:MouseEvent<HTMLTableRowElement>, item: ListInstitution) => {
        if (!e.defaultPrevented) {
            navigate(`${item.id}`);
        }
    }

    const handleOrderByChanged = (orderBy:OrderState) => {
        dispatch(gemeindenActions.setOrderBy(orderBy));
    }

    return <Table responsive hover>
        <thead>
        <tr>
            <OrderableTableHeader label={"Name"} property={"name"} orderBy={orderBy} onChangeOrder={handleOrderByChanged}/>
            <OrderableTableHeader label={"Landesverband"} property={"oberInstitution.name"} orderBy={orderBy} onChangeOrder={handleOrderByChanged}/>
        </tr>
        </thead>
        <tbody>
        {props.gemeinden?.map((gemeinde) => <tr key={gemeinde.id} onClick={(e) => handleRowClick(e, gemeinde)} className={"cursorClickable"}>
            <td>{gemeinde.name} {gemeinde.name2}</td>
            <td>{gemeinde.oberInstitution ? `${gemeinde.oberInstitution.name} ${gemeinde.oberInstitution.name2}` : "-"}</td>
        </tr>)}
        </tbody>
    </Table>;
}