import React from 'react';
import ReactDOM from 'react-dom/client';
// import reportWebVitals from './reportWebVitals';
import {RouterProvider} from "react-router";
import {router} from "@pages/router";
import {Provider} from 'react-redux'
import {store} from "@store/store";

const root = ReactDOM.createRoot(
    // document.body as HTMLElement
    document.getElementById('app-root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <RouterProvider router={router}></RouterProvider>
        </Provider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
