import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {appSettings, OrderByDirection, OrderState, PaginationState} from "@api/defaults";

export interface VeranstaltungenListState {
    filterPanelOpen: boolean;
}

export interface VeranstaltungenListFilterState {
    search?: string;
    alter?:number;
}

export interface VeranstaltungenState extends VeranstaltungenListState, VeranstaltungenListFilterState, PaginationState, OrderState {}

const initialState: VeranstaltungenState = {
    filterPanelOpen: false,
    page: 0,
    size: appSettings.pageSize,
    orderBy: "turnusse.von",
    orderByDirection: OrderByDirection.ASC,
};

export const veranstaltungenSlice = createSlice({
    name: "veranstaltungen",
    initialState,
    reducers: {
        setFilterPanelOpen: (state, action: PayloadAction<boolean>) => {
            state.filterPanelOpen = action.payload;
        },
        setPage: (state, action: PayloadAction<number>) => {
            state.page = action.payload;
        },
        setSize: (state, action: PayloadAction<number>) => {
            state.size = action.payload;
        },
        resetPagination: (state) => {
            state.page = 0;
        },

        setSearch: (state, action: PayloadAction<string|undefined>) => {
            state.search = action.payload;
        },
        setAlter: (state, action: PayloadAction<number|undefined>) => {
            state.alter = action.payload;
        },
        resetFilters: (state) => {
            state.search = undefined;
            state.alter = undefined;
        },

        setOrderBy: (state, action: PayloadAction<OrderState>) => {
            state.orderBy = action.payload.orderBy;
            state.orderByDirection = action.payload.orderByDirection;
        },
        resetOrderBy: (state) => {
            state.orderBy = initialState.orderBy;
            state.orderByDirection = initialState.orderByDirection;
        }
    }
});

export const veranstaltungenActions = veranstaltungenSlice.actions;
// export const selectMainNavigationOpen = (state: RootState) => state.ui.leftSidebarOpen;
export const veranstaltungenReducer = veranstaltungenSlice.reducer;

