import React, {ChangeEvent, ReactNode, useEffect, useState} from "react";
import {Kontaktdaten} from "@api/personen.types";
import {Form} from "react-bootstrap";
import {EditorRow} from "@features/ui/components/EditorRow";

export type KontaktdatenEditorPanelProps = {
    title?: string;
    kontaktdaten: Kontaktdaten;
    size?: 'sm' | 'lg';
    children?: ReactNode;
    onUpdate?: (adresse: Kontaktdaten) => void;
}

export const KontaktdatenEditorPanel: React.FC<KontaktdatenEditorPanelProps> = (props) => {
    const [kontaktdaten, setKontaktdaten] = useState<Kontaktdaten>(props.kontaktdaten);
    useEffect(() => {
        setKontaktdaten(props.kontaktdaten);
    }, [props.kontaktdaten]);

    const updateKontaktdaten = (adresse: Kontaktdaten) => {
        setKontaktdaten(adresse);
        if (props.onUpdate) {
            props.onUpdate(adresse);
        }
    }

    const handleUpdateEmail = (e:ChangeEvent<HTMLInputElement>) => {
        updateKontaktdaten({
            ...kontaktdaten,
            email: e.target.value
        });
    }

    const handleUpdateTelefon = (e:ChangeEvent<HTMLInputElement>) => {
        updateKontaktdaten({
            ...kontaktdaten,
            telefon: e.target.value
        });
    }

    const handleUpdateMobil = (e:ChangeEvent<HTMLInputElement>) => {
        updateKontaktdaten({
            ...kontaktdaten,
            mobil: e.target.value
        });
    }

    const handleUpdateFax = (e:ChangeEvent<HTMLInputElement>) => {
        updateKontaktdaten({
            ...kontaktdaten,
            fax: e.target.value
        });
    }

    return <>
        <h4>{props.title || "Kontaktdaten"}</h4>

        <EditorRow label={"E-Mail Adresse"} className={"mb-2"}>
            <Form.Control placeholder={"E-Mail Adresse"} value={kontaktdaten.email || ""} size={props.size} className={"mb-2"} onChange={handleUpdateEmail}/>
        </EditorRow>

        <EditorRow label={"Telefon"} className={"mb-2"}>
            <Form.Control placeholder={"Telefon"} value={kontaktdaten.telefon || ""} size={props.size} className={"mb-2"} onChange={handleUpdateTelefon}/>
        </EditorRow>

        <EditorRow label={"Mobil"} className={"mb-2"}>
            <Form.Control placeholder={"Mobil"} value={kontaktdaten.mobil || ""} size={props.size} className={"mb-2"} onChange={handleUpdateMobil}/>
        </EditorRow>

        <EditorRow label={"Fax"} className={"mb-2"}>
            <Form.Control placeholder={"Fax"} value={kontaktdaten.fax || ""} size={props.size} className={"mb-2"} onChange={handleUpdateFax}/>
        </EditorRow>

        {props.children}
    </>;
}