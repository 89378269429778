import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import styles from "@scss/EditorPageLayout.module.scss";
import {Institution} from "@api/institutionen.types";
import {useNavigate} from "react-router-dom";
import {addMessage} from "@features/ui/store/actions";
import {appSettings} from "@api/defaults";
import {GemeindeEditorPanel} from "@features/institutionen/components/GemeindeEditorPanel";
import {useUpdateGemeindenItem} from "@features/institutionen/api/institutionen-api";

export const GemeindenCreatePage = () => {
    const navigate = useNavigate();
    const gemeinde: Partial<Institution> = {
        adresse: {},
        kontaktdaten: {}
    };
    const saveMutation = useUpdateGemeindenItem({
        onSuccess: id => {
            addMessage({
                title: "Die Gemeinde wurde angelegt",
                timeout: appSettings.messageTimeout,
            });
            navigate(`../${id}`);
        }
    });

    const handleSubmit = (item: Institution) => {
        alert("Es ist ein unbekannter Fehler aufgetreten");
        return;
        saveMutation.mutate({
            id: item.id,
            item
        });
    }

    return <Container fluid className={styles.layout}>
        <Row>
            <Col xl={6}>
                <GemeindeEditorPanel title={"Gemeinde anlegen"} item={gemeinde} onSubmit={item => handleSubmit(item as Institution)} />
            </Col>
            <Col xl={6}>
            </Col>
        </Row>
    </Container>;
}