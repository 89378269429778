import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import styles from "@scss/EditorPageLayout.module.scss";
import {VeranstaltungsArt} from "@api/stammdaten.types";
import {useNavigate} from "react-router-dom";
import {addMessage} from "@features/ui/store/actions";
import {appSettings} from "@api/defaults";
import {useUpdateVeranstaltungsArtenItem} from "@features/veranstaltungsarten/api/veranstaltungs-arten-api";
import {VeranstaltungsArtEditorPanel} from "@features/veranstaltungsarten/components/VeranstaltungsArtEditorPanel";

export const VeranstaltungsArtenCreatePage = () => {
    const navigate = useNavigate();
    const veranstaltungsArt: Partial<VeranstaltungsArt> = {};
    const saveMutation = useUpdateVeranstaltungsArtenItem({
        onSuccess: id => {
            addMessage({
                title: "Die Veranstaltungs-Art wurde angelegt",
                timeout: appSettings.messageTimeout,
            });
            navigate(`../${id}`);
        }
    });

    const handleSubmit = (item: VeranstaltungsArt) => {
        saveMutation.mutate({
            id: item.id,
            item
        });
    }

    return <Container fluid className={styles.layout}>
        <Row>
            <Col xl={6}>
                <VeranstaltungsArtEditorPanel title={"Veranstaltungs-Art anlegen"}
                                            item={veranstaltungsArt}
                                            onSubmit={item => handleSubmit(item as VeranstaltungsArt)}/>
            </Col>
            <Col xl={6}>
            </Col>
        </Row>
    </Container>;
}