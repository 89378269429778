import {store} from "@store/store";
import {UiMessage, uiSlice} from "@features/ui/store/store";
import {nanoid} from "nanoid";

export const sanitizeMessage = (message: UiMessage) => {
    if (!message.id) {
        message.id = nanoid(16);
    }
    return message;
}

export const addMessage = (message: UiMessage) => {
    message = sanitizeMessage(message);
    store.dispatch(uiSlice.actions.addMessage(message));
    if (message.timeout) {
        setTimeout(() => {
            store.dispatch(uiSlice.actions.removeMessage(message.id!));
        }, message.timeout);
    }
}
