import React, {useEffect, useState} from "react";
import "react-datepicker/dist/react-datepicker.css";
import {EditorRow} from "@features/ui/components/EditorRow";
import {Referat, Stammdaten, VeranstaltungsArt} from "@api/stammdaten.types";
import {StammdatenEditorPanel} from "@features/stammdaten/components/StammdatenEditorPanel";
import {ReferatSelect} from "@features/stammdaten/components/ReferatSelect";

type VeranstaltungsArtEditorPanelProps = {
    title: string;
    item: Partial<VeranstaltungsArt>,
    onSubmit: (item: Stammdaten) => void;
    onDelete?: (id: number) => void;
}

export const VeranstaltungsArtEditorPanel: React.FC<VeranstaltungsArtEditorPanelProps> = (props) => {
    const [veranstaltungsArt, setVeranstaltungsArt] = useState<Partial<VeranstaltungsArt>>(props.item);
    useEffect(() => {
        setVeranstaltungsArt(props.item);
    }, [props.item]);

    const handleSubmit = (item: VeranstaltungsArt) => {
        props.onSubmit(item);
    }

    const handleStammdatenChange = (partialVeranstaltungsArt: Partial<Stammdaten>) => {
        setVeranstaltungsArt({
            ...veranstaltungsArt,
            ...partialVeranstaltungsArt
        });
    }

    const handleOnDelete = (id: number) => {
        if (props.onDelete) {
            props.onDelete(id);
        }
    }

    const handleUpdateReferat = (referat?: Referat) => {
        setVeranstaltungsArt({
            ...veranstaltungsArt,
            referat: referat,
        });
    }

    // const handleUpdateGemeinde = (e:ChangeEvent<HTMLInputElement>) => {
    //     setVeranstaltungsArt({
    //         ...veranstaltungsArt,
    //         gemeinde: e.target.checked,
    //     });
    // }
    //
    // const handleUpdateVeranstaltungsOrt = (e:ChangeEvent<HTMLInputElement>) => {
    //     setVeranstaltungsArt({
    //         ...veranstaltungsArt,
    //         veranstaltungsOrt: e.target.checked,
    //     });
    // }
    //
    // const handleUpdateVerband = (e:ChangeEvent<HTMLInputElement>) => {
    //     setVeranstaltungsArt({
    //         ...veranstaltungsArt,
    //         verband: e.target.checked,
    //     });
    // }

    return <StammdatenEditorPanel title={props.title} item={veranstaltungsArt}
                                  onSubmit={item => handleSubmit(item as VeranstaltungsArt)}
                                  onChange={handleStammdatenChange} onDelete={handleOnDelete}>
        <EditorRow label={"Referat"} className={"mb-2"}>
            <ReferatSelect required value={veranstaltungsArt.referat} onChange={handleUpdateReferat}/>
        </EditorRow>

        {/*<EditorRow label={""} className={"mb-2"}>*/}
        {/*    <Form.Check type={"checkbox"} checked={veranstaltungsArt.gemeinde} label={"Gemeinde"} onChange={handleUpdateGemeinde}/>*/}
        {/*</EditorRow>*/}

        {/*<EditorRow label={""} className={"mb-2"}>*/}
        {/*    <Form.Check type={"checkbox"} checked={veranstaltungsArt.veranstaltungsOrt} label={"Veranstaltungsort"} onChange={handleUpdateVeranstaltungsOrt}/>*/}
        {/*</EditorRow>*/}

        {/*<EditorRow label={""} className={"mb-2"}>*/}
        {/*    <Form.Check type={"checkbox"} checked={veranstaltungsArt.verband} label={"Verband"} onChange={handleUpdateVerband}/>*/}
        {/*</EditorRow>*/}
    </StammdatenEditorPanel>;
}