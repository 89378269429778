import {Country} from "@api/countries";

export const countries: Country[] = [
    {
        "code": "AD",
        "name": "Andorra"
    },
    {
        "code": "AE",
        "name": "Vereinigte Arabische Emirate"
    },
    {
        "code": "AF",
        "name": "Afghanistan"
    },
    {
        "code": "AG",
        "name": "Antigua und Barbuda"
    },
    {
        "code": "AI",
        "name": "Anguilla"
    },
    {
        "code": "AL",
        "name": "Albanien"
    },
    {
        "code": "AM",
        "name": "Armenien"
    },
    {
        "code": "AO",
        "name": "Angola"
    },
    {
        "code": "AQ",
        "name": "Antarktis"
    },
    {
        "code": "AR",
        "name": "Argentinien"
    },
    {
        "code": "AS",
        "name": "Amerikanisch-Samoa"
    },
    {
        "code": "AT",
        "name": "Österreich"
    },
    {
        "code": "AU",
        "name": "Australien"
    },
    {
        "code": "AW",
        "name": "Aruba"
    },
    {
        "code": "AX",
        "name": "Ålandinseln"
    },
    {
        "code": "AZ",
        "name": "Aserbaidschan"
    },
    {
        "code": "BA",
        "name": "Bosnien und Herzegowina"
    },
    {
        "code": "BB",
        "name": "Barbados"
    },
    {
        "code": "BD",
        "name": "Bangladesch"
    },
    {
        "code": "BE",
        "name": "Belgien"
    },
    {
        "code": "BF",
        "name": "Burkina Faso"
    },
    {
        "code": "BG",
        "name": "Bulgarien"
    },
    {
        "code": "BH",
        "name": "Bahrain"
    },
    {
        "code": "BI",
        "name": "Burundi"
    },
    {
        "code": "BJ",
        "name": "Benin"
    },
    {
        "code": "BL",
        "name": "St. Barthélemy"
    },
    {
        "code": "BM",
        "name": "Bermuda"
    },
    {
        "code": "BN",
        "name": "Brunei Darussalam"
    },
    {
        "code": "BO",
        "name": "Bolivien"
    },
    {
        "code": "BQ",
        "name": "Karibische Niederlande"
    },
    {
        "code": "BR",
        "name": "Brasilien"
    },
    {
        "code": "BS",
        "name": "Bahamas"
    },
    {
        "code": "BT",
        "name": "Bhutan"
    },
    {
        "code": "BV",
        "name": "Bouvetinsel"
    },
    {
        "code": "BW",
        "name": "Botsuana"
    },
    {
        "code": "BY",
        "name": "Belarus"
    },
    {
        "code": "BZ",
        "name": "Belize"
    },
    {
        "code": "CA",
        "name": "Kanada"
    },
    {
        "code": "CC",
        "name": "Kokosinseln"
    },
    {
        "code": "CD",
        "name": "Kongo-Kinshasa"
    },
    {
        "code": "CF",
        "name": "Zentralafrikanische Republik"
    },
    {
        "code": "CG",
        "name": "Kongo-Brazzaville"
    },
    {
        "code": "CH",
        "name": "Schweiz"
    },
    {
        "code": "CI",
        "name": "Côte d’Ivoire"
    },
    {
        "code": "CK",
        "name": "Cookinseln"
    },
    {
        "code": "CL",
        "name": "Chile"
    },
    {
        "code": "CM",
        "name": "Kamerun"
    },
    {
        "code": "CN",
        "name": "China"
    },
    {
        "code": "CO",
        "name": "Kolumbien"
    },
    {
        "code": "CR",
        "name": "Costa Rica"
    },
    {
        "code": "CU",
        "name": "Kuba"
    },
    {
        "code": "CV",
        "name": "Cabo Verde"
    },
    {
        "code": "CW",
        "name": "Curaçao"
    },
    {
        "code": "CX",
        "name": "Weihnachtsinsel"
    },
    {
        "code": "CY",
        "name": "Zypern"
    },
    {
        "code": "CZ",
        "name": "Tschechien"
    },
    {
        "code": "DE",
        "name": "Deutschland"
    },
    {
        "code": "DJ",
        "name": "Dschibuti"
    },
    {
        "code": "DK",
        "name": "Dänemark"
    },
    {
        "code": "DM",
        "name": "Dominica"
    },
    {
        "code": "DO",
        "name": "Dominikanische Republik"
    },
    {
        "code": "DZ",
        "name": "Algerien"
    },
    {
        "code": "EC",
        "name": "Ecuador"
    },
    {
        "code": "EE",
        "name": "Estland"
    },
    {
        "code": "EG",
        "name": "Ägypten"
    },
    {
        "code": "EH",
        "name": "Westsahara"
    },
    {
        "code": "ER",
        "name": "Eritrea"
    },
    {
        "code": "ES",
        "name": "Spanien"
    },
    {
        "code": "ET",
        "name": "Äthiopien"
    },
    {
        "code": "FI",
        "name": "Finnland"
    },
    {
        "code": "FJ",
        "name": "Fidschi"
    },
    {
        "code": "FK",
        "name": "Falklandinseln"
    },
    {
        "code": "FM",
        "name": "Mikronesien"
    },
    {
        "code": "FO",
        "name": "Färöer"
    },
    {
        "code": "FR",
        "name": "Frankreich"
    },
    {
        "code": "GA",
        "name": "Gabun"
    },
    {
        "code": "GB",
        "name": "Vereinigtes Königreich"
    },
    {
        "code": "GD",
        "name": "Grenada"
    },
    {
        "code": "GE",
        "name": "Georgien"
    },
    {
        "code": "GF",
        "name": "Französisch-Guayana"
    },
    {
        "code": "GG",
        "name": "Guernsey"
    },
    {
        "code": "GH",
        "name": "Ghana"
    },
    {
        "code": "GI",
        "name": "Gibraltar"
    },
    {
        "code": "GL",
        "name": "Grönland"
    },
    {
        "code": "GM",
        "name": "Gambia"
    },
    {
        "code": "GN",
        "name": "Guinea"
    },
    {
        "code": "GP",
        "name": "Guadeloupe"
    },
    {
        "code": "GQ",
        "name": "Äquatorialguinea"
    },
    {
        "code": "GR",
        "name": "Griechenland"
    },
    {
        "code": "GS",
        "name": "Südgeorgien und die Südlichen Sandwichinseln"
    },
    {
        "code": "GT",
        "name": "Guatemala"
    },
    {
        "code": "GU",
        "name": "Guam"
    },
    {
        "code": "GW",
        "name": "Guinea-Bissau"
    },
    {
        "code": "GY",
        "name": "Guyana"
    },
    {
        "code": "HK",
        "name": "Sonderverwaltungsregion Hongkong"
    },
    {
        "code": "HM",
        "name": "Heard und McDonaldinseln"
    },
    {
        "code": "HN",
        "name": "Honduras"
    },
    {
        "code": "HR",
        "name": "Kroatien"
    },
    {
        "code": "HT",
        "name": "Haiti"
    },
    {
        "code": "HU",
        "name": "Ungarn"
    },
    {
        "code": "ID",
        "name": "Indonesien"
    },
    {
        "code": "IE",
        "name": "Irland"
    },
    {
        "code": "IL",
        "name": "Israel"
    },
    {
        "code": "IM",
        "name": "Isle of Man"
    },
    {
        "code": "IN",
        "name": "Indien"
    },
    {
        "code": "IO",
        "name": "Britisches Territorium im Indischen Ozean"
    },
    {
        "code": "IQ",
        "name": "Irak"
    },
    {
        "code": "IR",
        "name": "Iran"
    },
    {
        "code": "IS",
        "name": "Island"
    },
    {
        "code": "IT",
        "name": "Italien"
    },
    {
        "code": "JE",
        "name": "Jersey"
    },
    {
        "code": "JM",
        "name": "Jamaika"
    },
    {
        "code": "JO",
        "name": "Jordanien"
    },
    {
        "code": "JP",
        "name": "Japan"
    },
    {
        "code": "KE",
        "name": "Kenia"
    },
    {
        "code": "KG",
        "name": "Kirgisistan"
    },
    {
        "code": "KH",
        "name": "Kambodscha"
    },
    {
        "code": "KI",
        "name": "Kiribati"
    },
    {
        "code": "KM",
        "name": "Komoren"
    },
    {
        "code": "KN",
        "name": "St. Kitts und Nevis"
    },
    {
        "code": "KP",
        "name": "Nordkorea"
    },
    {
        "code": "KR",
        "name": "Südkorea"
    },
    {
        "code": "KW",
        "name": "Kuwait"
    },
    {
        "code": "KY",
        "name": "Kaimaninseln"
    },
    {
        "code": "KZ",
        "name": "Kasachstan"
    },
    {
        "code": "LA",
        "name": "Laos"
    },
    {
        "code": "LB",
        "name": "Libanon"
    },
    {
        "code": "LC",
        "name": "St. Lucia"
    },
    {
        "code": "LI",
        "name": "Liechtenstein"
    },
    {
        "code": "LK",
        "name": "Sri Lanka"
    },
    {
        "code": "LR",
        "name": "Liberia"
    },
    {
        "code": "LS",
        "name": "Lesotho"
    },
    {
        "code": "LT",
        "name": "Litauen"
    },
    {
        "code": "LU",
        "name": "Luxemburg"
    },
    {
        "code": "LV",
        "name": "Lettland"
    },
    {
        "code": "LY",
        "name": "Libyen"
    },
    {
        "code": "MA",
        "name": "Marokko"
    },
    {
        "code": "MC",
        "name": "Monaco"
    },
    {
        "code": "MD",
        "name": "Republik Moldau"
    },
    {
        "code": "ME",
        "name": "Montenegro"
    },
    {
        "code": "MF",
        "name": "St. Martin"
    },
    {
        "code": "MG",
        "name": "Madagaskar"
    },
    {
        "code": "MH",
        "name": "Marshallinseln"
    },
    {
        "code": "MK",
        "name": "Nordmazedonien"
    },
    {
        "code": "ML",
        "name": "Mali"
    },
    {
        "code": "MM",
        "name": "Myanmar"
    },
    {
        "code": "MN",
        "name": "Mongolei"
    },
    {
        "code": "MO",
        "name": "Sonderverwaltungsregion Macau"
    },
    {
        "code": "MP",
        "name": "Nördliche Marianen"
    },
    {
        "code": "MQ",
        "name": "Martinique"
    },
    {
        "code": "MR",
        "name": "Mauretanien"
    },
    {
        "code": "MS",
        "name": "Montserrat"
    },
    {
        "code": "MT",
        "name": "Malta"
    },
    {
        "code": "MU",
        "name": "Mauritius"
    },
    {
        "code": "MV",
        "name": "Malediven"
    },
    {
        "code": "MW",
        "name": "Malawi"
    },
    {
        "code": "MX",
        "name": "Mexiko"
    },
    {
        "code": "MY",
        "name": "Malaysia"
    },
    {
        "code": "MZ",
        "name": "Mosambik"
    },
    {
        "code": "NA",
        "name": "Namibia"
    },
    {
        "code": "NC",
        "name": "Neukaledonien"
    },
    {
        "code": "NE",
        "name": "Niger"
    },
    {
        "code": "NF",
        "name": "Norfolkinsel"
    },
    {
        "code": "NG",
        "name": "Nigeria"
    },
    {
        "code": "NI",
        "name": "Nicaragua"
    },
    {
        "code": "NL",
        "name": "Niederlande"
    },
    {
        "code": "NO",
        "name": "Norwegen"
    },
    {
        "code": "NP",
        "name": "Nepal"
    },
    {
        "code": "NR",
        "name": "Nauru"
    },
    {
        "code": "NU",
        "name": "Niue"
    },
    {
        "code": "NZ",
        "name": "Neuseeland"
    },
    {
        "code": "OM",
        "name": "Oman"
    },
    {
        "code": "PA",
        "name": "Panama"
    },
    {
        "code": "PE",
        "name": "Peru"
    },
    {
        "code": "PF",
        "name": "Französisch-Polynesien"
    },
    {
        "code": "PG",
        "name": "Papua-Neuguinea"
    },
    {
        "code": "PH",
        "name": "Philippinen"
    },
    {
        "code": "PK",
        "name": "Pakistan"
    },
    {
        "code": "PL",
        "name": "Polen"
    },
    {
        "code": "PM",
        "name": "St. Pierre und Miquelon"
    },
    {
        "code": "PN",
        "name": "Pitcairninseln"
    },
    {
        "code": "PR",
        "name": "Puerto Rico"
    },
    {
        "code": "PS",
        "name": "Palästinensische Autonomiegebiete"
    },
    {
        "code": "PT",
        "name": "Portugal"
    },
    {
        "code": "PW",
        "name": "Palau"
    },
    {
        "code": "PY",
        "name": "Paraguay"
    },
    {
        "code": "QA",
        "name": "Katar"
    },
    {
        "code": "RE",
        "name": "Réunion"
    },
    {
        "code": "RO",
        "name": "Rumänien"
    },
    {
        "code": "RS",
        "name": "Serbien"
    },
    {
        "code": "RU",
        "name": "Russland"
    },
    {
        "code": "RW",
        "name": "Ruanda"
    },
    {
        "code": "SA",
        "name": "Saudi-Arabien"
    },
    {
        "code": "SB",
        "name": "Salomonen"
    },
    {
        "code": "SC",
        "name": "Seychellen"
    },
    {
        "code": "SD",
        "name": "Sudan"
    },
    {
        "code": "SE",
        "name": "Schweden"
    },
    {
        "code": "SG",
        "name": "Singapur"
    },
    {
        "code": "SH",
        "name": "St. Helena"
    },
    {
        "code": "SI",
        "name": "Slowenien"
    },
    {
        "code": "SJ",
        "name": "Spitzbergen und Jan Mayen"
    },
    {
        "code": "SK",
        "name": "Slowakei"
    },
    {
        "code": "SL",
        "name": "Sierra Leone"
    },
    {
        "code": "SM",
        "name": "San Marino"
    },
    {
        "code": "SN",
        "name": "Senegal"
    },
    {
        "code": "SO",
        "name": "Somalia"
    },
    {
        "code": "SR",
        "name": "Suriname"
    },
    {
        "code": "SS",
        "name": "Südsudan"
    },
    {
        "code": "ST",
        "name": "São Tomé und Príncipe"
    },
    {
        "code": "SV",
        "name": "El Salvador"
    },
    {
        "code": "SX",
        "name": "Sint Maarten"
    },
    {
        "code": "SY",
        "name": "Syrien"
    },
    {
        "code": "SZ",
        "name": "Eswatini"
    },
    {
        "code": "TC",
        "name": "Turks- und Caicosinseln"
    },
    {
        "code": "TD",
        "name": "Tschad"
    },
    {
        "code": "TF",
        "name": "Französische Süd- und Antarktisgebiete"
    },
    {
        "code": "TG",
        "name": "Togo"
    },
    {
        "code": "TH",
        "name": "Thailand"
    },
    {
        "code": "TJ",
        "name": "Tadschikistan"
    },
    {
        "code": "TK",
        "name": "Tokelau"
    },
    {
        "code": "TL",
        "name": "Timor-Leste"
    },
    {
        "code": "TM",
        "name": "Turkmenistan"
    },
    {
        "code": "TN",
        "name": "Tunesien"
    },
    {
        "code": "TO",
        "name": "Tonga"
    },
    {
        "code": "TR",
        "name": "Türkei"
    },
    {
        "code": "TT",
        "name": "Trinidad und Tobago"
    },
    {
        "code": "TV",
        "name": "Tuvalu"
    },
    {
        "code": "TW",
        "name": "Taiwan"
    },
    {
        "code": "TZ",
        "name": "Tansania"
    },
    {
        "code": "UA",
        "name": "Ukraine"
    },
    {
        "code": "UG",
        "name": "Uganda"
    },
    {
        "code": "UM",
        "name": "Amerikanische Überseeinseln"
    },
    {
        "code": "US",
        "name": "Vereinigte Staaten"
    },
    {
        "code": "UY",
        "name": "Uruguay"
    },
    {
        "code": "UZ",
        "name": "Usbekistan"
    },
    {
        "code": "VA",
        "name": "Vatikanstadt"
    },
    {
        "code": "VC",
        "name": "St. Vincent und die Grenadinen"
    },
    {
        "code": "VE",
        "name": "Venezuela"
    },
    {
        "code": "VG",
        "name": "Britische Jungferninseln"
    },
    {
        "code": "VI",
        "name": "Amerikanische Jungferninseln"
    },
    {
        "code": "VN",
        "name": "Vietnam"
    },
    {
        "code": "VU",
        "name": "Vanuatu"
    },
    {
        "code": "WF",
        "name": "Wallis und Futuna"
    },
    {
        "code": "WS",
        "name": "Samoa"
    },
    {
        "code": "YE",
        "name": "Jemen"
    },
    {
        "code": "YT",
        "name": "Mayotte"
    },
    {
        "code": "ZA",
        "name": "Südafrika"
    },
    {
        "code": "ZM",
        "name": "Sambia"
    },
    {
        "code": "ZW",
        "name": "Simbabwe"
    }
].sort((a, b) => a.name.localeCompare(b.name));
