import React from "react";
import {PageContentHeader} from "@features/ui/components/PageContentHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus, faSearch} from "@fortawesome/free-solid-svg-icons";
import {Button, Container, Row} from "react-bootstrap";
import {Pagination} from "@features/ui/components/Pagination";
import styles from "@scss/ListPageLayout.module.scss";
import {convertOrderState, convertPaginationState} from "@features/personen/api/personen-api";
import {PageLoader} from "@features/ui/components/PageLoader";
import {useAppDispatch, useAppSelector} from "@store/hooks";
import {OrderState, PaginationState} from "@api/defaults";
import {VeranstaltungenListFilterPanel} from "@features/veranstaltungen/components/VeranstaltungenListFilterPanel";
import {VeranstaltungenList} from "@features/veranstaltungen/components/VeranstaltungenList";
import {veranstaltungenActions, VeranstaltungenListFilterState} from "@features/veranstaltungen/store/store";
import {useLoadVeranstaltungenPaginationList} from "@features/veranstaltungen/api/veranstaltungen-api";

export const VeranstaltungenListPage = () => {
    const filterPanelOpen = useAppSelector(state => state.veranstaltungen.filterPanelOpen);
    const dispatch = useAppDispatch();

    const handleToggleFilterPanel = () => {
        dispatch(veranstaltungenActions.setFilterPanelOpen(true));
    }

    const handlePaginationPage = (newPage:number) => {
        dispatch(veranstaltungenActions.setPage(newPage));
    }

    const filters:VeranstaltungenListFilterState = {
        search: useAppSelector(state => state.veranstaltungen.search),
        alter: useAppSelector(state => state.veranstaltungen.alter),
    }

    const pagination:PaginationState = {
        page: useAppSelector(state => state.veranstaltungen.page),
        size: useAppSelector(state => state.veranstaltungen.size),
    }

    const orderBy:OrderState = {
        orderBy: useAppSelector(state => state.veranstaltungen.orderBy),
        orderByDirection: useAppSelector(state => state.veranstaltungen.orderByDirection),
    }

    const { data, isLoading, isError } = useLoadVeranstaltungenPaginationList({
        ...convertPaginationState(pagination),
        ...convertOrderState(orderBy),
        ...filters,
    });

    if (isError) {
        return <div>Error</div>
    }

    return <>
        <Container fluid className={styles.layout}>
            {filterPanelOpen || <Row className={styles.header}>
                <PageContentHeader title={"Veranstaltungen"}>
                    <Button variant={"link"} onClick={handleToggleFilterPanel}>
                        <FontAwesomeIcon icon={faPlus} size={"lg"}/>
                    </Button>
                    <Button variant={"link"} onClick={handleToggleFilterPanel}>
                        <FontAwesomeIcon icon={faSearch} size={"lg"}/>
                    </Button>
                </PageContentHeader>
            </Row>}
            {filterPanelOpen && <Row className={styles.filter}>
                <VeranstaltungenListFilterPanel/>
            </Row>}
            {isLoading && <Row className={styles.list}>
                <PageLoader message={"Veranstaltungen werden geladen"}/>
            </Row>}
            {isLoading || <Row className={styles.list}>
                <VeranstaltungenList veranstaltungen={data?.list} />
            </Row>}
            {data && <Row className={styles.pagination}>
                <Pagination pages={data.pages} currentPage={pagination.page} onPageChanged={handlePaginationPage} />
            </Row>}
        </Container>
    </>;
}