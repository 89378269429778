import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {appSettings, OrderByDirection, OrderState, PaginationState} from "@api/defaults";

export interface PersonenListState {
    filterPanelOpen: boolean;
}

export interface PersonenListFilterState {
    search?: string;
    alter?:number;
}

export interface PersonenState extends PersonenListState, PersonenListFilterState, PaginationState, OrderState {}

const initialState: PersonenState = {
    filterPanelOpen: false,
    page: 0,
    size: appSettings.pageSize,
    orderBy: "personendaten.nachname",
    orderByDirection: OrderByDirection.ASC,
};

export const personenSlice = createSlice({
    name: "personen",
    initialState,
    reducers: {
        setFilterPanelOpen: (state, action: PayloadAction<boolean>) => {
            state.filterPanelOpen = action.payload;
        },
        setPage: (state, action: PayloadAction<number>) => {
            state.page = action.payload;
        },
        setSize: (state, action: PayloadAction<number>) => {
            state.size = action.payload;
        },
        resetPagination: (state) => {
            state.page = 0;
        },

        setSearch: (state, action: PayloadAction<string|undefined>) => {
            state.search = action.payload;
        },
        setAlter: (state, action: PayloadAction<number|undefined>) => {
            state.alter = action.payload;
        },
        resetFilters: (state) => {
            state.search = undefined;
            state.alter = undefined;
        },

        setOrderBy: (state, action: PayloadAction<OrderState>) => {
            state.orderBy = action.payload.orderBy;
            state.orderByDirection = action.payload.orderByDirection;
        },
        resetOrderBy: (state) => {
            state.orderBy = initialState.orderBy;
            state.orderByDirection = initialState.orderByDirection;
        }
    }
});

export const personenActions = personenSlice.actions;
// export const selectMainNavigationOpen = (state: RootState) => state.ui.leftSidebarOpen;
export const personenReducer = personenSlice.reducer;

