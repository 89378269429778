import {useMutation, useQuery} from "@tanstack/react-query";
import {StammdatenItemRequestParams, StammdatenListRequestParams} from "@api/stammdaten";
import {InstitutionsArt} from "@api/stammdaten.types";
import {queryClient} from "@api/query-client";
import {
    deleteInstitutionsArtenItem,
    loadInstitutionsArtenItem,
    loadInstitutionsArtenList,
    updateInstitutionsArtenItem
} from "@api/institutions-arten";

export const useLoadInstitutionsArtenList = (params: StammdatenListRequestParams) => {
    return useQuery<InstitutionsArt[], Error>(
        ["institutions-arten-list", params],
        async () => {
            return await loadInstitutionsArtenList(params);
        }
    )
};

export const useLoadGemeindeArtenList = () => {
    return useQuery<InstitutionsArt[], Error>(
        ["gemeinde-arten-list"],
        async () => {
            return await loadInstitutionsArtenList({
                gemeinde: true
            });
        }
    )
};

export const useLoadVeranstaltungsOrteArtenList = () => {
    return useQuery<InstitutionsArt[], Error>(
        ["veranstaltungs-orte-arten-list"],
        async () => {
            return await loadInstitutionsArtenList({
                veranstaltungsOrt: true
            });
        }
    )
};

export const useLoadInstitutionsArtenItem = (params: StammdatenItemRequestParams) => {
    return useQuery<InstitutionsArt, Error>(
        ["institutions-arten-item", params],
        async () => {
            return await loadInstitutionsArtenItem(params);
        }
    )
};

export const useUpdateInstitutionsArtenItem = (options?: {
       onSuccess?: (id: number) => void;
       onError?: (err: unknown) => void;
   }) => {
    return useMutation({
        mutationFn: updateInstitutionsArtenItem,
        onSuccess: async (data) => {
            await queryClient.invalidateQueries({queryKey: ["institutions-arten-list-paged"]});
            await queryClient.invalidateQueries({queryKey: ["institutions-arten-item"]});
            if (options?.onSuccess) {
                options.onSuccess(data.data);
            }
        },
        onError: (err) => {
            if (options?.onError) {
                options.onError(err);
            }
        },
    });
}

export const useDeleteInstitutionsArtenItem = (options?: {
       onSuccess?: () => void;
       onError?: (err: unknown) => void;
   }) => {
    return useMutation({
        mutationFn: deleteInstitutionsArtenItem,
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: ["institutions-arten-list-paged"]});
            await queryClient.invalidateQueries({queryKey: ["institutions-arten-item"]});
            if (options?.onSuccess) {
                options.onSuccess();
            }
        },
        onError: (err) => {
            if (options?.onError) {
                options.onError(err);
            }
        },
    });
}
