import {InstitutionsArt} from "@api/stammdaten.types";
import {axios} from "@api/axios";
import {StammdatenItemRequestParams, StammdatenListRequestParams} from "@api/stammdaten";

export interface InstitutionsArtenListRequestParams extends StammdatenListRequestParams {
    gemeinde?: boolean;
    veranstaltungsOrt?: boolean;
}

const INSTITUTIONS_ARTEN_ENDPOINT = "/admin/institutionsarten";
export const loadInstitutionsArtenList = async (params: InstitutionsArtenListRequestParams) => {
    if (!params.orderBy) {
        params.orderBy = "bezeichnung:ASC";
    }
    const {data} = await axios.get<InstitutionsArt[]>(`${INSTITUTIONS_ARTEN_ENDPOINT}`, {
        params
    });
    return data;
}
export const loadInstitutionsArtenItem = async (params: StammdatenItemRequestParams) => {
    const {data} = await axios.get<InstitutionsArt>(`${INSTITUTIONS_ARTEN_ENDPOINT}/${params.id}`);
    return data;
}
export const updateInstitutionsArtenItem = async (payload: {
    id?: number,
    item: InstitutionsArt
}) => {
    if (payload.id) {
        return axios.put<number>(`${INSTITUTIONS_ARTEN_ENDPOINT}/${payload.id}`, payload.item);
    }
    return axios.post<number>(INSTITUTIONS_ARTEN_ENDPOINT, payload.item);
}
export const deleteInstitutionsArtenItem = async (id: number) => {
    return axios.delete<void>(`${INSTITUTIONS_ARTEN_ENDPOINT}/${id}`);
}