import {Geschlecht, Personendaten} from "@api/personen.types";

export const formatAnrede = (geschlecht?: Geschlecht, defaultLabel?: string): string => {
    switch (geschlecht) {
        case Geschlecht.WEIBLICH: return "Frau";
        case Geschlecht.MAENNLICH: return "Herr";
        case Geschlecht.DIVERS: return "Person";
        default: return defaultLabel || "";

    }
}

export const formatBriefAnrede = (geschlecht?: Geschlecht, defaultLabel?: string): string => {
    switch (geschlecht) {
        case Geschlecht.WEIBLICH: return "Sehr geehrte Frau";
        case Geschlecht.MAENNLICH: return "Sehr geehrter Herr";
        case Geschlecht.DIVERS: return "Guten Tag,";
        default: return defaultLabel || "";
    }
}

export const formatName = (personendaten?: Personendaten, defaultLabel?: string): string => {
    return personendaten ?
        `${personendaten.titel || ""} ${personendaten.vorname || ""} ${personendaten.nachname || ""}`.trim()
        : defaultLabel || "";
}
