import React, {ChangeEvent, useEffect, useState} from "react";
import {Form} from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import {EditorRow} from "@features/ui/components/EditorRow";
import {Referat, Stammdaten, TeilnehmerRolle} from "@api/stammdaten.types";
import {StammdatenEditorPanel} from "@features/stammdaten/components/StammdatenEditorPanel";
import {ReferatSelect} from "@features/stammdaten/components/ReferatSelect";

type TeilnehmerRolleEditorPanelProps = {
    title: string;
    item: Partial<TeilnehmerRolle>,
    onSubmit: (item: Stammdaten) => void;
    onDelete?: (id: number) => void;
}

export const TeilnehmerRolleEditorPanel: React.FC<TeilnehmerRolleEditorPanelProps> = (props) => {
    const [teilnehmerRolle, setTeilnehmerRolle] = useState<Partial<TeilnehmerRolle>>(props.item);
    useEffect(() => {
        setTeilnehmerRolle(props.item);
    }, [props.item]);

    const handleSubmit = (item: TeilnehmerRolle) => {
        props.onSubmit(item);
    }

    const handleStammdatenChange = (partialTeilnehmerRolle: Partial<Stammdaten>) => {
        setTeilnehmerRolle({
            ...teilnehmerRolle,
            ...partialTeilnehmerRolle
        });
    }

    const handleOnDelete = (id: number) => {
        if (props.onDelete) {
            props.onDelete(id);
        }
    }

    const handleUpdateReferat = (referat?: Referat) => {
        setTeilnehmerRolle({
            ...teilnehmerRolle,
            referat: referat,
        });
    }

    const handleUpdateTeam = (e: ChangeEvent<HTMLInputElement>) => {
        setTeilnehmerRolle({
            ...teilnehmerRolle,
            team: e.target.checked,
        });
    }

    const handleUpdateHonorar = (e: ChangeEvent<HTMLInputElement>) => {
        setTeilnehmerRolle({
            ...teilnehmerRolle,
            honorar: e.target.checked,
        });
    }

    return <StammdatenEditorPanel title={props.title} item={teilnehmerRolle}
                                  onSubmit={item => handleSubmit(item as TeilnehmerRolle)}
                                  onChange={handleStammdatenChange} onDelete={handleOnDelete}>
        <EditorRow label={"Referat"} className={"mb-2"}>
            <ReferatSelect required value={teilnehmerRolle.referat} onChange={handleUpdateReferat}/>
        </EditorRow>

        <EditorRow label={""} className={"mb-2"}>
            <Form.Check type={"checkbox"} checked={teilnehmerRolle.team} label={"Honorar"}
                        onChange={handleUpdateTeam}/>
        </EditorRow>

        <EditorRow label={""} className={"mb-2"}>
            <Form.Check type={"checkbox"} checked={teilnehmerRolle.honorar} label={"Team"}
                        onChange={handleUpdateHonorar}/>
        </EditorRow>
    </StammdatenEditorPanel>;
}