import {AsyncTypeahead, Highlighter} from "react-bootstrap-typeahead";
import React, {useEffect, useState} from "react";
import {ListPerson} from "@api/personen.types";
import {Option} from "react-bootstrap-typeahead/types/types";
import {loadPersonenItem, loadPersonenPaginationList} from "@api/personen";
import styles from "@scss/Autocomplete.module.scss";
import {formatDate} from "@features/ui/utils/dateTimeUtils";
import {formatName} from "@features/personen/utils/personenUtils";
import {formatInstitution} from "@features/institutionen/utils/institutionenUtils";
import {LoadingPlaceholderInput} from "@features/ui/components/LoadingPlaceholderInput";

type PersonAutocompleteProps = {
    id?: string;
    value?: number;
    placeholder?: string;
    onChange?: (person?: ListPerson) => void;
    className?: string | undefined;
}

export const PersonAutocomplete: React.FC<PersonAutocompleteProps> = (props) => {
    const [isInitialized, setInitialized] = useState(props.value === undefined);
    const [isLoading, setIsLoading] = useState(false);
    const [singleSelections, setSingleSelections] = useState<ListPerson[]>([]);
    const [options, setOptions] = useState<ListPerson[]>([]);

    const handleSearch = (query: string) => {
        loadPersonenPaginationList({
            search: query,
            page: 0,
            size: 20,
        })
            .then(res => {
                setOptions(res.list);
            })
            // .catch(err => {
            //     console.log("=> ERROR");
            //     console.log(err);
            // })
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        if (props.value) {
            loadPersonenItem({
                id: props.value,
            })
                .then(res => {
                    setSingleSelections([res]);
                })
                .catch(err => {
                    console.log(err);
                })
                .finally(() => {
                    setInitialized(true);
                });
        } else {
            setSingleSelections([]);
            setInitialized(true);
        }
    }, [props.value]);

    // Bypass client-side filtering by returning `true`. Results are already
    // filtered by the search endpoint, so no need to do it again.
    const filterBy = () => true;

    if (!isInitialized) {
        return <LoadingPlaceholderInput/>
    }

    const handleChange = (option: Option[]) => {
        console.log(option);
        setSingleSelections(option as ListPerson[]);
        if (props.onChange) {
            props.onChange(option.length ? option[0] as ListPerson : undefined);
        }
    }

    return <AsyncTypeahead
        className={`${styles.autocomplete} ${styles.autocompleteStretch} ${props.className}`}
        clearButton
        filterBy={filterBy}
        id="async-example"
        isLoading={isLoading}
        labelKey={(option: Option) => {
            const p = option as ListPerson;
            return `${p.personendaten.vorname} ${p.personendaten.nachname}`
        }}
        minLength={3}
        selected={singleSelections}
        onSearch={handleSearch}
        options={options}
        onChange={handleChange}
        placeholder={props.placeholder}
        renderMenuItemChildren={(option: Option, props) => {
            const p = option as ListPerson;
            return <>
                    <span className={styles.autocompleteSuggestionLabelPrimary}>
                        <Highlighter search={props.text}>
                            {formatName(p.personendaten)}
                        </Highlighter>
                    </span>
                <span className={styles.autocompleteSuggestionLabelSecondary}>
                        <span>{formatDate(p.geburtsdatum)}</span>
                    {p.geburtsdatum && ", "}
                    <span>
                            <Highlighter search={props.text}>
                                {formatInstitution(p.gemeinde)}
                            </Highlighter>
                        </span>
                    </span>
            </>
        }}
    />;
};