import React from "react";
import {preventDefault} from "@features/ui/utils/eventHandlerUtils";
import {PageLoader} from "@features/ui/components/PageLoader";
import {Button, Col, Row} from "react-bootstrap";
import {ListVeranstaltung, Veranstaltung} from "@api/veranstaltungen.types";
import {useLoadVeranstaltungenItem} from "@features/veranstaltungen/api/veranstaltungen-api";

export type PersonPreviewPanelParams = {
    title?: string;
    veranstaltungId: number;
    onShowVeranstaltungenEditor?: (veranstaltung: Veranstaltung|ListVeranstaltung) => void;
};

export const VeranstaltungenPreviewPanel: React.FC<PersonPreviewPanelParams> = (props) => {
    const {data: veranstaltung, isLoading, isError} = useLoadVeranstaltungenItem({
        id: props.veranstaltungId
    });

    if (isError) {
        return <div>Error</div>
    }

    if (isLoading) {
        return <PageLoader/>
    }

    const handleDetailsClick = () => {
        if (props.onShowVeranstaltungenEditor) {
            props.onShowVeranstaltungenEditor(veranstaltung);
        }
    }

    return <div onClick={preventDefault}>
        <Row>
            <Col md={4}>
                {/*<div>*/}
                {/*    <GenderIcon gender={veranstaltung.personendaten?.geschlecht} size={"sm"}/>*/}
                {/*    &nbsp;*/}
                {/*    {formatName(veranstaltung.personendaten)}*/}
                {/*</div>*/}
                {/*<div>*/}
                {/*    {formatDate(veranstaltung.geburtsdatum)}*/}
                {/*</div>*/}
                {/*<div>*/}
                {/*    {formatInstitution(veranstaltung.gemeinde)}*/}
                {/*</div>*/}
            </Col>
            <Col md={4}>
                {/*{veranstaltung.erziehungsberechtigter && <>*/}
                {/*    Erziehungsberechtiger:*/}
                {/*    <div>*/}
                {/*        <GenderIcon gender={veranstaltung.erziehungsberechtigter?.personendaten.geschlecht} size={"sm"}/>*/}
                {/*        &nbsp;*/}
                {/*        {formatName(veranstaltung.erziehungsberechtigter?.personendaten)}*/}
                {/*    </div>*/}
                {/*</>}*/}
                {/*{(veranstaltung.kinder?.length && veranstaltung.kinder?.length > 0) ?*/}
                {/*    <div>*/}
                {/*        Kinder:*/}
                {/*        <div>*/}
                {/*            {veranstaltung.kinder.map(k => <div className={"mb-1"} key={`kinder_${veranstaltung.id}_${k.id}`}>*/}
                {/*                <Link to={`/personen/${k.id}`}>*/}
                {/*                    {formatName(k.personendaten)}, {formatDate(k.geburtsdatum)}*/}
                {/*                </Link>*/}
                {/*            </div>)}*/}
                {/*        </div>*/}
                {/*    </div> : ""}*/}
            </Col>
            <Col md={4}>
                {/*{(veranstaltung.teilnahmen?.length && veranstaltung.teilnahmen?.length > 0) ?*/}
                {/*    <div>*/}
                {/*        Veranstaltungen:*/}
                {/*        <div>*/}
                {/*            {veranstaltung.teilnahmen.map(t => <div className={"mb-1"}*/}
                {/*                                             key={`terilnahmen_${veranstaltung.id}_${t.id}`}>*/}
                {/*                <Link to={`/teilnahmen/${t.id}`}>*/}
                {/*                    <div>*/}
                {/*                        {formatTurnusTitel(t.turnus)}*/}
                {/*                    </div>*/}
                {/*                    <div>*/}
                {/*                        {formatDate(t.turnus.von)}*/}
                {/*                        &nbsp;-&nbsp;*/}
                {/*                        {formatDate(t.turnus.bis)}*/}
                {/*                    </div>*/}
                {/*                </Link>*/}
                {/*            </div>)}*/}
                {/*        </div>*/}
                {/*    </div> : ""}*/}
            </Col>
        </Row>
        <Button onClick={handleDetailsClick}>Bearbeiten</Button>
    </div>;
}