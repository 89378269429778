import {useMutation, useQuery} from "@tanstack/react-query";
import {VeranstaltungsArt} from "@api/stammdaten.types";
import {queryClient} from "@api/query-client";
import {StammdatenItemRequestParams, StammdatenListRequestParams} from "@api/stammdaten";
import {
    deleteVeranstaltungsArtenItem,
    loadVeranstaltungsArtenItem,
    loadVeranstaltungsArtenList,
    updateVeranstaltungsArtenItem
} from "@api/veranstaltungs-arten";

export const useLoadVeranstaltungsArtenPaginationList = (params: StammdatenListRequestParams) => {
    return useQuery<VeranstaltungsArt[], Error>(
        ["veranstaltungs-arten-list-paged", params],
        async () => {
            return await loadVeranstaltungsArtenList(params);
        }
    )
};

export const useLoadVeranstaltungsArtenItem = (params: StammdatenItemRequestParams) => {
    return useQuery<VeranstaltungsArt, Error>(
        ["veranstaltungs-arten-item", params],
        async () => {
            return await loadVeranstaltungsArtenItem(params);
        }
    )
};

export const useUpdateVeranstaltungsArtenItem = (options?: {
       onSuccess?: (id: number) => void;
       onError?: (err: unknown) => void;
   }) => {
    return useMutation({
        mutationFn: updateVeranstaltungsArtenItem,
        onSuccess: async (data) => {
            await queryClient.invalidateQueries({queryKey: ["veranstaltungs-arten-list-paged"]});
            await queryClient.invalidateQueries({queryKey: ["veranstaltungs-arten-item"]});
            if (options?.onSuccess) {
                options.onSuccess(data.data);
            }
        },
        onError: (err) => {
            if (options?.onError) {
                options.onError(err);
            }
        },
    });
}

export const useDeleteVeranstaltungsArtenItem = (options?: {
       onSuccess?: () => void;
       onError?: (err: unknown) => void;
   }) => {
    return useMutation({
        mutationFn: deleteVeranstaltungsArtenItem,
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: ["veranstaltungs-arten-list-paged"]});
            await queryClient.invalidateQueries({queryKey: ["veranstaltungs-arten-item"]});
            if (options?.onSuccess) {
                options.onSuccess();
            }
        },
        onError: (err) => {
            if (options?.onError) {
                options.onError(err);
            }
        },
    });
}
