import React from "react";
import {PageContentHeader} from "@features/ui/components/PageContentHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus, faSearch} from "@fortawesome/free-solid-svg-icons";
import {Button, Container, Row} from "react-bootstrap";
import styles from "@scss/ListPageLayout.module.scss";
import {convertOrderState} from "@features/personen/api/personen-api";
import {PageLoader} from "@features/ui/components/PageLoader";
import {useAppDispatch, useAppSelector} from "@store/hooks";
import {OrderState} from "@api/defaults";
import {StammdatenListFilterState, veranstaltungsKategorienActions} from "@features/stammdaten/store/store";
import {StammdatenList} from "@features/stammdaten/components/StammdatenList";
import {RootState} from "@store/store";
import {StammdatenListFilterPanel} from "@features/stammdaten/components/StammdatenListFilterPanel";
import {LinkContainer} from "react-router-bootstrap";
import {
    useLoadVeranstaltungsKategorienPaginationList
} from "@features/veranstaltungskategorien/api/veranstaltungs-kategorien-api";

export const VeranstaltungsKategorienListPage = () => {
    const getState = (state: RootState) => {
        return state.veranstaltungsKategorien;
    }

    const filterPanelOpen = useAppSelector(state => getState(state).filterPanelOpen);
    const dispatch = useAppDispatch();

    const handleToggleFilterPanel = () => {
        dispatch(veranstaltungsKategorienActions.setFilterPanelOpen(true));
    }

    const filters:StammdatenListFilterState = {
        search: useAppSelector(state => getState(state).search),
    }

    const orderBy:OrderState = {
        orderBy: useAppSelector(state => getState(state).orderBy),
        orderByDirection: useAppSelector(state => getState(state).orderByDirection),
    }

    const { data, isLoading, isError } = useLoadVeranstaltungsKategorienPaginationList({
        ...convertOrderState(orderBy),
        ...filters,
    });

    if (isError) {
        return <div>Error</div>
    }

    return <>
        <Container fluid className={styles.layout}>
            {filterPanelOpen || <Row className={styles.header}>
                <PageContentHeader title={"Veranstaltungs-Kategorien"}>
                    <LinkContainer to={"new"}>
                        <Button variant={"link"}>
                            <FontAwesomeIcon icon={faPlus} size={"lg"}/>
                        </Button>
                    </LinkContainer>
                    <Button variant={"link"} onClick={handleToggleFilterPanel}>
                        <FontAwesomeIcon icon={faSearch} size={"lg"}/>
                    </Button>
                </PageContentHeader>
            </Row>}
            {filterPanelOpen && <Row className={styles.filter}>
                <StammdatenListFilterPanel getState={getState} actions={veranstaltungsKategorienActions}/>
            </Row>}
            {isLoading && <Row className={styles.list}>
                <PageLoader message={"Veranstaltungs-Kategorien werden geladen"}/>
            </Row>}
            {isLoading || <Row className={styles.list}>
                <StammdatenList items={data} getState={getState} actions={veranstaltungsKategorienActions}/>
            </Row>}
        </Container>
    </>;
}