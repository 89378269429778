import {Table} from "react-bootstrap";
import React from "react";
import {useAppDispatch, useAppSelector} from "@store/hooks";
import {OrderState} from "@api/defaults";
import {OrderableTableHeader} from "@features/ui/components/OrderableTableHeader";
import {useNavigate} from "react-router-dom";
import {VeranstaltungenListRow} from "@features/veranstaltungen/components/VeranstaltungenListRow";
import {ListVeranstaltung, Veranstaltung} from "@api/veranstaltungen.types";
import {veranstaltungenActions} from "@features/veranstaltungen/store/store";

export type VeranstaltungenListProps = {
    veranstaltungen?: ListVeranstaltung[];
}

export const VeranstaltungenList: React.FC<VeranstaltungenListProps> = (props) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const orderBy:OrderState = {
        orderBy: useAppSelector(state => state.veranstaltungen.orderBy),
        orderByDirection: useAppSelector(state => state.veranstaltungen.orderByDirection),
    }

    const handleShowVeranstaltungenEditor = (veranstaltung: Veranstaltung|ListVeranstaltung) => {
        navigate(`${veranstaltung.id}`);
    }

    const handleOrderByChanged = (orderBy:OrderState) => {
        dispatch(veranstaltungenActions.setOrderBy(orderBy));
    }

    return <Table responsive hover>
        <thead>
        <tr>
            <th style={{width: "32px", paddingRight: "16px"}}></th>
            <OrderableTableHeader label={"Thema"} property={"thema"} orderBy={orderBy} onChangeOrder={handleOrderByChanged}/>
            <OrderableTableHeader label={"Zeitraum"} property={"turnusse.von"} orderBy={orderBy} onChangeOrder={handleOrderByChanged}/>
            <OrderableTableHeader label={"Veranstaltungsort"} property={"veranstaltungsOrt.name"} orderBy={orderBy} onChangeOrder={handleOrderByChanged}/>
            <th></th>
        </tr>
        </thead>
        <tbody>
        {props.veranstaltungen?.map((veranstaltung) => <VeranstaltungenListRow key={veranstaltung.id} veranstaltung={veranstaltung} onShowVeranstaltungenEditor={handleShowVeranstaltungenEditor}/>)}
        </tbody>
    </Table>;
}