import {useMutation, useQuery} from "@tanstack/react-query";
import {AnsprechpartnerRolle} from "@api/stammdaten.types";
import {queryClient} from "@api/query-client";
import {StammdatenItemRequestParams, StammdatenListRequestParams} from "@api/stammdaten";
import {
    deleteAnsprechpartnerRollenItem,
    loadAnsprechpartnerRolleList,
    loadAnsprechpartnerRollenItem,
    updateAnsprechpartnerRollenItem
} from "@api/ansprechpartner-rollen";

export const useLoadAnsprechpartnerRollenPaginationList = (params: StammdatenListRequestParams) => {
    return useQuery<AnsprechpartnerRolle[], Error>(
        ["ansprechpartner-rollen-list-paged", params],
        async () => {
            return await loadAnsprechpartnerRolleList(params);
        }
    )
};

export const useLoadAnsprechpartnerRollenItem = (params: StammdatenItemRequestParams) => {
    return useQuery<AnsprechpartnerRolle, Error>(
        ["ansprechpartner-rollen-item", params],
        async () => {
            return await loadAnsprechpartnerRollenItem(params);
        }
    )
};

export const useUpdateAnsprechpartnerRollenItem = (options?: {
       onSuccess?: (id: number) => void;
       onError?: (err: unknown) => void;
   }) => {
    return useMutation({
        mutationFn: updateAnsprechpartnerRollenItem,
        onSuccess: async (data) => {
            await queryClient.invalidateQueries({queryKey: ["ansprechpartner-rollen-list-paged"]});
            await queryClient.invalidateQueries({queryKey: ["ansprechpartner-rollen-item"]});
            if (options?.onSuccess) {
                options.onSuccess(data.data);
            }
        },
        onError: (err) => {
            if (options?.onError) {
                options.onError(err);
            }
        },
    });
}

export const useDeleteAnsprechpartnerRollenItem = (options?: {
       onSuccess?: () => void;
       onError?: (err: unknown) => void;
   }) => {
    return useMutation({
        mutationFn: deleteAnsprechpartnerRollenItem,
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: ["ansprechpartner-rollen-list-paged"]});
            await queryClient.invalidateQueries({queryKey: ["ansprechpartner-rollen-item"]});
            if (options?.onSuccess) {
                options.onSuccess();
            }
        },
        onError: (err) => {
            if (options?.onError) {
                options.onError(err);
            }
        },
    });
}
