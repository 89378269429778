import {axiosAuth} from "@api/axios";
import {store} from "@store/store";

const TOKEN_ENDPOINT = "/oauth2/token";

export interface AccessTokenRequestParams {
    clientId: string;
    authorizationCode: string;
    codeVerifier: string;
}

export interface AccessTokenResponse {
    access_token: string;
    expires_in: number;
    token_type: string;
}

export const obtainAccessToken = async (params: AccessTokenRequestParams) => {
    const {data} = await axiosAuth.post<AccessTokenResponse>(`${TOKEN_ENDPOINT}`, {
        client_id: params.clientId,
        grant_type: "authorization_code",
        code: params.authorizationCode,
        code_verifier: params.codeVerifier,
    }, {
        headers: {'content-type': 'application/x-www-form-urlencoded'},
    });

    //     // + "?client_id=" + clientId
    //     // + "&redirect_uri=" + redirectUri
    //     + "&grant_type=" + grantType
    //     + "&code=" + urlParams.get("code")
    //     // + "&code_verifier=" + codeVerifier;

    return data;
}

export const hasAnyAuthority = (authorities: string|string[]):boolean => {
    const userAuthorites = store.getState().auth.authorities;
    if (!userAuthorites) {
        return false;
    }

    if (typeof authorities === "string") {
        authorities = [authorities];
    }

    return (authorities as string[]).filter(a => userAuthorites.filter(ua => a === ua).length).length > 0;
}

export const hasAllAuthorities = (authorities: string|string[]):boolean => {
    const userAuthorites = store.getState().auth.authorities;
    if (!userAuthorites) {
        return false;
    }

    if (typeof authorities === "string") {
        authorities = [authorities];
    }

    return (authorities as string[]).filter(a => userAuthorites.filter(ua => a === ua).length).length == authorities.length;
}
