import {Button} from "react-bootstrap";
import React, {MouseEvent, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCaretDown, faTimes} from "@fortawesome/free-solid-svg-icons";
import {VeranstaltungenPreviewPanel} from "@features/veranstaltungen/components/VeranstaltungenPreviewPanel";
import {ListVeranstaltung, Veranstaltung} from "@api/veranstaltungen.types";
import {formatVeranstaltungTitel} from "@features/veranstaltungen/utils/veranstaltungenUtils";
import {formatDate} from "@features/ui/utils/dateTimeUtils";

export type VeranstaltungenListRowProps = {
    veranstaltung: ListVeranstaltung;
    onShowVeranstaltungenEditor: (veranstaltung: Veranstaltung|ListVeranstaltung) => void;
}

export const VeranstaltungenListRow: React.FC<VeranstaltungenListRowProps> = (props) => {
    const [showDetails, setShowDetails] = useState(false);
    // const dispatch = useAppDispatch();

    // const handleDelete = (e:MouseEvent<HTMLElement>, id:number) => {
    //     if (window.confirm("Wollen Sie die Veranstaltung wirklich entfernen?")) {
    //         console.log("HANDLE DELETE", id);
    //     }
    // }

    const handleRowClick = (e:MouseEvent<HTMLTableRowElement>, veranstaltung: ListVeranstaltung) => {
        if (!e.defaultPrevented) {
            props.onShowVeranstaltungenEditor(veranstaltung);
        }
    }

    const handleShowDetails = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setShowDetails(true);
    }

    const handleHideDetails = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setShowDetails(false);
    }

    return <tr key={props.veranstaltung.id} onClick={(e) => handleRowClick(e, props.veranstaltung)} className={"cursorClickable"}>
        {!showDetails && <>
            <td>{props.veranstaltung.onlineAnmeldungAktiv ? "A" : ""}</td>
            <td>{formatVeranstaltungTitel(props.veranstaltung)}</td>
            <td>{formatDate(props.veranstaltung.von)} - {formatDate(props.veranstaltung.bis)}</td>
            <td>{`${props.veranstaltung.veranstaltungsOrt?.name || ""} ${props.veranstaltung.veranstaltungsOrt?.name2 || ""}`.trim() || "-"}</td>
            <td>
                <Button onClick={handleShowDetails} size={"sm"} variant={"link"}>
                    <FontAwesomeIcon icon={faCaretDown}/>
                </Button>
            </td>
        </>}
        {showDetails && <>
            <td></td>
            <td colSpan={4}>
                <VeranstaltungenPreviewPanel veranstaltungId={props.veranstaltung.id!} title={"Details"} onShowVeranstaltungenEditor={props.onShowVeranstaltungenEditor}/>
            </td>
            <td>
                <Button onClick={handleHideDetails} size={"sm"} variant={"link"}>
                    <FontAwesomeIcon icon={faTimes}/>
                </Button>
            </td>
        </>}
    </tr>;
}