import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import styles from "@scss/EditorPageLayout.module.scss";
import {AnsprechpartnerRolle} from "@api/stammdaten.types";
import {useNavigate} from "react-router-dom";
import {addMessage} from "@features/ui/store/actions";
import {appSettings} from "@api/defaults";
import {useUpdateAnsprechpartnerRollenItem} from "@features/ansprechpartnerrollen/api/ansprechpartner-rollen-api";
import {
    AnsprechpartnerRolleEditorPanel
} from "@features/ansprechpartnerrollen/components/AnsprechpartnerRolleEditorPanel";

export const AnsprechpartnerRollenCreatePage = () => {
    const navigate = useNavigate();
    const ansprechpartnerRolle: Partial<AnsprechpartnerRolle> = {};
    const saveMutation = useUpdateAnsprechpartnerRollenItem({
        onSuccess: id => {
            addMessage({
                title: "Die Ansprechpartner-Rolle wurde angelegt",
                timeout: appSettings.messageTimeout,
            });
            navigate(`../${id}`);
        }
    });

    const handleSubmit = (item: AnsprechpartnerRolle) => {
        saveMutation.mutate({
            id: item.id,
            item
        });
    }

    return <Container fluid className={styles.layout}>
        <Row>
            <Col xl={6}>
                <AnsprechpartnerRolleEditorPanel title={"Ansprechpartner-Rolle anlegen"}
                                            item={ansprechpartnerRolle}
                                            onSubmit={item => handleSubmit(item as AnsprechpartnerRolle)}/>
            </Col>
            <Col xl={6}>
            </Col>
        </Row>
    </Container>;
}