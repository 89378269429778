import React from "react";
import styles from "@features/ui/style/PageContentHeader.module.scss";

export type PageContentHeaderProps = {
    title: string;
    children?: React.ReactNode;
};

export const PageContentHeader: React.FC<PageContentHeaderProps> = (props) => {
    return <header className={styles.header}>
        <div>
            <h1>{props.title}</h1>
        </div>
        <div className={`${styles.title} text-end`}>
            {props.children}
        </div>
    </header>;

}