import React from "react";
import {useParams} from "react-router-dom";
import {convertRouterParams, IdRouterParams, idRouterParamsTypes} from "@pages/router";
import {PageLoader} from "@features/ui/components/PageLoader";
import {Col, Container, Row} from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import styles from "@scss/EditorPageLayout.module.scss";
import {TeilnahmenInlineListPanel} from "@features/teilnahmen/components/TeilnahmenInlineListPanel";
import {VeranstaltungenEditorPanel} from "@features/veranstaltungen/components/VeranstaltungenEditorPanel";
import {useLoadVeranstaltungenItem} from "@features/veranstaltungen/api/veranstaltungen-api";
import {Veranstaltung} from "@api/veranstaltungen.types";

export const VeranstaltungenEditorPage = () => {
    const {id} = convertRouterParams<IdRouterParams>(useParams(), [idRouterParamsTypes]);

    const {data: veranstaltung, isLoading, isError} = useLoadVeranstaltungenItem({
        id
    });

    if (isError) {
        return <div>Error</div>
    }

    if (isLoading) {
        return <PageLoader/>
    }

    const handleSubmit = (veranstaltung: Veranstaltung) => {
        console.log("SAVE VERANSTALTUNG");
        console.log(veranstaltung);
    }

    return <Container fluid className={styles.layout}>
        <Row>
            <Col xl={6}>
                <VeranstaltungenEditorPanel veranstaltung={veranstaltung} onSubmit={handleSubmit}/>
            </Col>
            <Col xl={6} className={"editorSecondaryPanel"}>
                <TeilnahmenInlineListPanel teilnahmen={veranstaltung.teilnahmen}></TeilnahmenInlineListPanel>
            </Col>
        </Row>
    </Container>;
}