import {axios} from "@api/axios";
import {AnsprechpartnerRolle} from "@api/stammdaten.types";
import {StammdatenItemRequestParams, StammdatenListRequestParams} from "@api/stammdaten";

const ANSPRECHPARTNER_ROLLEN_ENDPOINT = "/admin/ansprechpartnerrollen";

export const loadAnsprechpartnerRolleList = async (params: StammdatenListRequestParams) => {
    if (!params.orderBy) {
        params.orderBy = "bezeichnung:ASC";
    }
    const {data} = await axios.get<AnsprechpartnerRolle[]>(`${ANSPRECHPARTNER_ROLLEN_ENDPOINT}`, {
        params
    });
    return data;
}

export const loadAnsprechpartnerRollenItem = async (params: StammdatenItemRequestParams) => {
    const {data} = await axios.get<AnsprechpartnerRolle>(`${ANSPRECHPARTNER_ROLLEN_ENDPOINT}/${params.id}`);
    return data;
}

export const updateAnsprechpartnerRollenItem = async (payload: {
    id?: number,
    item: AnsprechpartnerRolle
}) => {
    if (payload.id) {
        return axios.put<number>(`${ANSPRECHPARTNER_ROLLEN_ENDPOINT}/${payload.id}`, payload.item);
    }
    return axios.post<number>(ANSPRECHPARTNER_ROLLEN_ENDPOINT, payload.item);
}

export const deleteAnsprechpartnerRollenItem = async (id: number) => {
    return axios.delete<void>(`${ANSPRECHPARTNER_ROLLEN_ENDPOINT}/${id}`);
}
