import React, {ChangeEvent, ReactNode, useEffect, useState} from "react";
import {Adresse} from "@api/personen.types";
import {Form} from "react-bootstrap";
import {CountryAutocomplete} from "@features/ui/components/CountryAutocomplete";
import {EditorRow} from "@features/ui/components/EditorRow";

export type AdresseEditorPanelProps = {
    title?: string;
    adresse: Adresse;
    size?: 'sm' | 'lg';
    children?: ReactNode;
    onUpdate?: (adresse: Adresse) => void;
}

export const AdresseEditorPanel: React.FC<AdresseEditorPanelProps> = (props) => {
    const [adresse, setAdresse] = useState<Adresse>(props.adresse);
    useEffect(() => {
        setAdresse(props.adresse);
    }, [props.adresse]);

    const updateAdresse = (adresse: Adresse) => {
        setAdresse(adresse);
        if (props.onUpdate) {
            props.onUpdate(adresse);
        }
    }

    const handleUpdateAdresse1 = (e:ChangeEvent<HTMLInputElement>) => {
        updateAdresse({
            ...adresse,
            adresse1: e.target.value
        });
    }

    const handleUpdateAdresse2 = (e:ChangeEvent<HTMLInputElement>) => {
        updateAdresse({
            ...adresse,
            adresse2: e.target.value
        });
    }

    const handleUpdatePlz = (e:ChangeEvent<HTMLInputElement>) => {
        updateAdresse({
            ...adresse,
            plz: e.target.value
        });
    }

    const handleUpdateOrt = (e:ChangeEvent<HTMLInputElement>) => {
        updateAdresse({
            ...adresse,
            ort: e.target.value
        });
    }

    const handleUpdateLand = (staatsangehoerigkeit?: string) => {
        updateAdresse({
            ...adresse,
            land: staatsangehoerigkeit
        });
    }

    return <>
        <h4>{props.title || "Adresse"}</h4>

        <EditorRow label={"Adresse"} className={"mb-2"}>
            <Form.Control placeholder={"Adresse"} value={adresse.adresse1 || ""} size={props.size} className={"mb-2"} onChange={handleUpdateAdresse1}/>
        </EditorRow>

        <EditorRow label={""} className={"mb-2"}>
            <Form.Control placeholder={"Adresse Zusatz"} value={adresse.adresse2 || ""} size={props.size} className={"mb-2"} onChange={handleUpdateAdresse2}/>
        </EditorRow>

        <EditorRow label={"PLZ, Ort"} className={"mb-2"}>
            <Form.Control placeholder={"PLZ"} value={adresse.plz || ""} size={props.size} className={"mb-2"} onChange={handleUpdatePlz} style={{width: "100px"}}/>
            <Form.Control placeholder={"Ort"} value={adresse.ort || ""} size={props.size} className={"mb-2"} onChange={handleUpdateOrt}/>
        </EditorRow>

        <EditorRow label={"Land"} className={"mb-2"}>
            <CountryAutocomplete value={adresse.land} onChange={handleUpdateLand}/>
        </EditorRow>

        {props.children}
    </>;
}