import React, {ChangeEvent} from "react";
import {LoadingPlaceholderInput} from "@features/ui/components/LoadingPlaceholderInput";
import {InstitutionsArt} from "@api/stammdaten.types";
import {useLoadGemeindeArtenList} from "@features/institutionsarten/api/institutions-arten-api";
import {Form} from "react-bootstrap";

type GemeindenArtSelectProps = {
    required?: boolean;
    value?: number;
    size?: 'sm' | 'lg';
    placeholder?: string;
    onChange?: (institutionsArt?: InstitutionsArt) => void;
    className?: string | undefined;
}

export const GemeindenArtSelect = React.forwardRef<HTMLSelectElement, GemeindenArtSelectProps>((props, ref: React.ForwardedRef<HTMLSelectElement>) => {

    const { data: gemeindeArten, isLoading, isError } = useLoadGemeindeArtenList();

    if (isLoading) {
        return <LoadingPlaceholderInput/>
    }

    if (isError) {
        return <>Error</>;
    }

    const handleChange = (e:ChangeEvent<HTMLSelectElement>) => {
        if (props.onChange) {
            props.onChange(gemeindeArten.find(ga => ga.id == Number(e.target.value)));
        }
    }

    return <Form.Select value={props.value?.valueOf()} ref={ref} size={props.size} className={props.className} onChange={handleChange} required={props.required}>
        <option value={undefined}>Bitte wählen</option>
        {gemeindeArten.map(ga => <option value={ga.id} key={`options_${ga.id}`}>{ga.bezeichnung}</option>)}
    </Form.Select>;
});