import React, {ChangeEvent, FormEvent, ReactNode, useEffect, useState} from "react";
import {Form} from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import {EditorButtons} from "@features/ui/components/EditorButtons";
import {Institution} from "@api/institutionen.types";
import {EditorRow} from "@features/ui/components/EditorRow";
import {AdresseEditorPanel} from "@features/stammdaten/components/AdresseEditorPanel";
import {KontaktdatenEditorPanel} from "@features/stammdaten/components/KontaktdatenEditorPanel";
import {Adresse, Kontaktdaten} from "@api/personen.types";
import {EditorSpacer} from "@features/ui/components/EditorSpacer";

type InstitutiuonEditorPanelProps = {
    title: string;
    institution: Partial<Institution>,
    onChange?: (institution: Partial<Institution>) => void;
    onSubmit: (institution: Institution) => void;
    children?: ReactNode;
    onDelete?: (id: number) => void;

}

export const InstitutiuonEditorPanel: React.FC<InstitutiuonEditorPanelProps> = (props) => {
    const [institution, setInstitution] = useState<Partial<Institution>>(props.institution);
    useEffect(() => {
        setInstitution(props.institution);
    }, [props.institution]);

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();
        props.onSubmit(institution as Institution);
    }

    const handleOnChange = (partialItem: Partial<Institution>) => {
        setInstitution({
            ...institution,
            ...partialItem,
        });
        if (props.onChange) {
            props.onChange(partialItem);
        }
    }

    const handleOnDelete = () => {
        if (props.onDelete) {
            props.onDelete(institution.id!);
        }
    }

    const handleUpdateName = (e: ChangeEvent<HTMLInputElement>) => {
        handleOnChange({
            name: e.target.value
        });
    }

    const handleUpdateName2 = (e: ChangeEvent<HTMLInputElement>) => {
        handleOnChange({
            name2: e.target.value
        });
    }

    const handleUpdateBemerkungen = (e: ChangeEvent<HTMLInputElement>) => {
        handleOnChange({
            bemerkungen: e.target.value
        });
    }

    const handleUpdateAdresse = (adresse: Adresse) => {
        handleOnChange({
            adresse,
        });
    }

    const handleUpdateKontaktdaten = (kontaktdaten: Kontaktdaten) => {
        handleOnChange({
            kontaktdaten,
        });
    }

    return <Form onSubmit={handleSubmit}>
        <div className={"p-3"}>
            <h3>{props.title}</h3>

            <EditorRow label={"Name"} className={"mb-2"}>
                <Form.Control placeholder={"Name"} value={institution.name || ""}
                              onChange={handleUpdateName}/>
            </EditorRow>

            <EditorRow label={""} className={"mb-2"}>
                <Form.Control placeholder={"Name Zusatz"} value={institution.name2 || ""}
                              onChange={handleUpdateName2}/>
            </EditorRow>

            <EditorRow label={"Bemerkungen"} className={"mb-2"}>
                <Form.Control placeholder={"Bemerkungen"} value={institution.bemerkungen || ""}
                              onChange={handleUpdateBemerkungen} as="textarea" rows={3}/>
            </EditorRow>

            {props.children}

            <EditorSpacer/>
            <AdresseEditorPanel adresse={institution.adresse!} onUpdate={handleUpdateAdresse} />

            <EditorSpacer/>
            <KontaktdatenEditorPanel kontaktdaten={institution.kontaktdaten!} onUpdate={handleUpdateKontaktdaten}/>
        </div>

        <EditorButtons hideDeleteButton={!institution.id} onDelete={handleOnDelete}/>

    </Form>;
}