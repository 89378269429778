import React from "react";
import {Col, Form, Row} from "react-bootstrap";
import styles from "@features/ui/style/EditorRow.module.scss";

type EditorRowProps = {
    label?: string;
    className?: string | undefined;
    children: React.ReactNode;
}

export const EditorRow: React.FC<EditorRowProps> = (props) => {
    return <Form.Group as={Row} className={props.className}>
        {props.label !== undefined && <Form.Label column sm={3}>{props.label}</Form.Label>}
        <Col sm={9} className={styles.fields}>
            {props.children}
        </Col>
    </Form.Group>;
}