import React, {ChangeEvent, useEffect, useState} from "react";
import {Form} from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import {EditorRow} from "@features/ui/components/EditorRow";
import {InstitutionsArt, Stammdaten} from "@api/stammdaten.types";
import {StammdatenEditorPanel} from "@features/stammdaten/components/StammdatenEditorPanel";

type InstitutionsArtEditorPanelProps = {
    title: string;
    item: Partial<InstitutionsArt>,
    onSubmit: (item: Stammdaten) => void;
    onDelete?: (id: number) => void;
}

export const InstitutionsArtEditorPanel: React.FC<InstitutionsArtEditorPanelProps> = (props) => {
    const [institutionsArt, setInstitutionsArt] = useState<Partial<InstitutionsArt>>(props.item);
    useEffect(() => {
        setInstitutionsArt(props.item);
    }, [props.item]);

    const handleSubmit = (item: InstitutionsArt) => {
        props.onSubmit(item);
    }

    const handleStammdatenChange = (partialInstitutionsArt: Partial<Stammdaten>) => {
        setInstitutionsArt({
            ...institutionsArt,
            ...partialInstitutionsArt
        });
    }

    const handleUpdateGemeinde = (e:ChangeEvent<HTMLInputElement>) => {
        setInstitutionsArt({
            ...institutionsArt,
            gemeinde: e.target.checked,
        });
    }

    const handleUpdateVeranstaltungsOrt = (e:ChangeEvent<HTMLInputElement>) => {
        setInstitutionsArt({
            ...institutionsArt,
            veranstaltungsOrt: e.target.checked,
        });
    }

    const handleOnDelete = (id: number) => {
        if (props.onDelete) {
            props.onDelete(id);
        }
    }

    const handleUpdateVerband = (e:ChangeEvent<HTMLInputElement>) => {
        setInstitutionsArt({
            ...institutionsArt,
            verband: e.target.checked,
        });
    }

    return <StammdatenEditorPanel title={props.title} item={institutionsArt} onSubmit={item => handleSubmit(item as InstitutionsArt)} onChange={handleStammdatenChange} onDelete={handleOnDelete}>
            <EditorRow label={""} className={"mb-2"}>
                <Form.Check type={"checkbox"} checked={institutionsArt.gemeinde} label={"Gemeinde"} onChange={handleUpdateGemeinde}/>
            </EditorRow>

            <EditorRow label={""} className={"mb-2"}>
                <Form.Check type={"checkbox"} checked={institutionsArt.veranstaltungsOrt} label={"Veranstaltungsort"} onChange={handleUpdateVeranstaltungsOrt}/>
            </EditorRow>

            <EditorRow label={""} className={"mb-2"}>
                <Form.Check type={"checkbox"} checked={institutionsArt.verband} label={"Verband"} onChange={handleUpdateVerband}/>
            </EditorRow>
    </StammdatenEditorPanel>;
}