import {PaginationRequestParam} from "@api/query.types";

// export const appSettings = {
//     debounce: 400,
//     pageSize: 200,
//     messageTimeout: 5000,
//     apiBaseURL: "http://localhost:8080",
//     authBaseURL: "http://localhost:8000",
//     authUrl: "http://localhost:8000/oauth2/authorize",
//     authClientId: "org_zwst_administration",
//     authRedirectUri: "http://localhost:3000/authorized.html",
// }

export const appSettings = {
    debounce: 400,
    pageSize: 200,
    messageTimeout: 5000,
    apiBaseURL: "https://api.zwst.org/0.0.2",
    authBaseURL: "https://auth.zwst.org",
    authUrl: "https://auth.zwst.org/oauth2/authorize",
    authClientId: "org_zwst_administration",
    authRedirectUri: "https://sozialreferat.zwst.org/authorized.html",
}

export interface PaginationState extends PaginationRequestParam {}

export enum OrderByDirection {
    ASC = "ASC",
    DESC = "DESC",
}

export interface OrderState {
    orderBy: string;
    orderByDirection: OrderByDirection;
}
