import {Geschlecht} from "@api/personen.types";
import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGenderless, faMars, faTransgender} from "@fortawesome/free-solid-svg-icons";
import {faVenus} from "@fortawesome/free-solid-svg-icons/faVenus";
import {SizeProp} from "@fortawesome/fontawesome-svg-core";

type GenderIconProps = {
    gender?: Geschlecht;
    size?: SizeProp
}

export const GenderIcon: React.FC<GenderIconProps> = (props) => {
    if (!props.gender) {
        return <FontAwesomeIcon icon={faGenderless} size={props.size}/>;
    }
    switch (props.gender) {
        case Geschlecht.WEIBLICH: return <FontAwesomeIcon icon={faVenus} size={props.size}/>;
        case Geschlecht.MAENNLICH: return <FontAwesomeIcon icon={faMars} size={props.size}/>;
        case Geschlecht.DIVERS: return <FontAwesomeIcon icon={faTransgender} size={props.size}/>;
        default: return <FontAwesomeIcon icon={faGenderless} size={props.size}/>;

    }
}