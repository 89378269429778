import React, {ChangeEvent, FormEvent, ReactNode, useEffect, useState} from "react";
import {Form} from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import {EditorRow} from "@features/ui/components/EditorRow";
import {Stammdaten} from "@api/stammdaten.types";
import {EditorButtons} from "@features/ui/components/EditorButtons";

type StammdatenEditorPanelProps = {
    title: string;
    item: Partial<Stammdaten>,
    onChange?: (item: Partial<Stammdaten>) => void;
    onSubmit: (item: Stammdaten) => void;
    children?: ReactNode;
    onDelete?: (id: number) => void;
}

export const StammdatenEditorPanel: React.FC<StammdatenEditorPanelProps> = (props) => {
    const [item, setItem] = useState<Partial<Stammdaten>>(props.item);
    useEffect(() => {
        setItem(props.item);
    }, [props.item]);

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();
        props.onSubmit(item as Stammdaten);
    }

    const handleOnChange = (partialItem: Partial<Stammdaten>) => {
        setItem({
            ...item,
            ...partialItem,
        });
        if (props.onChange) {
            props.onChange(partialItem);
        }
    }

    const handleOnDelete = () => {
        if (props.onDelete) {
            props.onDelete(item.id!);
        }
    }

    const handleUpdateBezeichnung = (e: ChangeEvent<HTMLInputElement>) => {
        handleOnChange({
            bezeichnung: e.target.value
        });
    }

    return <Form onSubmit={handleSubmit}>
        <div className={"p-3"}>
            <h3>{props.title}</h3>

            <EditorRow label={"Bezeichnung"} className={"mb-2"}>
                <Form.Control placeholder={"Bezeichnung"} value={item.bezeichnung}
                              onChange={handleUpdateBezeichnung}/>
            </EditorRow>

            {props.children}
        </div>

        <EditorButtons hideDeleteButton={!item.id} onDelete={handleOnDelete}/>
    </Form>;
}