import React from "react";
import {useNavigate, useParams} from "react-router-dom";
import {convertRouterParams, IdRouterParams, idRouterParamsTypes} from "@pages/router";
import {PageLoader} from "@features/ui/components/PageLoader";
import {Col, Container, Row} from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import styles from "@scss/EditorPageLayout.module.scss";
import {TeilnehmerRolle} from "@api/stammdaten.types";
import {addMessage} from "@features/ui/store/actions";
import {appSettings} from "@api/defaults";
import {
    useDeleteTeilnehmerRollenItem,
    useLoadTeilnehmerRollenItem,
    useUpdateTeilnehmerRollenItem
} from "@features/teilnehmerrollen/api/teilnehmer-rollen-api";
import {TeilnehmerRolleEditorPanel} from "@features/teilnehmerrollen/components/TeilnehmerRolleEditorPanel";

export const TeilnehmerRolleEditorPage = () => {
    const navigate = useNavigate();
    const {id} = convertRouterParams<IdRouterParams>(useParams(), [idRouterParamsTypes]);
    const {data: teilnehmerRolle, isLoading, isError} = useLoadTeilnehmerRollenItem({
        id
    });

    const saveMutation = useUpdateTeilnehmerRollenItem({
        onSuccess: () => {
            addMessage({
                title: "Die Teilnehmer-Rolle wurde gespeichert",
                timeout: appSettings.messageTimeout,
            });
        }
    });

    const deleteMutation = useDeleteTeilnehmerRollenItem({
        onSuccess: () => {
            addMessage({
                title: "Die Teilnehmer-Rolle wurde entfernt",
                timeout: appSettings.messageTimeout,
            });
        },
        onError: () => {
            addMessage({
                title: "Beim Entfernen der Teilnehmer-Rolle ist ein Fehler aufgetreten",
                timeout: appSettings.messageTimeout,
            });
        }
    });

    if (isError) {
        return <div>Error</div>
    }

    if (isLoading) {
        return <PageLoader/>
    }

    const handleSubmit = async (item: TeilnehmerRolle) => {
        saveMutation.mutate({
            id: item.id,
            item
        });
    }
    const handleOnDelete = (id: number) => {
        deleteMutation.mutate(id);
        navigate("..");
    }

    return <Container fluid className={styles.layout}>
        <Row>
            <Col xl={6}>
                <TeilnehmerRolleEditorPanel title={"Teilnehmer-Rolle bearbeiten"}
                                            item={teilnehmerRolle}
                                            onSubmit={item => handleSubmit(item as TeilnehmerRolle)}
                                            onDelete={handleOnDelete}/>
            </Col>
            <Col xl={6}>
            </Col>
        </Row>
    </Container>;
}