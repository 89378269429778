import {createBrowserRouter} from "react-router-dom";
import {AppLayoutPage} from "@pages/AppLayoutPage";
import {HttpError404Page} from "./HttpError404Page";
import React from "react";
import {PersonenListPage} from "@features/personen/components/PersonenListPage";
import {WelcomePage} from "@pages/WelcomePage";
import {PersonenEditorPage,} from "@features/personen/components/PersonenEditorPage";
import {InstitutionsArtenListPage} from "@features/institutionsarten/components/InstitutionsArtenListPage";
import {InstitutionsArtenEditorPage} from "@features/institutionsarten/components/InstitutionsArtenEditorPage";
import {InstitutionsArtenCreatePage} from "@features/institutionsarten/components/InstitutionsArtenCreatePage";
import {TeilnehmerRollenListPage} from "@features/teilnehmerrollen/components/TeilnehmerRollenListPage";
import {TeilnehmerRollenCreatePage} from "@features/teilnehmerrollen/components/TeilnehmerRollenCreatePage";
import {TeilnehmerRolleEditorPage} from "@features/teilnehmerrollen/components/TeilnehmerRolleEditorPage";
import {AnsprechpartnerRollenListPage} from "@features/ansprechpartnerrollen/components/AnsprechpartnerRollenListPage";
import {
    AnsprechpartnerRollenCreatePage
} from "@features/ansprechpartnerrollen/components/AnsprechpartnerRollenCreatePage";
import {
    AnsprechpartnerRolleEditorPage
} from "@features/ansprechpartnerrollen/components/AnsprechpartnerRolleEditorPage";
import {VeranstaltungsArtenListPage} from "@features/veranstaltungsarten/components/VeranstaltungsArtenListPage";
import {VeranstaltungsArtenCreatePage} from "@features/veranstaltungsarten/components/VeranstaltungsArtenCreatePage";
import {VeranstaltungsArtEditorPage} from "@features/veranstaltungsarten/components/VeranstaltungsArtEditorPage";
import {
    VeranstaltungsKategorienListPage
} from "@features/veranstaltungskategorien/components/VeranstaltungsKategorienListPage";
import {
    VeranstaltungsKategorienCreatePage
} from "@features/veranstaltungskategorien/components/VeranstaltungsKategorienCreatePage";
import {
    VeranstaltungsKategorieEditorPage
} from "@features/veranstaltungskategorien/components/VeranstaltungsKategorieEditorPage";
import {GemeindenListPage} from "@features/institutionen/components/GemeindenListPage";
import {GemeindenCreatePage} from "@features/institutionen/components/GemeindenCreatePage";
import {GemeindenEditorPage} from "@features/institutionen/components/GemeindenEditorPage";
import {VeranstaltungsOrteListPage} from "@features/institutionen/components/VeranstaltungsOrteListPage";
import {VeranstaltungsOrtCreatePage} from "@features/institutionen/components/VeranstaltungsOrtCreatePage";
import {VeranstaltungsOrteEditorPage} from "@features/institutionen/components/VeranstaltungsOrteEditorPage";
import {VeranstaltungenListPage} from "@features/veranstaltungen/components/VeranstaltungenListPage";
import {VeranstaltungenEditorPage} from "@features/veranstaltungen/components/VeranstaltungenEditorPage";

export interface IdRouterParams {
    id: number;
}

export const idRouterParamsTypes:ParamTypeCnversions = {
    param: "id",
    type: "number"
}

export type ParamTypeCnversions = {
    param: string;
    type: string;
}

const convertValue = (v:string, targetType:string) => {
    switch (targetType.toLowerCase()) {
        case "number" :
            return Number(v);
    }
    return v;
}

export const convertRouterParams = <T,>(source: any, conversions?: ParamTypeCnversions[]): T => {
    const target = source;
    conversions?.forEach(c => {
        if (c.param in target) {
            target[c.param] = convertValue(target[c.param], c.type)
        }
    });
    return target;
}

/*
function proxiedPropertiesOf<TObj>(obj?: TObj) {
    return new Proxy({}, {
        get: (_, prop) => prop,
        set: () => {
            throw Error('Set not supported');
        },
    }) as {
        [P in keyof TObj]?: P;
    };
}

const myInterfaceProperties = proxiedPropertiesOf(myObj);
myInterfaceProperties.myProperty;
*/

export const router = createBrowserRouter([
    {
        path: "/",
        element: <AppLayoutPage />,
        errorElement: <HttpError404Page />,
        children: [
            {
                index: true,
                element: <WelcomePage />,
            },
            {
                path: "personen",
                children: [
                    {
                        index: true,
                        element: <PersonenListPage />,
                    },
                    {
                        path: ":id",
                        element: <PersonenEditorPage />,
                    },
                ]
            },
            {
                path: "veranstaltungen",
                children: [
                    {
                        index: true,
                        element: <VeranstaltungenListPage />,
                    },
                    {
                        path: ":id",
                        element: <VeranstaltungenEditorPage />,
                    },
                ]
            },
            {
                path: "gemeinden",
                children: [
                    {
                        index: true,
                        element: <GemeindenListPage />,
                    },
                    {
                        path: "new",
                        element: <GemeindenCreatePage />,
                    },
                    {
                        path: ":id",
                        element: <GemeindenEditorPage />,
                    },
                ]
            },
            {
                path: "veranstaltungsorte",
                children: [
                    {
                        index: true,
                        element: <VeranstaltungsOrteListPage />,
                    },
                    {
                        path: "new",
                        element: <VeranstaltungsOrtCreatePage />,
                    },
                    {
                        path: ":id",
                        element: <VeranstaltungsOrteEditorPage />,
                    },
                ]
            },
            {
                path: "teilnehmerrollen",
                children: [
                    {
                        index: true,
                        element: <TeilnehmerRollenListPage />,
                    },
                    {
                        path: "new",
                        element: <TeilnehmerRollenCreatePage />,
                    },
                    {
                        path: ":id",
                        element: <TeilnehmerRolleEditorPage />,
                    },
                ]
            },
            {
                path: "ansprechpartnerrollen",
                children: [
                    {
                        index: true,
                        element: <AnsprechpartnerRollenListPage />,
                    },
                    {
                        path: "new",
                        element: <AnsprechpartnerRollenCreatePage />,
                    },
                    {
                        path: ":id",
                        element: <AnsprechpartnerRolleEditorPage />,
                    },
                ]
            },
            {
                path: "veranstaltungsarten",
                children: [
                    {
                        index: true,
                        element: <VeranstaltungsArtenListPage />,
                    },
                    {
                        path: "new",
                        element: <VeranstaltungsArtenCreatePage />,
                    },
                    {
                        path: ":id",
                        element: <VeranstaltungsArtEditorPage />,
                    },
                ]
            },
            {
                path: "veranstaltungskategorien",
                children: [
                    {
                        index: true,
                        element: <VeranstaltungsKategorienListPage />,
                    },
                    {
                        path: "new",
                        element: <VeranstaltungsKategorienCreatePage />,
                    },
                    {
                        path: ":id",
                        element: <VeranstaltungsKategorieEditorPage />,
                    },
                ]
            },
            {
                path: "institutionsarten",
                children: [
                    {
                        index: true,
                        element: <InstitutionsArtenListPage />,
                    },
                    {
                        path: "new",
                        element: <InstitutionsArtenCreatePage />,
                    },
                    {
                        path: ":id",
                        element: <InstitutionsArtenEditorPage />,
                    },
                ]
            },
            // {
            //     path: "task-template/:templateId",
            //     element: <TaskListTemplatePage />,
            // },
            // {
            //     path: "task-lists",
            //     element: <TaskListPage />,
            // },
        ],
    }
]);
