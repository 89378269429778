export enum Referat {
    SOZIALREFERAT = "SOZIALREFERAT",
    JUGENDREFERAT = "JUGENDREFERAT",
}

export type Stammdaten = {
    id: number;
    bezeichnung: string;
}

export type InstitutionsArt = {
    id: number;
    bezeichnung: string;
    gemeinde: boolean;
    veranstaltungsOrt: boolean;
    verband: boolean;
}

export type TeilnehmerRolle = {
    id: number;
    bezeichnung: string;
    team: boolean;
    honorar: boolean;
    referat: Referat;
}

export type AnsprechpartnerRolle = {
    id: number;
    bezeichnung: string;
}

export type VeranstaltungsArt = {
    id: number;
    bezeichnung: string;
    color: string;
    referat: Referat;
}

export type VeranstaltungsKategorie = {
    id: number;
    bezeichnung: string;
    color: string;
    referat: Referat;
}
