import {Nav} from "react-bootstrap";
import {LinkContainer} from "react-router-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import styles from "@features/ui/style/SidebarMenuNavitem.module.scss";
import {IconProp} from "@fortawesome/fontawesome-svg-core";

export type SidebarMenuNavitemProps = {
    url: string;
    icon: IconProp;
    label: string;
};

export const SidebarMenuNavitem: React.FC<SidebarMenuNavitemProps> = (props) => {

    return <LinkContainer to={props.url}>
        <Nav.Link className={"navitem"}>
            <span className={styles.navitemIcon}>
                <FontAwesomeIcon icon={props.icon} size={"lg"}/>
            </span>
            {props.label}
        </Nav.Link>
    </LinkContainer>;
};