import React, {MouseEvent} from 'react';
import '@scss/reset.scss';
import '@scss/bootstrap.scss';
import '@scss/global.scss';
import {QueryClientProvider} from "@tanstack/react-query";
import {queryClient} from "@api/query-client";
import {Button} from "react-bootstrap";
import {useAppDispatch, useAppSelector} from "@store/hooks";
import {uiActions, UiMessage} from "@features/ui/store/store";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars, faUser} from '@fortawesome/free-solid-svg-icons'
import logo from "@/assets/logo-zwst-clean.png";
import {SidebarNavMenu} from "@features/ui/components/SidebarNavMenu";
import styles from "@scss/AppLayoutPage.module.scss";
import {Outlet} from "react-router-dom";
import {LoginForm} from "@features/auth/components/LoginForm";
import {SidebarAuthMenu} from "@features/auth/components/SidebarAuthMenu";

export const AppLayoutPage = () => {
    // const footerSlotRight:React.ReactNode = useAppSelector(state => state.ui.footerSlotRight)
    const dispatch = useAppDispatch();
    const uiMessages = useAppSelector(state => state.ui.uiMessages);
    const lastMessage = uiMessages.length > 0 && uiMessages[uiMessages.length - 1];
    const {authenticated} = useAppSelector(state => state.auth);


    const handleOpenLeftSidebar = () => {
        dispatch(uiActions.openLeftSidebar());
    }

    const handleOpenRightSidebar = () => {
        dispatch(uiActions.openRightSidebar());
    }

    const handleRemoveMessage = (e: MouseEvent<HTMLSpanElement>, message: UiMessage) => {
        if (message.id) {
            dispatch(uiActions.removeMessage(message.id));
        }
    }

    return (
        <QueryClientProvider client={queryClient}>
            <div className={styles.appLayout}>
                {authenticated && <SidebarNavMenu/>}
                {authenticated && <SidebarAuthMenu />}
                <header>
                    <div className={styles.iconLeft}>
                        {authenticated &&
                            <Button type="button" size={"lg"} variant="link" onClick={handleOpenLeftSidebar}>
                                <FontAwesomeIcon icon={faBars} size={"lg"} color={"#fff"}/>
                            </Button>}
                    </div>
                    <div className={styles.body}>
                        <img src={logo} alt={"ZWST Logo"}/>
                    </div>
                    <div className={styles.iconRight}>
                        {authenticated && <Button type="button" size={"lg"} variant="link" onClick={handleOpenRightSidebar}>
                            <FontAwesomeIcon icon={faUser} size={"lg"} color={"#fff"}/>
                        </Button>}
                    </div>
                </header>


                <article>
                    <LoginForm/>
                    {authenticated && <Outlet/>}
                </article>


                <footer>
                    <div className={`${styles.slotLeft} mx-2 my-1`}>
                        Version 1.0.0
                    </div>
                    {authenticated && <>
                        <div className={styles.slotCenter}/>
                        <div className={`${styles.slotRight} mx-2 my-1`}>
                            {lastMessage && <span onClick={e => handleRemoveMessage(e, lastMessage)}>
                            {lastMessage.title}
                        </span>}
                        </div>
                    </>}
                </footer>
            </div>
        </QueryClientProvider>
    );
}
