import React, {ChangeEvent, useEffect, useState} from "react";
import {Referat} from "@api/stammdaten.types";
import {Form} from "react-bootstrap";
import {hasAnyAuthority} from "@api/auth";

type ReferatSelectProps = {
    required?: boolean;
    value?: Referat;
    size?: 'sm' | 'lg';
    className?: string | undefined;
    onChange?: (referat?: Referat) => void;
}

type ReferatSelectOption = {
    value? : string;
    label: string;
}

export const ReferatSelect = React.forwardRef<HTMLSelectElement, ReferatSelectProps>((props, ref: React.ForwardedRef<HTMLSelectElement>) => {
    const [value, setValue] = useState<Referat|undefined>(undefined);
    useEffect(() => {
        setValue(props.value);
    }, [props.value]);


    const referatSelectOptions: ReferatSelectOption[] = [
        {
            value: undefined,
            label: "Referat: Bitte wählen"
        },
    ];

    if (hasAnyAuthority("admin.sozialreferat.write")) {
        referatSelectOptions.push({
            value: Referat.SOZIALREFERAT.valueOf(),
            label: "Sozialreferat"
        });
    }

    if (hasAnyAuthority("admin.jugendreferat.write")) {
        referatSelectOptions.push({
            value: Referat.JUGENDREFERAT.valueOf(),
            label: "Jugendreferat"
        });
    }

    const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
        if (props.onChange) {
            props.onChange(Referat[e.target.value as keyof typeof Referat])
        }
    }

    return <Form.Select placeholder={"Referat"} value={value?.valueOf()} ref={ref}
                        size={props.size} className={props.className} onChange={handleChange} required={props.required}>
        {referatSelectOptions.map(o => <option value={o.value} key={`options_${o.value || "none"}`}>{o.label}</option>)}
    </Form.Select>;
});