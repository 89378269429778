import {useNavigate} from "react-router-dom";
import React, {MouseEvent, ReactNode} from "react";
import {Button} from "react-bootstrap";
import {ButtonVariant} from "react-bootstrap/types";

type ReloadButtonProps = {
    label?: string;
    variant?: ButtonVariant;
    size?: 'sm' | 'lg';
    children?: ReactNode;
}

export const ReloadButton: React.FC<ReloadButtonProps> = (props) => {
    const navigate = useNavigate();

    const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
        if (!e.defaultPrevented) {
            e.preventDefault();
            navigate(0);
        }
    }

    return <Button variant={props.variant || "secondary"} size={props.size} onClick={handleClick}>
        {props.children ? props.children : props.label || "Abbrechen"}
    </Button>
}
