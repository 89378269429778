import React, {useEffect, useState} from "react";
import ReactDatePicker from "react-datepicker";

type DatePickerProps = {
    value?: Date
    onChange?: (date?: Date) => void;
}
export const DatePicker = React.forwardRef<ReactDatePicker, DatePickerProps>((props, ref)  => {
    const [date, setDate] = useState(props.value);
    useEffect(() => {
        setDate(props.value);
    }, [props.value]);

    return <ReactDatePicker
        ref={ref}
        selected={date}
        isClearable
        className="form-control"
        onChange={(date: Date) => {
            setDate(date);
            if (props.onChange) {
                props.onChange(date);
            }
        }}
        dateFormat="dd.MM.yyyy"
    />

});