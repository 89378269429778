import {Button, Nav, Offcanvas} from "react-bootstrap";
import {useAppDispatch, useAppSelector} from "@store/hooks";
import {uiActions} from "@features/ui/store/store";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faBuildingColumns,
    faCalendarDay,
    faClipboardList,
    faHouse,
    faTimes,
    faUserCheck,
    faUsers
} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import {faBuilding} from "@fortawesome/free-regular-svg-icons";
import {SidebarMenuNavitem} from "@features/ui/components/SidebarMenuNavitem";
import styles from "@features/ui/style/SidebarMenu.module.scss";

export const SidebarNavMenu = () => {

    const sidebarmenuOpen = useAppSelector((state) => state.ui.leftSidebarOpen);
    const dispatch = useAppDispatch();

    const handleCloseMainSidebar = () => {
        dispatch(uiActions.closeLeftSidebar());
    }

    return <Offcanvas show={sidebarmenuOpen} onHide={handleCloseMainSidebar}>
        <Offcanvas.Header className={styles.sidemenuHeader}>
            <Offcanvas.Title>Personen- und Veranstaltungsdatenbank</Offcanvas.Title>
            <Button type="button" variant="link" onClick={handleCloseMainSidebar}>
                <FontAwesomeIcon icon={faTimes} size={"lg"} color={"#fff"}/>
            </Button>
        </Offcanvas.Header>
        <Offcanvas.Body onClick={handleCloseMainSidebar}>
            <Nav className="justify-content-end flex-grow-1 pe-3 flex-column">
                <div className={styles.navitemsSection}>
                    <SidebarMenuNavitem url={`/personen`} icon={faUsers} label={"Personen"}/>
                    {/*<SidebarMenuNavitem url={`/personenduplikate`} icon={faUserGroup} label={"Personen-Duplikate"}/>*/}
                    <SidebarMenuNavitem url={`/veranstaltungen`} icon={faCalendarDay} label={"Veranstaltungen"}/>
                    <SidebarMenuNavitem url={`/gemeinden`} icon={faHouse} label={"Gemeinden"}/>
                    <SidebarMenuNavitem url={`/veranstaltungsorte`} icon={faBuilding} label={"Veranstaltungsorte"}/>
                </div>

                <div className={styles.navitemsSection}>
                    <h5>Stammdaten</h5>
                    <SidebarMenuNavitem url={`/teilnehmerrollen`} icon={faUserCheck} label={"Teilnehmer Rollen"}/>
                    <SidebarMenuNavitem url={`/ansprechpartnerrollen`} icon={faUserCheck}
                                        label={"Ansprechpartner Rollen"}/>
                    <SidebarMenuNavitem url={`/veranstaltungsarten`} icon={faClipboardList}
                                        label={"Veranstaltungsarten"}/>
                    <SidebarMenuNavitem url={`/veranstaltungskategorien`} icon={faClipboardList}
                                        label={"Veranstaltungskategorien"}/>
                    <SidebarMenuNavitem url={`/institutionsarten`} icon={faBuildingColumns} label={"Institutionsarten"}/>
                    {/*<SidebarMenuNavitem url={`/zusatzfragenmodule`} icon={faClipboardQuestion}*/}
                    {/*                    label={"Zusatzfragen Module"}/>*/}
                    {/*<SidebarMenuNavitem url={`/dokumente`} icon={faPrint} label={"Dokumente"}/>*/}
                </div>

                {/*<div className={styles.navitemsSection}>*/}
                {/*    <h5>System</h5>*/}
                {/*    <SidebarMenuNavitem url={`/benutzer`} icon={faPerson} label={"Benutzer"}/>*/}
                {/*    <SidebarMenuNavitem url={`/gruppen`} icon={faPeopleGroup} label={"Benutzer-Gruppen"}/>*/}
                {/*    <SidebarMenuNavitem url={`/rollen`} icon={faLock} label={"Rollen"}/>*/}
                {/*    <SidebarMenuNavitem url={`/emails`} icon={faEnvelope} label={"E-Mails"}/>*/}
                {/*</div>*/}
            </Nav>
        </Offcanvas.Body>
    </Offcanvas>;
};