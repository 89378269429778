import React, {useState} from "react";
import "react-datepicker/dist/react-datepicker.css";
import {AnsprechpartnerRolle, Stammdaten} from "@api/stammdaten.types";
import {StammdatenEditorPanel} from "@features/stammdaten/components/StammdatenEditorPanel";

type AnsprechpartnerRolleEditorPanelProps = {
    title: string;
    item: Partial<AnsprechpartnerRolle>,
    onSubmit: (item: Stammdaten) => void;
    onDelete?: (id: number) => void;
}

export const AnsprechpartnerRolleEditorPanel: React.FC<AnsprechpartnerRolleEditorPanelProps> = (props) => {
    const [ansprechpartnerRolle, setAnsprechpartnerRolle] = useState<Partial<AnsprechpartnerRolle>>(props.item);

    const handleSubmit = (item: AnsprechpartnerRolle) => {
        props.onSubmit(item);
    }

    const handleStammdatenChange = (partialAnsprechpartnerRolle: Partial<Stammdaten>) => {
        setAnsprechpartnerRolle({
            ...ansprechpartnerRolle,
            ...partialAnsprechpartnerRolle
        });
    }

    const handleOnDelete = (id: number) => {
        if (props.onDelete) {
            props.onDelete(id);
        }
    }

    // const handleUpdateGemeinde = (e:ChangeEvent<HTMLInputElement>) => {
    //     setAnsprechpartnerRolle({
    //         ...ansprechpartnerRolle,
    //         gemeinde: e.target.checked,
    //     });
    // }
    //
    // const handleUpdateVeranstaltungsOrt = (e:ChangeEvent<HTMLInputElement>) => {
    //     setAnsprechpartnerRolle({
    //         ...ansprechpartnerRolle,
    //         veranstaltungsOrt: e.target.checked,
    //     });
    // }
    //
    // const handleUpdateVerband = (e:ChangeEvent<HTMLInputElement>) => {
    //     setAnsprechpartnerRolle({
    //         ...ansprechpartnerRolle,
    //         verband: e.target.checked,
    //     });
    // }

    return <StammdatenEditorPanel title={props.title} item={ansprechpartnerRolle} onSubmit={item => handleSubmit(item as AnsprechpartnerRolle)} onChange={handleStammdatenChange} onDelete={handleOnDelete}>
            {/*<EditorRow label={""} className={"mb-2"}>*/}
            {/*    <Form.Check type={"checkbox"} checked={ansprechpartnerRolle.gemeinde} label={"Gemeinde"} onChange={handleUpdateGemeinde}/>*/}
            {/*</EditorRow>*/}

            {/*<EditorRow label={""} className={"mb-2"}>*/}
            {/*    <Form.Check type={"checkbox"} checked={ansprechpartnerRolle.veranstaltungsOrt} label={"Veranstaltungsort"} onChange={handleUpdateVeranstaltungsOrt}/>*/}
            {/*</EditorRow>*/}

            {/*<EditorRow label={""} className={"mb-2"}>*/}
            {/*    <Form.Check type={"checkbox"} checked={ansprechpartnerRolle.verband} label={"Verband"} onChange={handleUpdateVerband}/>*/}
            {/*</EditorRow>*/}
    </StammdatenEditorPanel>;
}