import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface AuthState {
    authenticated: boolean;
    username?: string;
    validUntil?: Date;
    authorities: string[];
    accessToken?: string;
}

const initialState: AuthState = {
    authenticated: false,
    authorities: [],
};

export const authSlice = createSlice({
    name: "ui",
    initialState,
    reducers: {
        setAuthenticated: (state, action: PayloadAction<boolean>) => {
            state.authenticated = action.payload;
        },
        setUsername: (state, action: PayloadAction<string>) => {
            state.username = action.payload;
        },
        setValidUntil: (state, action: PayloadAction<Date>) => {
            state.validUntil = action.payload;
        },
        setAuthorities: (state, action: PayloadAction<string[]>) => {
            state.authorities = action.payload;
        },
        setAccessToken: (state, action: PayloadAction<string>) => {
            state.accessToken = action.payload;
        },
        clearAuth: state => {
            state.authenticated = false;
            state.username = undefined;
            state.validUntil = undefined;
            state.authorities = [];
            state.accessToken = undefined;
        },
    }
});

export const authActions = authSlice.actions;
export const authReducer = authSlice.reducer;
