import {Button} from "react-bootstrap";
import {GenderIcon} from "@features/ui/components/GenderIcon";
import React, {MouseEvent, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCaretDown, faTimes} from "@fortawesome/free-solid-svg-icons";
import {ListPerson} from "@api/personen.types";
import {PersonPreviewPanel} from "@features/personen/components/PersonPreviewPanel";
import {formatDate} from "@features/ui/utils/dateTimeUtils";

export type PersonenListRowProps = {
    person: ListPerson;
    onShowPersonEditor: (person: ListPerson) => void;
}

export const PersonenListRow: React.FC<PersonenListRowProps> = (props) => {
    const [showDetails, setShowDetails] = useState(false);
    // const dispatch = useAppDispatch();

    // const handleDelete = (e:MouseEvent<HTMLElement>, id:number) => {
    //     if (window.confirm("Wollen Sie die Person wirklich entfernen?")) {
    //         console.log("HANDLE DELETE", id);
    //     }
    // }

    const handleRowClick = (e:MouseEvent<HTMLTableRowElement>, person: ListPerson) => {
        if (!e.defaultPrevented) {
            props.onShowPersonEditor(person);
        }
    }

    const handleShowDetails = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setShowDetails(true);
    }

    const handleHideDetails = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setShowDetails(false);
    }

    return <tr key={props.person.id} onClick={(e) => handleRowClick(e, props.person)} className={"cursorClickable"}>
        {!showDetails && <>
            <td><GenderIcon gender={props.person.personendaten?.geschlecht} size={"sm"}/></td>
            <td>{props.person.personendaten?.vorname}</td>
            <td>{props.person.personendaten?.nachname}</td>
            <td>{`${props.person.gemeinde?.name || ""} ${props.person.gemeinde?.name2 || ""}`.trim() || "-"}</td>
            <td>
                {formatDate(props.person.geburtsdatum) || "-"}
                {props.person.alter && ` (${props.person.alter || "-"})`}
            </td>
            <td>
                <Button onClick={handleShowDetails} size={"sm"} variant={"link"}>
                    <FontAwesomeIcon icon={faCaretDown}/>
                </Button>
            </td>
        </>}
        {showDetails && <>
            <td></td>
            <td colSpan={4}>
                <PersonPreviewPanel personId={props.person.id!} title={"Details"} onShowPersonEditor={props.onShowPersonEditor}/>
            </td>
            <td>
                <Button onClick={handleHideDetails} size={"sm"} variant={"link"}>
                    <FontAwesomeIcon icon={faTimes}/>
                </Button>
            </td>
        </>}
    </tr>;
}