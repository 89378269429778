import React, {ChangeEvent, FormEvent, useEffect, useState} from "react";
import {PersonendatenEditorPanel} from "@features/stammdaten/components/PersonendatenEditorPanel";
import {Button, Form} from "react-bootstrap";
import {Adresse, Bankverbindung, Kontaktdaten, Person, Personendaten, Reisepass} from "@api/personen.types";
import "react-datepicker/dist/react-datepicker.css";
import {DatePicker} from "@features/ui/components/DatePicker";
import {PersonAutocomplete} from "@features/personen/components/PersonAutocomplete";
import {AdresseEditorPanel} from "@features/stammdaten/components/AdresseEditorPanel";
import {EditorRow} from "@features/ui/components/EditorRow";
import {GemeindeAutocomplete} from "@features/institutionen/components/GemeindeAutocomplete";
import {EditorButtons} from "@features/ui/components/EditorButtons";
import {KontaktdatenEditorPanel} from "@features/stammdaten/components/KontaktdatenEditorPanel";
import {EditorSpacer} from "@features/ui/components/EditorSpacer";
import {BankverbindungEditorPanel} from "@features/stammdaten/components/BankverbindungEditorPanel";
import {ReisepassEditorPanel} from "@features/stammdaten/components/ReisepassEditorPanel";
import {formatName} from "@features/personen/utils/personenUtils";
import {formatDate} from "@features/ui/utils/dateTimeUtils";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleRight} from "@fortawesome/free-solid-svg-icons";

type PersonenEditorPanelProps = {
    person: Partial<Person>,
    onSubmit: (person: Person) => void;
}

export const PersonenEditorPanel: React.FC<PersonenEditorPanelProps> = (props) => {
    const [person, setPerson] = useState<Partial<Person>>(props.person);
    useEffect(() => {
        setPerson(props.person);
    }, [props.person]);

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();
        console.log("NOW SUBMIT EDITOR PANEL");
        console.log(person);
    }

    const handleUpdatePersonendaten = (personendaten: Personendaten) => {
        setPerson({
            ...person,
            personendaten,
        });
    }

    const handleUpdateGeburtsdatum = (geburtsdatum?: Date) => {
        setPerson({
            ...person,
            geburtsdatum
        });
    }

    const handleUpdateVerstorbenAm = (verstorbenAm?: Date) => {
        setPerson({
            ...person,
            verstorbenAm
        });
    }

    const handleUpdateErziehungsberechtigter = (erziehungsberechtigter?: Person) => {
        setPerson({
            ...person,
            erziehungsberechtigter
        });
    }

    const handleUpdateAdresse = (adresse: Adresse) => {
        setPerson({
            ...person,
            adresse,
        });
    }

    const handleUpdateVerzogenAm = (verzogenAm?: Date) => {
        setPerson({
            ...person,
            verzogenAm
        });
    }

    const handleUpdateKontaktdatenPrivat = (kontaktdatenPrivat: Kontaktdaten) => {
        setPerson({
            ...person,
            kontaktdatenPrivat,
        });
    }

    const handleUpdateEmailUnerwuenscht = (e: ChangeEvent<HTMLInputElement>) => {
        setPerson({
            ...person,
            emailUnerwuenscht: e.target.checked,
        });
    }

    const handleUpdateKontaktUnerwuenscht = (e: ChangeEvent<HTMLInputElement>) => {
        setPerson({
            ...person,
            kontaktUnerwuenscht: e.target.checked,
        });
    }

    const handleUpdateKontaktdatenArbeit = (kontaktdatenArbeit: Kontaktdaten) => {
        setPerson({
            ...person,
            kontaktdatenArbeit,
        });
    }

    const handleUpdateBankverbindung = (bankverbindung: Bankverbindung) => {
        setPerson({
            ...person,
            bankverbindung,
        });
    }

    const handleUpdateReisepass = (reisepass: Reisepass) => {
        setPerson({
            ...person,
            reisepass,
        });
    }

    const handleUpdateReisepass2 = (reisepass2: Reisepass) => {
        setPerson({
            ...person,
            reisepass2,
        });
    }

    const handleUpdateBemerkungen = (e: ChangeEvent<HTMLInputElement>) => {
        setPerson({
            ...person,
            bemerkungen: e.target.value
        });
    }

    const handleUpdateSozialreferat = (e: ChangeEvent<HTMLInputElement>) => {
        setPerson({
            ...person,
            sozialreferat: e.target.checked,
        });
    }

    const handleUpdateJugendreferat = (e: ChangeEvent<HTMLInputElement>) => {
        setPerson({
            ...person,
            jugendreferat: e.target.checked,
        });
    }

    const handleReset = (ignored: FormEvent<HTMLFormElement>) => {
        // anthing to do here?
        // localFormRefs.geburtsdatum.current?.forceUpdate();
        // setPerson(props.person); // doesn't seem to work
    };

    // return <Form onSubmit={handleSubmit} onReset={handleReset} ref={localFormRefs.form}>
    return <Form onSubmit={handleSubmit} onReset={handleReset}>
        <div className={"p-3"}>
            <PersonendatenEditorPanel personendaten={person.personendaten!} onUpdate={handleUpdatePersonendaten}>
                <EditorRow label={"Geburtsdatum"} className={"mb-2"}>
                    <DatePicker value={person.geburtsdatum} onChange={handleUpdateGeburtsdatum}/>
                </EditorRow>

                <EditorRow label={"Verstorben am"} className={"mb-2"}>
                    <DatePicker value={person.verstorbenAm} onChange={handleUpdateVerstorbenAm}/>
                </EditorRow>

                <EditorRow label={"Erziehungsberechtiger"} className={"mb-2"}>
                    <PersonAutocomplete placeholder={"Erziehungsberechtiger"} value={person.erziehungsberechtigter?.id}
                                        onChange={handleUpdateErziehungsberechtigter}/>
                    {person.erziehungsberechtigter && <Link to={`/personen/${person.erziehungsberechtigter.id}`}>
                        <Button>
                            <FontAwesomeIcon icon={faAngleRight}/>
                        </Button>
                    </Link>}
                </EditorRow>

                {(person.kinder?.length && person.kinder?.length > 0) ?
                    <EditorRow label={"Kinder"} className={"mb-2"}>
                        <div>
                            {person.kinder.map(k => <div className={"mb-1"} key={`kinder_${person.id}_${k.id}`}>
                                <Link to={`/personen/${k.id}`}>
                                    {formatName(k.personendaten)}, {formatDate(k.geburtsdatum)}
                                </Link>
                            </div>)}
                        </div>
                    </EditorRow> : ""}
            </PersonendatenEditorPanel>

            <EditorSpacer/>
            <AdresseEditorPanel adresse={person.adresse!} onUpdate={handleUpdateAdresse}>
                <EditorRow label={"Verzogen am"} className={"mb-2"}>
                    <DatePicker value={person.verzogenAm} onChange={handleUpdateVerzogenAm}/>
                </EditorRow>

                <EditorRow label={"Gemeinde"} className={"mb-2"}>
                    <GemeindeAutocomplete/>
                </EditorRow>
            </AdresseEditorPanel>

            <EditorSpacer/>
            <KontaktdatenEditorPanel kontaktdaten={person.kontaktdatenPrivat!} title={"Kontaktdaten privat"}
                                     onUpdate={handleUpdateKontaktdatenPrivat}>
                <EditorRow label={""} className={"mb-2"}>
                    <Form.Check type={"checkbox"} checked={person.emailUnerwuenscht}
                                label={"E-Mail Kontakt unerwünscht"}
                                onChange={handleUpdateEmailUnerwuenscht}/>
                </EditorRow>
                <EditorRow label={""} className={"mb-2"}>
                    <Form.Check type={"checkbox"} checked={person.kontaktUnerwuenscht}
                                label={"Jeglicher Kontakt unerwünscht"}
                                onChange={handleUpdateKontaktUnerwuenscht}/>
                </EditorRow>
            </KontaktdatenEditorPanel>

            <EditorSpacer/>
            <KontaktdatenEditorPanel kontaktdaten={person.kontaktdatenArbeit!} title={"Kontaktdaten Arbeit"}
                                     onUpdate={handleUpdateKontaktdatenArbeit}/>

            <EditorSpacer/>
            <BankverbindungEditorPanel bankverbindung={person.bankverbindung!} onUpdate={handleUpdateBankverbindung}/>

            <EditorSpacer/>
            <ReisepassEditorPanel reisepass={person.reisepass!} onUpdate={handleUpdateReisepass}/>

            <EditorSpacer/>
            <ReisepassEditorPanel title={"Reisepass 2"} reisepass={person.reisepass2!}
                                  onUpdate={handleUpdateReisepass2}/>

            <EditorSpacer/>
            <h4>Sonstiges</h4>
            <EditorRow label={"Bemerkungen"} className={"mb-2"}>
                <Form.Control placeholder={"Bemerkungen"} value={person.bemerkungen || ""}
                              onChange={handleUpdateBemerkungen} as="textarea" rows={3}/>
            </EditorRow>

            <EditorRow label={""} className={"mb-2"}>
                <Form.Check type={"checkbox"} checked={person.sozialreferat}
                            label={"Sichbar für Mitarbeiter des Sozialreferats"}
                            onChange={handleUpdateSozialreferat}/>
            </EditorRow>

            <EditorRow label={""} className={"mb-2"}>
                <Form.Check type={"checkbox"} checked={person.jugendreferat}
                            label={"Sichbar für Mitarbeiter des Jugendreferats"}
                            onChange={handleUpdateJugendreferat}/>
            </EditorRow>
        </div>

        <EditorButtons/>
    </Form>;
}