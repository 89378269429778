import {Button, Form} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {personenActions, PersonenListFilterState} from "@features/personen/store/store";
import {useAppDispatch, useAppSelector} from "@store/hooks";
import {ChangeEvent} from "react";
import _ from "lodash";
import {appSettings} from "@api/defaults";

export const PersonenListFilterPanel = () => {
    const filters:PersonenListFilterState = {
        search: useAppSelector(state => state.personen.search),
        alter: useAppSelector(state => state.personen.alter),
    }
    const dispatch = useAppDispatch();

    const handleResetForm = () => {
        dispatch(personenActions.resetFilters());
        dispatch(personenActions.resetPagination());
        dispatch(personenActions.setFilterPanelOpen(false));
    }

    const handleSetSearch = (e: ChangeEvent<HTMLInputElement>) => {
        dispatch(personenActions.setSearch(e.target.value));
        dispatch(personenActions.resetPagination());
    }

    const handleSetAlter = (e: ChangeEvent<HTMLInputElement>) => {
        dispatch(personenActions.setAlter(Number(e.target.value)));
        dispatch(personenActions.resetPagination());
    }

    return <Form onReset={handleResetForm} className="rowLayout rowLayoutGap my-4">
            <div className={"rowLayoutFlex"}>
                <Form.Control placeholder="Suche" size={"sm"} onChange={_.debounce(handleSetSearch, appSettings.debounce)}
                              defaultValue={filters.search || ""}/>
            </div>
            <div>
                <Form.Control placeholder="Alter" size={"sm"} onChange={_.debounce(handleSetAlter, appSettings.debounce)}
                              defaultValue={filters.alter || ""}/>
            </div>
            <div className={"text-end"}>
                <Button type={"reset"} variant={"danger"} size={"sm"}>
                    <FontAwesomeIcon icon={faTimes}/>
                </Button>
            </div>
        </Form>;
}