import {Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleLeft, faAngleRight, faAnglesLeft, faAnglesRight} from "@fortawesome/free-solid-svg-icons";
import React from "react";

export type PaginationProps = {
    pages:number;
    currentPage:number;
    maxPages?:number;
    onPageChanged: (newPage:number) => void;
};

const defaultMaxPages = 10;

export const Pagination: React.FC<PaginationProps> = (props) => {

    const maxPages = Math.max(props.maxPages || defaultMaxPages, 1);
    const startPage = Math.max(props.currentPage - Math.floor(maxPages / 2), 0);
    const directLinks = [];
    for (let i = startPage; i < startPage + maxPages + 1; i += 1) {
        if (i < props.pages) {
            directLinks.push(i);
        }
    }

    return <div className={"rowLayout rowLayoutCenter rowLayoutGap py-2"}>
        <Button variant={"link"} className={"inactiveLink"} onClick={() => props.onPageChanged(0)}>
            <FontAwesomeIcon icon={faAnglesLeft} />
        </Button>
        <Button variant={"link"} className={"inactiveLink"} onClick={() => props.onPageChanged(Math.max(props.currentPage - 1, 0))}>
            <FontAwesomeIcon icon={faAngleLeft} />
        </Button>
        {directLinks.map(p =>
            <Button variant={"link"} key={p} className={`${p == props.currentPage ? "activeLink" : "inactiveLink"} numberButton`} onClick={() => props.onPageChanged(p)}>
                {p + 1}
            </Button>
        )}
        <Button variant={"link"} className={"inactiveLink"} onClick={() => props.onPageChanged(Math.min(props.currentPage + 1, props.pages - 1))}>
            <FontAwesomeIcon icon={faAngleRight} />
        </Button>
        <Button variant={"link"} className={"inactiveLink"} onClick={() => props.onPageChanged(props.pages - 1)}>
            <FontAwesomeIcon icon={faAnglesRight} />
        </Button>
    </div>;
}