import {axios} from "@api/axios";
import {OrderRequestParam} from "@api/query.types";
import {GemeindenListFilterState, VeranstaltungsOrteListFilterState} from "@features/institutionen/store/store";
import {Institution, ListInstitution} from "@api/institutionen.types";

const GEMEINDEN_ENDPOINT = "/admin/gemeinden";
const VERANSTALTUNGS_ORTE_ENDPOINT = "/admin/veranstaltungsorte";

export interface GemeindenListRequestParams extends OrderRequestParam,GemeindenListFilterState {}
export interface GemeindeItemRequestParams {
    id: number;
}

export interface VeranstaltungsOrteListRequestParams extends OrderRequestParam,VeranstaltungsOrteListFilterState {}
export interface VeranstaltungsOrteItemRequestParams {
    id: number;
}

export const loadGemeindenList = async (params: GemeindenListRequestParams) => {
    if (!params.orderBy) {
        params.orderBy = "name:ASC,name2:ASC";
    }
    const { data } = await axios.get<ListInstitution[]>(`${GEMEINDEN_ENDPOINT}`, {
        params
    });
    return data;
}

export const loadGemeindenItem = async (params: GemeindeItemRequestParams) => {
    const { data } = await axios.get<Institution>(`${GEMEINDEN_ENDPOINT}/${params.id}`);
    return data;
}

export const updateGemeindenItem = async (payload: {
    id?: number,
    item: Institution
}) => {
    if (payload.id) {
        return axios.put<number>(`${GEMEINDEN_ENDPOINT}/${payload.id}`, payload.item);
    }
    return axios.post<number>(GEMEINDEN_ENDPOINT, payload.item);
}
export const deleteGemeindenItem = async (id: number) => {
    return axios.delete<void>(`${GEMEINDEN_ENDPOINT}/${id}`);
}

export const loadVeranstaltungsOrteList = async (params: GemeindenListRequestParams) => {
    if (!params.orderBy) {
        params.orderBy = "name:ASC,name2:ASC";
    }
    const { data } = await axios.get<ListInstitution[]>(`${VERANSTALTUNGS_ORTE_ENDPOINT}`, {
        params
    });
    return data;
}

export const loadVeranstaltungsOrteItem = async (params: GemeindeItemRequestParams) => {
    const { data } = await axios.get<Institution>(`${VERANSTALTUNGS_ORTE_ENDPOINT}/${params.id}`);
    return data;
}

export const updateVeranstaltungsOrteItem = async (payload: {
    id?: number,
    item: Institution
}) => {
    if (payload.id) {
        return axios.put<number>(`${VERANSTALTUNGS_ORTE_ENDPOINT}/${payload.id}`, payload.item);
    }
    return axios.post<number>(VERANSTALTUNGS_ORTE_ENDPOINT, payload.item);
}
export const deleteVeranstaltungsOrteItem = async (id: number) => {
    return axios.delete<void>(`${VERANSTALTUNGS_ORTE_ENDPOINT}/${id}`);
}