import React, {MouseEvent} from "react";
import {OrderByDirection, OrderState} from "@api/defaults";
import {OrderByIcon} from "@features/ui/components/OrderByIcon";

type OrderableTableHeaderProps = {
    label?: string;
    property: string;
    orderBy:OrderState;
    onChangeOrder: (orderBy:OrderState) => void;
    children?: React.ReactNode;
}

export const OrderableTableHeader: React.FC<OrderableTableHeaderProps> = (props) => {

    const handleClick = (e:MouseEvent<HTMLElement>) => {
        e.preventDefault();
        let newOrderByDirection: OrderByDirection;
        if (props.property == props.orderBy.orderBy) {
            newOrderByDirection = props.orderBy.orderByDirection == OrderByDirection.ASC ? OrderByDirection.DESC : OrderByDirection.ASC;
        } else {
            newOrderByDirection = OrderByDirection.ASC;
        }
        props.onChangeOrder({
            orderBy: props.property,
            orderByDirection: newOrderByDirection
        })
    }

    return <th onClick={handleClick} className={"cursorClickable"}>
        {props.children || props.label}
        &nbsp;
        &nbsp;
        <OrderByIcon orderByProperty={props.orderBy.orderBy} property={props.property} direction={props.orderBy.orderByDirection} />
    </th>;
}
