import React from "react";
import {PageContentHeader} from "@features/ui/components/PageContentHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus, faSearch} from "@fortawesome/free-solid-svg-icons";
import {Button, Container, Row} from "react-bootstrap";
import styles from "@scss/ListPageLayout.module.scss";
import {convertOrderState} from "@features/personen/api/personen-api";
import {PageLoader} from "@features/ui/components/PageLoader";
import {useAppDispatch, useAppSelector} from "@store/hooks";
import {OrderState} from "@api/defaults";
import {RootState} from "@store/store";
import {LinkContainer} from "react-router-bootstrap";
import {useLoadVeranstaltungsOrteList} from "@features/institutionen/api/institutionen-api";
import {veranstaltungsOrteActions, VeranstaltungsOrteListFilterState} from "@features/institutionen/store/store";
import {VeranstaltungsOrteList} from "@features/institutionen/components/VeranstaltungsOrteList";
import {VeranstaltungsOrteListFilterPanel} from "@features/institutionen/components/VeranstaltungsOrteListFilterPanel";

export const VeranstaltungsOrteListPage = () => {
    const getState = (state: RootState) => {
        return state.veranstaltungsOrte;
    }

    const filterPanelOpen = useAppSelector(state => getState(state).filterPanelOpen);
    const dispatch = useAppDispatch();

    const handleToggleFilterPanel = () => {
        dispatch(veranstaltungsOrteActions.setFilterPanelOpen(true));
    }

    const filters:VeranstaltungsOrteListFilterState = {
        search: useAppSelector(state => getState(state).search),
    }

    const orderBy:OrderState = {
        orderBy: useAppSelector(state => getState(state).orderBy),
        orderByDirection: useAppSelector(state => getState(state).orderByDirection),
    }

    const { data, isLoading, isError } = useLoadVeranstaltungsOrteList({
        ...convertOrderState(orderBy),
        ...filters,
    });

    if (isError) {
        return <div>Error</div>
    }

    return <>
        <Container fluid className={styles.layout}>
            {filterPanelOpen || <Row className={styles.header}>
                <PageContentHeader title={"Veranstaltungsorte"}>
                    <LinkContainer to={"new"}>
                        <Button variant={"link"}>
                            <FontAwesomeIcon icon={faPlus} size={"lg"}/>
                        </Button>
                    </LinkContainer>
                    <Button variant={"link"} onClick={handleToggleFilterPanel}>
                        <FontAwesomeIcon icon={faSearch} size={"lg"}/>
                    </Button>
                </PageContentHeader>
            </Row>}
            {filterPanelOpen && <Row className={styles.filter}>
                <VeranstaltungsOrteListFilterPanel/>
            </Row>}
            {isLoading && <Row className={styles.list}>
                <PageLoader message={"Veranstaltungsorte werden geladen"}/>
            </Row>}
            {isLoading || <Row className={styles.list}>
                <VeranstaltungsOrteList veranstaltungsOrte={data}/>
            </Row>}
        </Container>
    </>;
}