import {useMutation, useQuery} from "@tanstack/react-query";
import {VeranstaltungsKategorie} from "@api/stammdaten.types";
import {queryClient} from "@api/query-client";
import {StammdatenItemRequestParams, StammdatenListRequestParams} from "@api/stammdaten";
import {
    deleteVeranstaltungsKategorienItem,
    loadVeranstaltungsKategorienItem,
    loadVeranstaltungsKategorienList,
    updateVeranstaltungsKategorienItem
} from "@api/veranstaltungs-kategorien";

export const useLoadVeranstaltungsKategorienPaginationList = (params: StammdatenListRequestParams) => {
    return useQuery<VeranstaltungsKategorie[], Error>(
        ["veranstaltungs-kategorien-list-paged", params],
        async () => {
            return await loadVeranstaltungsKategorienList(params);
        }
    )
};

export const useLoadVeranstaltungsKategorienItem = (params: StammdatenItemRequestParams) => {
    return useQuery<VeranstaltungsKategorie, Error>(
        ["veranstaltungs-kategorien-item", params],
        async () => {
            return await loadVeranstaltungsKategorienItem(params);
        }
    )
};

export const useUpdateVeranstaltungsKategorienItem = (options?: {
       onSuccess?: (id: number) => void;
       onError?: (err: unknown) => void;
   }) => {
    return useMutation({
        mutationFn: updateVeranstaltungsKategorienItem,
        onSuccess: async (data) => {
            await queryClient.invalidateQueries({queryKey: ["veranstaltungs-kategorien-list-paged"]});
            await queryClient.invalidateQueries({queryKey: ["veranstaltungs-kategorien-item"]});
            if (options?.onSuccess) {
                options.onSuccess(data.data);
            }
        },
        onError: (err) => {
            if (options?.onError) {
                options.onError(err);
            }
        },
    });
}

export const useDeleteVeranstaltungsKategorienItem = (options?: {
       onSuccess?: () => void;
       onError?: (err: unknown) => void;
   }) => {
    return useMutation({
        mutationFn: deleteVeranstaltungsKategorienItem,
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: ["veranstaltungs-kategorien-list-paged"]});
            await queryClient.invalidateQueries({queryKey: ["veranstaltungs-kategorien-item"]});
            if (options?.onSuccess) {
                options.onSuccess();
            }
        },
        onError: (err) => {
            if (options?.onError) {
                options.onError(err);
            }
        },
    });
}
